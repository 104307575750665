import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { DatePicker, TimePicker, Space } from 'antd';
import moment from 'moment';
import RichTextEditor from '../../form-input/RichTextEditor';
import MultiSelect from '../../form-input/MultiSelect';

import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import SelectLocation from '../select-location/SelectLocation';
import {
    isTruthyString,
    isValidURL,
    isValidEmailAddress,
    isValidPassword,
    isValidIndianMobileNumber,
    isValidBoolean,
    isDescriptionHas5Words
} from '../../../utils/verification';
import dayjs from 'dayjs';
import { DOMParser } from '../../../utils/DOMParser';
import appURLs from '../../../appURL';
import Inputs from '../../Inputs/Inputs';
import AdsListerInfo from '../ads-lister-info/AdsListerInfo';
import FormButton from '../../buttons/form-button/FormButton';
import PostAdsHeading from '../post-ads-heading/PostAdsHeading';
import AdsPlans from '../ads-plans/AdsPlans';
import AdsPlansUpdated from '../ads-plans/AdsPlansUpdated';
import ImageUpload from '../images-upload/ImageUpload';
import formDataAppender from '../../../utils/formDataAppender';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';

import style from "./rentals.module.css";
import { useFetcher } from 'react-router-dom';

export default function Rentals({ toggleModalVisibilty, adsCategoriesId = ``, adsId = "", ad_steps = null, adsIds, setadsIds, setReplacerental, toggleModalForPostAdscategories, post_type }) {

    const userinfo = JSON.parse(window.localStorage.getItem("user"))
    console.log("userinfo", adsCategoriesId)

    const fetchConfig = getFetchConfig(),
        appURL = appURLs();

    const optionsForSelectSalary = [

        {
            name: "Please Contact",
            _id: 1
        },

        {
            name: "Enter Your Amount",
            _id: 2
        },
    ];

    const optionsForWhoAreYou = [

        {
            name: "Owner",
            _id: "Owner"
        },
        {
            name: "Broker",
            _id: "Broker"
        },
        {
            name: "Company",
            _id: "Company"
        },
    ];


    const [roomTypeRadioBtnOption, updateRoomTypeRadioBtnOption] = useState([]);
    const [rentDetailsData, setrentDetailsData] = useState();
    const [myadsId, setmyadsId] = useState('');
    const [myadsType, setmyadsType] = useState('');


    const availityRadioBtnOtionForRoomRents = [
        {
            label: "Immediate move in",
            value: "Immediate move in"
        },
        {
            label: "Custom date",
            value: "Custom date"
        }

    ];

    const availityRadioBtnOtion = [

        {
            label: "Please contact",
            value: "Please contact"
        },
        {
            label: "Custom date",
            value: "Custom date"
        }

    ];

    const [isPetFriendlyr, setIsPetFriendly] = useState(false);
    // const [isSmokingAllowed, setIsSmokingAllowedR] = useState(false);
    const [contOrdate, setcontOrdate] = useState(false);

    const [locationResponseData, setLocationResponseData] = useState(false);
    const [showLoader, toggleLoader] = useState(false),
        [steps, updateSteps] = useState(0);

    console.log(steps, "------steps--------")

    const [lat, setlat] = useState("")
    const [lng, setlng] = useState("")

    const [selectedFiles, setSelectedFiles] = useState([]);
    // const [status,setstatus]=useState('');

    const [jobsDynamicData, updateJobsDynamicData] = useState({});
    const [isContact, setIsConatct] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const [catId, setCatId] = useState('')
    const [roomRentsDynamicData, updateRoomRentsDynamicData] = useState({}),
        [roomRentsAdsId, updateRoomRentsAdsId] = useState(null),
        [imagesDataArr, updateImagesDataArr] = useState([]),
        [preferredAgeArr, updatePreferredAgeArr] = useState([]),
        [selectedTagline, upatedSelectedTagline] = useState([]),
        [isCheckedForFreeNegotiable, toggleIsCheckedForFreeNegotiable] = useState(false),
        [rentalTypesCategories, updateRentalTypeCategories] = useState([]),
        [images, updateImage] = useState([]),
        [formData, updateFormData] = useState({
            title: "",
            descriptions: "",
            listerType: "",
            rental_type: "",
            custom_date: null,
            availability: "",
            accommodates: "",
            attachedBath: "",
            rent: null,
            rent_info: roomRentsDynamicData?.rent_info?.[0],
            rent_option: null,
            currency: roomRentsDynamicData?.currency?.[0],
            whoAreU: "",
            is_contact: false,
            category: "",
            isPetFriendly: null,
            isSmokingAllowed: null,
            isAlcoholAllowed: "",
            occupation: "",
            preferredGender: "",
            furnished: "",
            tagline: [],
            location: "",
            latitude: "",
            longitude: "",
            location_name: "",
            prefered_age: [],
            name: JSON.parse(localStorage.getItem(`user`))?.name,
            email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
            // primary_phone_number: rentDetailsData?.lister_basic_info?.primary_mobile_number?.primary_phone_number
            //     ? rentDetailsData?.lister_basic_info?.primary_mobile_number?.primary_phone_number : JSON.parse(localStorage.getItem(`user`))?.phone_number,
            primary_phone_number: JSON.parse(localStorage.getItem(`user`))?.phone_number,
            secondary_phone_number: "",
            hide_email_address: false,
            hide_phone_number: false,
            website_link: "",
            adsType: "",
            status: "draft"
        });

    const {
        title,
        descriptions,
        listerType,
        rental_type,
        availability,
        category,
        custom_date,
        accommodates,
        attachedBath,
        rent,
        rent_info,
        rent_option,
        currency,
        whoAreU,
        is_contact,

        isPetFriendly,
        isAlcoholAllowed,
        isSmokingAllowed,
        prefered_age,
        occupation,
        preferredGender,
        furnished,
        location,
        location_name,
        latitude,
        longitude,
        tagline,
        name,
        email_address,
        primary_phone_number,
        secondary_phone_number,
        hide_phone_number,
        hide_email_address,
        website_link,
        adsType,
        status
    } = formData;

    console.log(category, "rentalCategory===09876")

    console.log(primary_phone_number, "primary_phone_number")

    console.log("formDataaaaaaaa====0", formData)
    console.log(category, "categoryyryr");
    console.log(formData, "formDataformData")

    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState("");

    // Handle typing in the input field (with character limit)
    const handleTagInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 25) {
            setInputValue(value);  // Only update the input if the length is <= 25
        }
    };

    // Handle adding a tag (either pressing Enter or clicking the Add button)
    const handleAddTag = () => {
        if (inputValue.trim() && !tags.includes(inputValue)) {
            updateFormData((prevData) => ({
                ...prevData,
                tagline: [...prevData.tagline, inputValue],  // Append to tagline array
            }));
            setTags([...tags, inputValue]);  // Add the tag to the list
            setInputValue("");  // Clear the input field
        }
    };

    // Handle tag removal
    const handleRemoveTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));  // Remove the tag from the list
    };

    // Handle the Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleAddTag();
        }
    };

    console.log(rentalTypesCategories, "rentalTypesCategories===0")

    const handleChangeCheckkBox = (e) => {

        // Destructuring
        const { value, checked } = e.target;
        // debugger

        if (checked) {

            if (value.toLocaleLowerCase() === `18-any`) updatePreferredAgeArr([value]);
            else {
                const filteredData = preferredAgeArr.filter((data) => data.toLocaleLowerCase() !== `18-any`)
                updatePreferredAgeArr([...filteredData, value]);
            }

            //updatePreferredAgeArr([...preferredAgeArr, value]);
        }
        // Case 2  : The user unchecks the box
        else {
            updatePreferredAgeArr(preferredAgeArr.filter((e) => e !== value));
        }

    };

    const onChangeForDate = (date, dateString) => {
        console.log("dateString", dateString);
        updateFormData({
            ...formData,
            custom_date: dateString
        })
    }

    const handleOnChangeFreeRegularFreePrice = () => {
        toggleIsCheckedForFreeNegotiable(!isCheckedForFreeNegotiable)
        console.log(isCheckedForFreeNegotiable)
    };

    function disabledDate(current) {
        return current && current < moment().startOf('day');
        // Disable all dates before today's date
    }

    const onChangeHandlerForForm = (e) => {
        console.log(currency,isPetFriendly,"currencyyyyyy")
        const inputName = e?.target?.name;
        let inputValue = e?.target?.value;

        if (inputName === `primary_phone_number` || inputName === `secondary_phone_number`) {
            const inputNumber1 = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

            if (inputNumber1.length <= 15) {
                updateFormData((prev) => ({
                    ...prev,
                    [inputName]: inputValue

                }));
            }
        }

        if (inputName === 'category') {

            const selectedCategory = rentalTypesCategories.find(cat => cat._id === inputValue);
            if (selectedCategory) {
                updateFormData(prev => ({
                    ...prev,
                    category: selectedCategory._id  // Update the entire category object, not just the _id
                }));
                setCatId(selectedCategory._id)
            }
        } else if (inputName === 'rental_type') {
            const category = roomRentsDynamicData.categories.find(cat => cat._id === inputValue);
            if (category) {
                // Extract subcategory objects
                const subcategories = category.subcategory || [];

                console.log(subcategories, "subcategoriesewiieiee");

                // Update the state for subcategories
                // setCatId(category._id)
                updateRentalTypeCategories(subcategories);
            }
            updateFormData(prev => ({
                ...prev,
                [inputName]: inputValue
            }));
        } else {
            updateFormData(prev => ({
                ...prev,
                [inputName]: inputValue
            }));
        }
    };

    const [isHideMobileNumberChecked, toggleIsHideMobileNumberChecked] = useState(false),
        [isHideEmailAddressChecked, toggleIsHideEmailAddressChecked] = useState(false),
        [isHideEmailAddress1Checked, toggleIsHideEmailAddress1Checked] = useState(false);


    // lister info
    const hideEmailNumberSwitchButtonHandler = (e) => {

        const inputName = e?.target?.name;

        if (inputName === `hide_secondary_phone_number`) toggleIsHideEmailAddressChecked(!isHideEmailAddressChecked)
        if (inputName === `hide_primary_phone_number`) toggleIsHideMobileNumberChecked(!isHideMobileNumberChecked)
        if (inputName === `hide_my_email_address`) toggleIsHideEmailAddress1Checked(!isHideEmailAddress1Checked)

    }

    // const handleFormSubmit = async (e) => {
    //     e.preventDefault();
    //     toast.dismiss();
    //     localStorage.removeItem("start_date");
    //     localStorage.removeItem("end_date");

    //     const missingData = [],
    //         invalidData = [];

    //     if (steps === 0) {
    // console.log(category,"formcategory====0")
    //         if (!(rental_type || category || title || rent || availability || accommodates || attachedBath || whoAreU || tagline || location_name || descriptions)) return toast.error(`Please provide all data`);

    //         if (!isTruthyString(rental_type)) missingData.push(`Rental type${rental_type?._id}${rental_type} ${status}`);
    //         // if (!isTruthyString(!(adsId || adsIds) ? category : category?._id)) missingData.push(`Rental category`);
    //         if (!isTruthyString(category)) missingData.push(`Rental category`);
    //         if (!isTruthyString(title)) missingData.push(`Title`);

    //         if (!rent_option) missingData.push("Rent option");
    //         // else if (rent_option && isNaN(Number(rent_option))) invalidData.push("Rent option");

    //         if (Number(rent_option) === 2 && !rent) missingData.push("Rent amount");
    //         else if (Number(rent_option) === 2 && rent && isNaN(rent)) invalidData.push("Rent amount");

    //         if (!isTruthyString(availability)) missingData.push(`Availability`);
    //         else if (availability && availability === "Custom date") {
    //             if (!isTruthyString(custom_date)) missingData.push("Availability date")
    //         }

    //         if (accommodates && isNaN(accommodates)) missingData.push(`Accommodates`);
    //         if (attachedBath && isNaN(attachedBath)) missingData.push(`Attached bath`);
    //         if (whoAreU && !isTruthyString(whoAreU)) missingData.push(`who are you`);

    //         if (/^0+$|^0+[1-9][0-9]*$/.test(rent)) {
    //             return toast.error(`Please enter a valid amount`);
    //         }
    //         if (rental_type === 'Rooms for Rent' && !isTruthyString(furnished)) missingData.push(`furnished`);

    //         // alert(`checkcheck${steps}`);
    //         updateSteps(steps + 1);

    //         // if (images && images.length === 0) missingData.push(`Images`)
    //     }
    //     else if (steps === 1) {
    //         // if (prefered_age && !isTruthyString(prefered_age)) invalidData.push("Prefered age")

    //         let convertedPreferGender = null;

    //         // if (preferredGender === "Male") convertedPreferGender = 1;
    //         // else if (preferredGender === "Female") convertedPreferGender = 2;
    //         // else if (preferredGender === "Any Gender") convertedPreferGender = 3;

    //         if (preferredGender && !isTruthyString(preferredGender)) missingData.push("preferred gender");

    //         if (furnished && !isTruthyString(furnished)) missingData.push(`furnished`);
    //         // if (tagline && !isTruthyString(tagline)) missingData.push(`Tagline`);
    //         // if (!isTruthyString(location)) missingData.push(`Location`);

    //         if (!isTruthyString(location_name)) missingData.push('location');
    //         else if (!locationResponseData && !adsIds) {
    //             return toast.error('Please select a location from the suggestions');
    //         }

    //         if (!isTruthyString(descriptions)) missingData.push(`description`);
    //         else if (isDescriptionHas5Words(descriptions)) {
    //             return toast.error("You must write at least 5 words in your description");
    //         }
    //         updateSteps(steps + 1);

    //     } else if (steps === 2) {

    //         if (!(name || email_address || primary_phone_number || secondary_phone_number || primary_phone_number)) return toast.error(`Please provide all data`)

    //         if (!isTruthyString(name)) missingData.push(`Name`);
    //         if (!email_address) missingData.push(`Email address`);
    //         else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`Email address`);

    //         if (!primary_phone_number) missingData.push(`Primary phone number`);
    //         else if (primary_phone_number && isNaN(primary_phone_number)) invalidData.push(`Primary phone number`);
    //         else if (primary_phone_number && (primary_phone_number.length < 10 || primary_phone_number.length > 15)) invalidData.push(`Primary phone number`);

    //         if (secondary_phone_number && isNaN(secondary_phone_number)) invalidData.push(`Secondary phone number`);
    //         else if (secondary_phone_number && (secondary_phone_number.length < 10 || secondary_phone_number.length > 15)) invalidData.push(`secondary phone number`);

    //         if ((secondary_phone_number && secondary_phone_number) && primary_phone_number === secondary_phone_number) return toast.error(`Primary phone number can not be same as secondary phone number`)
    //     }

    //     if (steps == 2 && adsIds && status === "active") {
    //         toast.success("Ad edit successfully");
    //         window.location.reload();
    //         updateSteps(steps + 1);
    //     }

    //     let formDataObj = {
    //         negotiable: isCheckedForFreeNegotiable ? false : isCheckedForFreeNegotiable,
    //         is_contact,
    //         adsType: adsCategoriesId || adsType,
    //         rent_info,
    //         currency,
    //         rental_type: rental_type,
    //         listerType: `Owner`,
    //         roomType: 1,
    //         preferedGender: `Male`,
    //         hide_phone_number: isHideMobileNumberChecked,
    //         hide_email_address: isHideEmailAddressChecked,
    //         redirect_uri_success: "menehariya.netscapelabs.com/success",
    //         status
    //     };
    //     // alert(steps === 3 && status === 'draft' && (adsIds || adsId)?true:false);
    //     if (title) formDataObj.title = title;
    //     // if (category) formDataObj.category = ((adsId || adsIds)) ? category?._id : category;
    //     if (category) formDataObj.category = category;
    //     if (roomRentsDynamicData?.rent_info) formDataObj.rent_info = roomRentsDynamicData?.rent_info[0];
    //     if (roomRentsDynamicData?.currency) formDataObj.currency = roomRentsDynamicData?.currency[0];
    //     if (custom_date) formDataObj.custom_date = availability?.custom_date ? custom_date : null;
    //     if (descriptions) formDataObj.descriptions = descriptions;
    //     if (preferredAgeArr && preferredAgeArr.length) formDataObj.prefered_age = preferredAgeArr
    //     if (descriptions) formDataObj.descriptions = descriptions;
    //     if (accommodates) formDataObj.accommodates = accommodates;
    //     if (attachedBath) formDataObj.attachedBath = attachedBath;
    //     if (rent) formDataObj.amount = rent;
    //     if (isCheckedForFreeNegotiable) formDataObj.negotiable = isCheckedForFreeNegotiable;
    //     if (isContact) formDataObj.is_contact = rent ? false : true;
    //     // if (currency) formDataObj.currency = currency;

    //     if (isPetFriendlyr) formDataObj.isPetFriendly = isPetFriendlyr;
    //     if (isSmokingAllowed) formDataObj.isSmokingAllowed = isSmokingAllowed;
    //     if (isAlcoholAllowed) formDataObj.isAlcoholAllowed = isAlcoholAllowed;
    //     if (occupation) formDataObj.occupation = occupation;
    //     if (preferredGender) formDataObj.preferred_gender = preferredGender;
    //     if (tagline) formDataObj.tagline = tagline;

    //     if (furnished) formDataObj.furnished = furnished;
    //     if (location_name) formDataObj.location_name = location_name;
    //     if (latitude) formDataObj.latitude = latitude;
    //     if (longitude) formDataObj.longitude = longitude;
    //     if (name) formDataObj.name = name;
    //     if (email_address) formDataObj.email_address = email_address;
    //     if (primary_phone_number) formDataObj.primary_phone_number = primary_phone_number;
    //     if (secondary_phone_number) formDataObj.secondary_phone_number = secondary_phone_number;
    //     if (website_link) formDataObj.website_link = website_link;

    //     if (isHideMobileNumberChecked) formDataObj.hide_my_phone = isHideMobileNumberChecked;
    //     if (isHideEmailAddressChecked) formDataObj.hide_my_secondary_phone = isHideEmailAddressChecked;


    //     const roomRentsFormData = new FormData();

    //     if ((adsId || adsIds) && status === 'draft') {
    //         // alert("infirsty")
    //         steps === 2 && selectedFiles.forEach((file, index) => {
    //             roomRentsFormData.append('photos', file);
    //         })
    //     }
    //     if (!((adsId || adsIds) && status === 'draft')) {
    //         // alert("insecond")
    //         steps === 2 && selectedFiles.forEach((file, index) => {
    //             roomRentsFormData.append('photos', file);
    //         })
    //     }

    //     if (status === 'active') {
    //         // alert("inthird")
    //         steps === 1 && selectedFiles.forEach((file, index) => {
    //             roomRentsFormData.append('photos', file);
    //         })
    //     }

    //     console.log(formDataObj, "formDataObj")


    //     const data = formDataAppender(formDataObj, roomRentsFormData);
    //     // debugger


    //     // for (const key in data) {
    //     //     roomRentsFormData.append(key, formData[key]);
    //     // }
    //     //last dshikj

    //     // for (const pair of roomRentsFormData.entries()) {
    //     //     console.log(`${pair[0]}, ${pair[1]}`);
    //     // }

    //     if (steps === 1 && !roomRentsAdsId && !adsIds) {
    //         console.log(`vwgewvfw`)
    //         // debugger
    //         toggleLoader(true);

    //         const updatedFetchConfig = fetchConfig;
    //         delete updatedFetchConfig.headers['Content-Type'];

    //         fetch(`${appURL}/posts/room-rents`, {
    //             ...updatedFetchConfig,
    //             body: data
    //         })
    //             .then(handleFetchErrors)
    //             .then((res) => {
    //                 console.log(res)
    //                 console.log(res.roomtRentObjToSend.advertisement_id, "my_new_id");
    //                 localStorage.setItem('advertisementId', res.roomtRentObjToSend.advertisement_id)
    //                 toggleLoader(false)
    //                 if (res?.status == 200) {

    //                     const roomRentsAdsObjectId = res?.roomtRentObjToSend?._id

    //                     updateSteps(steps + 1);
    //                     updateRoomRentsAdsId(roomRentsAdsObjectId);

    //                     // localStorage.setItem('adsIdAndType', JSON.stringify({
    //                     //     id:roomRentsAdsObjectId,
    //                     //     adsType: "rentals"
    //                     // }));


    //                     localStorage.setItem('adsInfo', JSON.stringify({
    //                         id: roomRentsAdsObjectId,
    //                         title: title,
    //                         adsType: "rentals",
    //                         adsCategories: adsCategoriesId
    //                     }));

    //                 } else {
    //                     return toast.error(res?.message);
    //                 }
    //                 console.log(`resresresres`, res);
    //             })
    //             .catch((err) => {
    //                 toggleLoader(false);
    //                 console.log(err)
    //             });
    //     } else if ((steps === 1 || steps === 2) && roomRentsAdsId || adsIds) {
    //         console.log(`WORKING`)
    //         toggleLoader(true);

    //         const updatedFetchConfig = fetchConfig;
    //         delete updatedFetchConfig.headers['Content-Type'];

    //         // roomRentsFormData.delete('photos');

    //         fetch(`${appURL}/ads/room-rents/edit/${roomRentsAdsId || adsIds}`, {
    //             ...updatedFetchConfig,
    //             method: "PATCH",
    //             body: data
    //         })
    //             .then(handleFetchErrors)
    //             .then((res) => {
    //                 console.log(res, "5555")
    //                 console.log(res.updateRoomAdObjToSend.advertisement_id, "my_new_id");
    //                 localStorage.setItem('advertisementId', res.updateRoomAdObjToSend.advertisement_id)
    //                 toggleLoader(false)
    //                 // setadsIds("")
    //                 if (res?.status === 200) {
    //                     toggleLoader(false)
    //                     // window.location.reload()
    //                     updateSteps(steps + 1);
    //                     // if(steps==2){
    //                     //     // if(status){}
    //                     //     // setReplacerental(false)
    //                     //     setadsIds("")
    //                     //     // toggleModalForPostAdscategories(false)
    //                     // }


    //                 } else {
    //                     return toast.error(res?.message);
    //                 }
    //                 // console.log(`resresresres`, res)
    //             })
    //             .catch((err) => {
    //                 toggleLoader(false);
    //                 console.log(err)
    //             });
    //     }
    // };

    console.log("renttttttt",rent,rent_option,currency,roomRentsDynamicData?.currency?.[0])


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        toast.dismiss();
        localStorage.removeItem("start_date");
        localStorage.removeItem("end_date");
    
        const missingData = [],
            invalidData = [];
    
        // Step 0 - Validation for first step
        if (steps === 0) {
            console.log(category, "formcategory====0");
    
            if (!(rental_type || category || title || rent || availability || accommodates || attachedBath || whoAreU || tagline || location_name || descriptions)) {
                return toast.error("Please provide all data");
            }
    
            if (!isTruthyString(rental_type)) missingData.push(`Rental type: ${rental_type?._id}${rental_type} ${status}`);
            if (!isTruthyString(category)) missingData.push(`Rental category`);
            if (!isTruthyString(title)) missingData.push(`Title`);
            if (!rent_option) missingData.push("Rent option");
    
            if (Number(rent_option) === 2 && !rent) missingData.push("Rent amount");
            else if (Number(rent_option) === 2 && rent && isNaN(rent)) invalidData.push("Rent amount");
    
            if (!isTruthyString(availability)) missingData.push(`Availability`);
            else if (availability === "Custom date" && !isTruthyString(custom_date)) missingData.push("Availability date");
    
            if (accommodates && isNaN(accommodates)) missingData.push(`Accommodates`);
            if (attachedBath && isNaN(attachedBath)) missingData.push(`Attached bath`);
            if (whoAreU && !isTruthyString(whoAreU)) missingData.push(`Who are you`);
    
            if (/^0+$|^0+[1-9][0-9]*$/.test(rent)) {
                return toast.error("Please enter a valid amount");
            }
    
            if (rental_type === 'Rooms for Rent' && !isTruthyString(furnished)) missingData.push(`Furnished`);
    
            // Show errors if needed
            if (missingData.length || invalidData.length) {
                if (missingData.length) toast.error(`Missing Data:- ${missingData.join(`, `)}`);
                if (invalidData.length) toast.error(`Invalid Data:- ${invalidData.join(`, `)}`);
                return;
            }
    
            updateSteps(steps + 1);
    
        }
        // Step 1 - Validation for second step
        else if (steps === 1) {
            if (!isTruthyString(location_name)) missingData.push('Location');
            else if (!locationResponseData && !adsIds) {
                return toast.error('Please select a location from the suggestions');
            }
    
            if (!isTruthyString(descriptions)) missingData.push('Description');
            else if (isDescriptionHas5Words(descriptions)) {
                return toast.error("You must write at least 5 words in your description");
            }
     // Show errors if needed
     if (missingData.length || invalidData.length) {
        if (missingData.length) toast.error(`Missing Data:- ${missingData.join(`, `)}`);
        if (invalidData.length) toast.error(`Invalid Data:- ${invalidData.join(`, `)}`);
        return;
    }

    
            updateSteps(steps + 1);
        }
        // Step 2 - Validation for third step
        else if (steps === 2) {
            if (!(name || email_address || primary_phone_number || secondary_phone_number)) {
                return toast.error("Please provide all data");
            }
    
            if (!isTruthyString(name)) missingData.push("Name");
            if (!email_address) missingData.push("Email address");
            else if (email_address && !isValidEmailAddress(email_address)) invalidData.push("Email address");
    
            if (!primary_phone_number) missingData.push("Primary phone number");
            else if (primary_phone_number && isNaN(primary_phone_number)) invalidData.push("Primary phone number");
            else if (primary_phone_number && (primary_phone_number.length < 10 || primary_phone_number.length > 15)) invalidData.push("Primary phone number");
    
            if (secondary_phone_number && isNaN(secondary_phone_number)) invalidData.push("Secondary phone number");
            else if (secondary_phone_number && (secondary_phone_number.length < 10 || secondary_phone_number.length > 15)) invalidData.push("Secondary phone number");
    
            if (secondary_phone_number && primary_phone_number === secondary_phone_number) {
                return toast.error("Primary phone number cannot be the same as secondary phone number");
            }
    
            // Show errors if needed
            if (missingData.length || invalidData.length) {
                if (missingData.length) toast.error(`Missing Data:- ${missingData.join(`, `)}`);
                if (invalidData.length) toast.error(`Invalid Data:- ${invalidData.join(`, `)}`);
                return;
            }
    
    
            updateSteps(steps + 1);
        }
    
        if (steps === 2 && adsIds && status === "active") {
            toast.success("Ad edited successfully");
            window.location.reload();
            updateSteps(steps + 1);
        }
    
        let formDataObj = {
            negotiable: isCheckedForFreeNegotiable ? false : isCheckedForFreeNegotiable,
            is_contact,
            amount: rent || 0, 
            adsType: adsCategoriesId || adsType,
            rent_info,
            currency : currency || roomRentsDynamicData?.currency?.[0],
            rental_type,
            listerType: "Owner",
            roomType: 1,
            preferedGender: "Male",
            hide_phone_number: isHideMobileNumberChecked,
            hide_email_address: isHideEmailAddressChecked,
            redirect_uri_success: "menehariya.netscapelabs.com/success",
            status
        };
    
        if (title) formDataObj.title = title;
        if (category) formDataObj.category = category;
        if (roomRentsDynamicData?.rent_info) formDataObj.rent_info = roomRentsDynamicData?.rent_info[0];
        // if (roomRentsDynamicData?.currency) formDataObj.currency = roomRentsDynamicData?.currency[0];
        if (currency) formDataObj.currency = currency;
        if (custom_date) formDataObj.custom_date = custom_date ? custom_date : null;
        if (descriptions) formDataObj.descriptions = descriptions;
        if (preferredAgeArr && preferredAgeArr.length) formDataObj.prefered_age = preferredAgeArr;
        if (accommodates) formDataObj.accommodates = accommodates;
        if (attachedBath) formDataObj.attachedBath = attachedBath;
        if (rent) formDataObj.amount = rent;
        if (isCheckedForFreeNegotiable) formDataObj.negotiable = isCheckedForFreeNegotiable;
        formDataObj.is_contact = rent === null || rent === 0 ? true : false;
        if (isPetFriendly) formDataObj.isPetFriendly = isPetFriendly !== "2" ? true : false ;
        if (isSmokingAllowed) formDataObj.isSmokingAllowed = isSmokingAllowed !== "2" ? true : false ;
        if (isAlcoholAllowed) formDataObj.isAlcoholAllowed = isAlcoholAllowed !== "2" ? true : false ;
        if (occupation) formDataObj.occupation = occupation;
        if (preferredGender) formDataObj.preferedGender = preferredGender;
        if (tagline) formDataObj.tagline = tagline;
        if(whoAreU) formDataObj.listerType = whoAreU;
        if (furnished) formDataObj.furnished = furnished;
        if (location_name) formDataObj.location_name = location_name;
        if (latitude) formDataObj.latitude = latitude;
        if (longitude) formDataObj.longitude = longitude;
        if (name) formDataObj.name = name;
        if (email_address) formDataObj.email_address = email_address;
        if (primary_phone_number) formDataObj.primary_phone_number = primary_phone_number;
        if (secondary_phone_number) formDataObj.secondary_phone_number = secondary_phone_number;
        if (website_link) formDataObj.website_link = website_link;
        if (isHideMobileNumberChecked) formDataObj.hide_my_phone = isHideMobileNumberChecked;
        if (isHideEmailAddressChecked) formDataObj.hide_my_secondary_phone = isHideEmailAddressChecked;
    
        const roomRentsFormData = new FormData();
    
        // Handle photo uploads
        if ((adsId || adsIds) && status === 'draft') {
            steps === 2 && selectedFiles.forEach((file) => roomRentsFormData.append('photos', file));
        }
    
        if (!((adsId || adsIds) && status === 'draft')) {
            steps === 2 && selectedFiles.forEach((file) => roomRentsFormData.append('photos', file));
        }
    
        if (status === 'active') {
            steps === 1 && selectedFiles.forEach((file) => roomRentsFormData.append('photos', file));
        }
    
        console.log(formDataObj, "formDataObj");
    
        const data = formDataAppender(formDataObj, roomRentsFormData);
    
        if (steps === 1 && !roomRentsAdsId && !adsIds) {
            toggleLoader(true);
            const updatedFetchConfig = fetchConfig;
            delete updatedFetchConfig.headers['Content-Type'];
    
            fetch(`${appURL}/posts/room-rents`, {
                ...updatedFetchConfig,
                body: data
            })
                .then(handleFetchErrors)
                .then((res) => {
                    console.log(res);
                    localStorage.setItem('advertisementId', res.roomtRentObjToSend.advertisement_id);
                    toggleLoader(false);
                    if (res?.status === 200) {
                        const roomRentsAdsObjectId = res?.roomtRentObjToSend?._id;
                        updateSteps(steps + 1);
                        updateRoomRentsAdsId(roomRentsAdsObjectId);
                        localStorage.setItem('adsInfo', JSON.stringify({ id: roomRentsAdsObjectId, title, adsType: "rentals", adsCategories: adsCategoriesId }));
                    } else {
                        return toast.error(res?.message);
                    }
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err);
                });
        } else if ((steps === 1 || steps === 2) && roomRentsAdsId || adsIds) {
            toggleLoader(true);
            const updatedFetchConfig = fetchConfig;
            delete updatedFetchConfig.headers['Content-Type'];
    
            fetch(`${appURL}/ads/room-rents/edit/${roomRentsAdsId || adsIds}`, {
                ...updatedFetchConfig,
                method: "PATCH",
                body: data
            })
                .then(handleFetchErrors)
                .then((res) => {
                    console.log(res, "5555");
                    localStorage.setItem('advertisementId', res.updateRoomAdObjToSend.advertisement_id);
                    toggleLoader(false);
                    if (res?.status === 200) {
                        updateSteps(steps + 1);
                    } else {
                        return toast.error(res?.message);
                    }
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err);
                });
        }
    };
    console.log(rent_option,"rent_optio")

    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };

    // selecting location
    const callBackFunction = (address = '', lat = '', lng = '') => {
        updateFormData({
            ...formData,
            location_name: address,
            latitude: lat,
            longitude: lng,
        });
    }

    const backBtnClickHandler = () => {
        if (steps === 0) return toggleModalVisibilty("roomRents", false);
        updateSteps(steps - 1)
    }

    // useEffect(() => {
    //     if (catId && roomRentsDynamicData?.categories) {
    //         console.log(catId, roomRentsDynamicData.categories, "myCatttttttttt==00");

    //         let matchedCategory = null;

    //         // Find the category and update the formData
    //         roomRentsDynamicData.categories.some(category => {
    //             const hasSubcategory = category.subcategory.some(subcat => subcat._id === catId);

    //             if (hasSubcategory) {
    //                 matchedCategory = category;

    //                 // Update formData with the category ID in rental_type
    //                 updateFormData({
    //                     ...formData,
    //                     rental_type: category._id,
    //                 });

    //                 // Log the category ID directly
    //                 console.log(rental_type,category._id, "myCatttttttttt==00111"); // This ensures the ID is logged
    //             }

    //             return hasSubcategory; // Stop iteration if found
    //         });

    //         if (matchedCategory) {
    //             console.log(matchedCategory.subcategory, "Matched Subcategories");
    //             updateRentalTypeCategories(matchedCategory.subcategory); // Update the subcategory array
    //         } else {
    //             console.warn("No matching category or subcategory found for ID:", catId);
    //         }
    //     }
    // }, [catId]);

    useEffect(() => {
        if (catId && roomRentsDynamicData?.categories) {
            console.log(catId, rental_type,roomTypeRadioBtnOption, roomRentsDynamicData.categories, "myCatttttttttt==00");

            let matchedCategory = null;

            roomRentsDynamicData.categories.some((category) => {
                const hasSubcategory = category.subcategory.some(subcat => subcat._id === catId);
                matchedCategory = category;
                // if (hasSubcategory) {
                //     matchedCategory = category;

                //     // Check if the rental_type has already been updated
                //     if (formData.rental_type !== category._id) {
                //         updateFormData({
                //             ...formData,
                //             rental_type: category._id,
                //         });
                //     }

                //     console.log(rental_type, category._id, "myCatttttttttt==00111");
                // }

                return hasSubcategory; // Stop iteration if found
            });

            if (matchedCategory) {
                console.log(matchedCategory.subcategory, "Matched Subcategories");
                updateRentalTypeCategories(matchedCategory.subcategory);
            } else {
                console.warn("No matching category or subcategory found for ID:", catId);
            }
        }
    }, [catId]);

    const fetchingDynamicsData = () => {
        fetch(`${appURL}/posts/room-rents/dynamics-data?PostType=${adsCategoriesId || "64e48fbcf9eeb14b6e2135c0"}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {
                if (res.status === 200) {
                    const roomRentsDynamicDataObj = res?.data;
                    if (roomRentsDynamicDataObj) {
                        // // Create an object to hold categories and their respective subcategories
                        // const categoriesWithSubcategories = {};

                        // roomRentsDynamicDataObj.categories.forEach(category => {
                        //     categoriesWithSubcategories[category.name] = category.subcategory.map(subcategory => ({
                        //         label: subcategory.name,
                        //         value: subcategory._id
                        //     }));
                        // });

                        updateRoomRentsDynamicData(roomRentsDynamicDataObj);

                        // // Create categories options dynamically
                        const dynamicCategoriesOptions = roomRentsDynamicDataObj.categories.map(category => ({
                            label: category.name,
                            value: category._id
                        }));

                        // Update state with categories options
                        updateRoomTypeRadioBtnOption(dynamicCategoriesOptions)

                        // console.log(categoriesWithSubcategories, "categoriesWithSubcategories");
                    }
                } else {
                    return toast.error(`Failed to fetch dynamics data`);
                }
            })
            .catch((err) => standardFetchHandlers.error(err));
    }

    //you can see i am getting id in rental_type but unable to show as selected radio button 
    const fetchingAdsById = () => {
        let id = adsId || adsIds
        fetch(`${appURL}/posts/room-rents/ad_details?adsId=${id}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(`resress`, res);
                //  debugger
                if (res?.status === 200) {
                    const data = res?.ads_details,
                        listerInfo = data?.lister_basic_info,
                        adsInfo = data?.adsInfo;
                    setrentDetailsData(res?.ads_details?.adsInfo);
                    setmyadsId(res?.ads_details?._id);
                    setmyadsType(res?.ads_details?.adsType);
                    setSelectedFiles(res?.ads_details?.adsInfo?.image);
                    setcontOrdate(data?.adsInfo?.availability?.immidiate ? true : false);
                    updateFormData((prev) => ({
                        ...prev,
                        availability: !data?.adsInfo?.availability?.immidiate ? 'Custom date' : 'Please contact',
                    }));

                    updateFormData({
                        ...formData,
                        rental_type: data?.adsInfo?.rental_type?._id
                    })

                    updateFormData({
                        ...formData,
                        category: data?.adsInfo?.category?._id
                    })

                    setCatId(data?.adsInfo?.category?._id)

                    updatePreferredAgeArr(adsInfo?.prefered_age)
                    setTags(adsInfo?.tagline)
                    toggleIsCheckedForFreeNegotiable(adsInfo?.rent?.negotiable)
                    const isContact = adsInfo?.rent?.is_contact === true;
                    const rentOptionName = isContact ? "Please Contact" : "Enter Your Amount";

                    const dataObj = {
                        ...formData,
                        title: adsInfo?.title,
                        descriptions: adsInfo?.descriptions,
                        whoAreU: adsInfo?.listerType,
                        rental_type: adsInfo?.rental_type?._id,
                        custom_date: adsInfo?.availability?.custom_date,
                        availability: availityRadioBtnOtionForRoomRents.find(option => option.label === (adsInfo?.availability?.immidiate === true ? "Immediate move in" : "Custom date"))?.label,
                        accommodates: adsInfo?.accommodates,
                        attachedBath: adsInfo?.attachedBath,
                        category: adsInfo?.category?._id,
                        rent: adsInfo?.rent?.amount,
                        rent_info: adsInfo?.rent_info,
                        rent_option: !adsInfo?.rent?.is_contact ? "2" : "1",
                        currency: adsInfo?.rent?.currency,
                        // whoAreU: "",
                        is_contact: adsInfo?.rent?.is_contact,
                        isPetFriendly: adsInfo?.isPetFriendly === true ? "1" : "2",
                        isSmokingAllowed: adsInfo?.isSmokingAllowed === true ? "1" : "2",
                        isAlcoholAllowed: adsInfo?.isAlcoholAllowed === true ? "1" : "2",
                        occupation: "",
                        preferredGender: adsInfo?.preferedGender,
                        furnished: adsInfo?.furnished,
                        prefered_age: adsInfo?.prefered_age,
                        tagline: adsInfo?.tagline || [],
                        location_name: adsInfo?.location?.location_name,
                        latitude: adsInfo?.location?.coordinates[1],
                        longitude: adsInfo?.location?.coordinates[0],
                        name: JSON.parse(localStorage.getItem(`user`))?.name,
                        email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
                        primary_phone_number: listerInfo?.primary_mobile_number?.primary_phone_number || "",
                        secondary_phone_number: JSON.parse(localStorage.getItem(`user`))?.secondary_phone_number,
                        hide_email_address: listerInfo?.hide_my_email || false,
                        hide_phone_number: listerInfo?.hide_my_phone || false,
                        website_link: data?.website_url,
                        adsType: data?.adsType,
                        status: data.status,
                    };
                    console.log(dataObj, "Corrected Data");
                    updateFormData(dataObj);
                }
            })
            .catch((err) => {
                console.log(err)
            });
    }

    useEffect(() => {
        fetchingDynamicsData();
        if (adsId || adsIds) fetchingAdsById();
    }, [])

    // useEffect(() => {
    //     fetchingDynamicsData();
    // }, [])

    let HeadingMessage = `Post your Ad`;
    let SubHeadingMessage = ``;


    if (steps === 3 && !adsIds && status === "draft") {
        HeadingMessage = `Post Your Ad`
    } else if (steps === 4) {
        SubHeadingMessage = ``
    } else if (steps === 2) {
        SubHeadingMessage = `Basic Information`
    }

    const dateFormat = 'MM-DD-YYYY';


    const handlingClearSelectInput = (name) => {
        switch (name) {
            case "isPetFriendly":
                updateFormData({
                    ...formData,
                    isPetFriendly: ``
                })
                break;

            case "whoAreU":
                updateFormData({
                    ...formData,
                    whoAreU: ``
                })
                break;

            case "furnished":
                updateFormData({
                    ...formData,
                    furnished: ``
                })
                break;


            case "isSmokingAllowed":
                updateFormData({
                    ...formData,
                    isSmokingAllowed: ``
                })

                break;
            case "preferredGender":
                updateFormData({
                    ...formData,
                    preferredGender: ``
                })
                break;

            case "attachedBath":
                updateFormData({
                    ...formData,
                    attachedBath: ``
                })
                break;

            default:
                break;
        }
        // updateFormData({
        //     ...formData,
        //     isPetFriendly: ``
        // })
    }

    console.log(prefered_age, "prefered_ageprefered_ageprefered_age");


    const abcfunction = (data) => {
        setlat(data.lat)
        setlng(data.lng)

        console.log(data.lat, data.lng, "Nothing")
    }

    console.log(formData.is_contact,"formData.is_contact")

    return (
        <main className={style.main_wrapper}>
            <PostAdsHeading
                headingText={
                    steps === 2 && status === 'active' ? 'Update Your Ad' : HeadingMessage
                }
                subHeadingText={SubHeadingMessage}
            />
            <form id={steps === 3 ? undefined : style.room_rents_form}>

                {
                    (() => {
<div></div>
                        if (steps === 0) {
                            return (
                                <>
                                    <Inputs
                                        label='Rental type'
                                        type='radio'
                                        required={true}
                                        name='rental_type'
                                        value={rental_type}
                                        // disabled
                                        onChangeHandler={onChangeHandlerForForm}
                                        id='span-2'
                                        radioBtnOtions={roomTypeRadioBtnOption}
                                        // checked={rental_type === roomTypeRadioBtnOption.value} 
                                    />

                                    <Inputs
                                        label='Category'
                                        type='select'
                                        required={true}
                                        id="span-2"
                                        name='category'
                                        value={category}// Ensure that this references the _id field of formData.category
                                        disabled={status !== "draft" && (adsIds ? true : false)}
                                        onChangeHandler={onChangeHandlerForForm}
                                        placeholder='Select category'
                                        selectInputOptions={rentalTypesCategories}  // Ensure this contains the correct categories
                                    />


                                    {/* {
                                        myCat === "" ?
                                            <Inputs
                                                label='Category'
                                                type='select'
                                                required={true}
                                                id="span-2"
                                                name='category'
                                                value={category}
                                                disabled={status !== "draft" && (adsIds ? true : false)}
                                                onChangeHandler={onChangeHandlerForForm}
                                                placeholder='Select category'
                                                // selectInputOptions={rentalTypesCategories?.length ? rentalTypesCategories : roomRentsDynamicData?.category_Room}
                                                selectInputOptions={rentalTypesCategories}
                                            /> :
                                            <Inputs
                                                type='select'
                                                name='category'
                                                value={category}
                                                label='Category'
                                                required={true} id='span-2'
                                                disabled={status !== "draft" && (adsIds ? true : false)}
                                                selectInputOptions={rentalTypesCategories}
                                            />
                                        // <Inputs
                                        //     label='Categoryy'
                                        //     type='text  '
                                        //     required={true}
                                        //     id="span-2"
                                        //     name='category'
                                        //     value={myCat}
                                        //     disabled={status !== "draft" && (adsIds ? true : false)}
                                        //     onChangeHandler={onChangeHandlerForForm}
                                        //     placeholder='Select category'
                                        // selectInputOptions={roomRentsDynamicData?.category_Room}
                                        // selectInputOptions={rentalTypesCategories.map(subcategory => (
                                        //     <option key={subcategory._id} value={subcategory._id}>
                                        //         {subcategory.name}
                                        //     </option>
                                        //      ))}
                                        // selectInputOptions={rentalTypesCategories.map(subcategory => (
                                        //     <option key={subcategory._id} value={subcategory._id}>
                                        //         {subcategory.name}
                                        //     </option>
                                        // ))}
                                        // />
                                    } */}


                                    <Inputs
                                        label='Title'
                                        type='text'
                                        id="span-2"
                                        name='title'
                                        value={title}
                                        required={true}
                                        onChangeHandler={onChangeHandlerForForm}
                                        placeholder='Enter ad title'
                                    />


                                    {
                                        <Inputs
                                            label='Rent Amount'
                                            type='select'
                                            id='span-2'
                                            required={true}
                                            name='rent_option'
                                            value={rent_option}
                                            adsIds={adsIds}
                                            placeholder='Select rent amount'
                                            onChangeHandler={onChangeHandlerForForm}
                                            selectInputOptions={optionsForSelectSalary}
                                        />
                                    }

                                    {
                                        Number(rent_option) === 2  ?
                                            <div id='span-2'>
                                                <div className={style.regular_price_wrapper}>
                                                    <span id='label'>Rent Amount <span className='required_field'>*</span></span>

                                                    <div className={style.free_regular_ticket_price_input_wrapper}>
                                                        <input checked={isCheckedForFreeNegotiable} name="is_regular_price_free" onChange={handleOnChangeFreeRegularFreePrice} className={style.free_regular_ticket_price_input} type="checkbox" id="vehicle1" />
                                                        <label for="vehicle1">Negotiable</label><br />
                                                    </div>

                                                </div>

                                                <div className={style.expected_salary_input_wrapper}>
                                                    <section>
                                                        <Inputs
                                                            inputId={style.currency_input_field}
                                                            type='select'
                                                            name='currency'
                                                            required={true}
                                                            value={currency}
                                                            onChangeHandler={onChangeHandlerForForm}
                                                            selectInputOptions={roomRentsDynamicData?.currency}
                                                        />
                                                    </section>
                                                    <section>


                                                        <Inputs
                                                            required={true}
                                                            inputId={style.rent_input}
                                                            id='span-2'
                                                            type='number'
                                                            name='rent'
                                                            value={rent}
                                                            onChangeHandler={onChangeHandlerForForm}
                                                            placeholder='Enter rent'
                                                        />
                                                        <div style={{ color: 'red', fontSize: "12px", marginTop: "10px" }}>{errorMessage}</div>
                                                    </section>

                                                    <section>

                                                        <Inputs
                                                            inputClassName={style.salary_input}
                                                            inputId={style.expected_salary_input_rate}
                                                            type='select'
                                                            required={true}
                                                            name='rent_info'
                                                            value={rent_info}
                                                            onChangeHandler={onChangeHandlerForForm}
                                                            selectInputOptions={roomRentsDynamicData?.rent_info}
                                                        />

                                                    </section>

                                                </div>


                                            </div> : ``

                                    }


                                    {/* <section id='span-2'>


                                        <div className={style.regular_price_wrapper}>
                                            <span id='label'>Rent Amount</span>

                                            <div className={style.free_regular_ticket_price_input_wrapper}>
                                                <input checked={isCheckedForFreeNegotiable} name="is_regular_price_free" onChange={handleOnChangeFreeRegularFreePrice} className={style.free_regular_ticket_price_input} type="checkbox" id="vehicle1" />
                                                <label for="vehicle1">Negotiable</label><br />
                                            </div>

                                        </div>

                                        <Inputs
                                            required={true}
                                            id='span-2'
                                            type='number'
                                            name='rent'
                                            value={rent}
                                            onChangeHandler={onChangeHandlerForForm}
                                            placeholder='Enter rent'
                                        />

                                    </section> */}

                                    <Inputs
                                        label='Availability'
                                        required={true}
                                        type='radio'
                                        name='availability'
                                        className={style.availability_input_section_wrapper}
                                        inputClassName={style.availability_input_section}
                                        value={availability}
                                        onChangeHandler={onChangeHandlerForForm}
                                        id='span-2'
                                        radioBtnOtions={rental_type == '652ce05317e3cbd2d3181a92' ? availityRadioBtnOtionForRoomRents : availityRadioBtnOtion}
                                    />


                                    {
                                        availability === 'Custom date' ?
                                            <section id='span-2'>
                                                <Space direction="vertical">
                                                    {/* <span>Select Custom Date<span className='required_field'>*</span></span> */}

                                                    {/* <DatePicker defaultValue={custom_date ? dayjs(custom_date, dateFormat) : ``} disabledDate={(current) => disabledDate(current, 1)} className={style.date_picter_wrapper} placeholder="Enter your availability date" onChange={(date, dateString) => onChangeForDate(date, dateString, 1)} /> */}

                                                    <DatePicker
                                                        defaultValue={custom_date ? dayjs(custom_date) : ''}
                                                        // disabledDate={(current) => disabledDate(current, 1)}
                                                        className={style.date_picker_wrapper}
                                                        placeholder="Enter your availability date"
                                                        rent_info format="MM-DD-YYYY" // Set the desired date format
                                                        onChange={(date, dateString) => onChangeForDate(date, dateString, 1)}
                                                        disabledDate={(current) => {
                                                            return current && (current.year() > 2030 || disabledDate(current, 1));
                                                        }}
                                                    />

                                                </Space>
                                            </section>
                                            :
                                            ``
                                    }



                                    {
                                        rental_type !== 'Other Rentals' && rental_type !== 'Commercial Property for Rent' ?
                                            <>


                                                {/* 
                                                <Inputs
                                                    label='Smoking allowed?'
                                                    type='radio'
                                                    name='isSmokingAllowed'
                                                    value={isSmokingAllowed}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    radioBtnOtions={[{
                                                        label: 'Yes',
                                                        value: '1'
                                                    },
                                                    {
                                                        label: 'No',
                                                        value: '2'
                                                    }]}
                                                /> */}

                                                {/* <Inputs
                                                    label='Smoking allowed?'
                                                    name='isSmokingAllowed'
                                                    value={isSmokingAllowed}
                                                    handlingClearSelectInput={handlingClearSelectInput}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    type='select'
                                                    placeholder='Select'
                                                    selectInputOptions={[{
                                                        name: 'Yes',
                                                        _id: '1'
                                                    },
                                                    {
                                                        name: 'No',
                                                        _id: '2'
                                                    }]}
                                                /> */}

                                                <Inputs
                                                    label='Smoking allowed?'
                                                    name='isSmokingAllowed'
                                                    value={isSmokingAllowed || isSmokingAllowed}
                                                    // handlingClearSelectInput={handlingClearSelectInput}
                                                    handlingClearSelectInput={handlingClearSelectInput}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    type='select2'
                                                    placeholder='Select'
                                                    selectInputOptions={[{
                                                        name: 'Yes',
                                                        _id: '1'
                                                    },
                                                    {
                                                        name: 'No',
                                                        _id: '2'
                                                    }]}
                                                />




                                                {/* <div>
                                                    <span id='label'>Pet Friendly?(Optional)</span>
                                                    <div className="radio">
                                                        <input onChange={onChangeHandlerForForm} name="isPetFriendly" checked={isPetFriendly} value={false} id="isPetFriendly1" type="radio" />
                                                        <label htmlFor='isPetFriendly1' className="radio-label">Yes</label>
                                                    </div>
                                                    <div className="radio">
                                                        <input onChange={onChangeHandlerForForm} name="isPetFriendly" value={true} id="isPetFriendly" type="radio" />
                                                        <label htmlFor='isPetFriendly' className="radio-label">No</label>
                                                    </div>
                                                </div> */}


                                                <Inputs
                                                    label='Pet Friendly?'
                                                    name='isPetFriendly'
                                                    handlingClearSelectInput={handlingClearSelectInput}
                                                    value={isPetFriendly}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    type='select2'
                                                    placeholder='Select'
                                                    selectInputOptions={[{
                                                        name: 'Yes',
                                                        _id: '1'
                                                    },
                                                    {
                                                        name: 'No',
                                                        _id: '2'
                                                    }]}
                                                />





                                                {/* <Inputs
                                                    label='Pet Friendly?'
                                                    type='radio'
                                                    name='isPetFriendly'
                                                    value={isSmokingAllowed}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    radioBtnOtions={[{
                                                        label: 'Yes',
                                                        value: '3'
                                                    },
                                                    {
                                                        label: 'No',
                                                        value: '4'
                                                    }]}
                                                /> */}




                                                <Inputs
                                                    label='Attached bath'
                                                    handlingClearSelectInput={handlingClearSelectInput}
                                                    type='select2'
                                                    name='attachedBath'
                                                    value={attachedBath}
                                                    updateState={updateFormData}
                                                    // handlingClearSelectInput={handlingClearSelectInput}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    placeholder='Select number of baths'
                                                    selectInputOptions={roomRentsDynamicData?.attachedBathRoom}
                                                />
                                            </>


                                            :
                                            ``
                                    }




                                    {
                                        rental_type === 'Rooms for Rent' || rental_type === 'Commercial Property for Rent' ?

                                            <Inputs
                                                label='Furnished'
                                                required={rental_type === 'Commercial Property for Rent' ? false : true}
                                                name='furnished'
                                                handlingClearSelectInput={rental_type === 'Commercial Property for Rent' ? handlingClearSelectInput : ``}
                                                // handlingClearSelectInput={handlingClearSelectInput}
                                                value={furnished}
                                                onChangeHandler={onChangeHandlerForForm}
                                                type={rental_type === 'Commercial Property for Rent' ? 'select2' : 'select'}
                                                placeholder='Select furnished type'
                                                selectInputOptions={roomRentsDynamicData?.furnished}
                                            />
                                            :
                                            ``

                                    }
                                    {
                                        rental_type !== 'Other Rentals' && <>
                                            <Inputs
                                                label='Accommodates'
                                                type='number'
                                                name='accommodates'
                                                formData={formData}
                                                updateState={updateFormData}
                                                value={accommodates}
                                                // onChangeHandler={onChangeHandlerForForm}
                                                onChangeHandler={(e) => {
                                                    const inputValue = e.target.value;
                                                    if (inputValue.length <= 4) {
                                                        onChangeHandlerForForm(e);
                                                    }
                                                }}
                                                // onChangeHandler={(e) => {
                                                //     const inputValue = e.target.value;
                                                //     // Ensure the value is a number and limit to 4 digits
                                                //     if (/^\d{0,4}$/.test(inputValue)) {
                                                //       onChangeHandlerForForm(e);
                                                //     }
                                                //   }}
                                                placeholder='Enter accommodates'

                                            />


                                            <Inputs
                                                label='Who are you?'
                                                type='select2'
                                                name='whoAreU'
                                                handlingClearSelectInput={handlingClearSelectInput}
                                                value={whoAreU}
                                                onChangeHandler={onChangeHandlerForForm}
                                                placeholder='Select'
                                                selectInputOptions={optionsForWhoAreYou}
                                            />

                                        </>
                                    }




                                </>
                            )
                        } else if (steps === 1) {
                            return <>

                                {
                                    rental_type === '652ce05317e3cbd2d3181a92' ?
                                        <>
                                            <Inputs
                                                name='prefered_age'
                                                selectOptionArrForCheckBox={preferredAgeArr}
                                                onChangeHandler={handleChangeCheckkBox}
                                                label="Preferred age"
                                                type='checkbox'
                                                id='span-2'
                                                checkboxInputOtions={roomRentsDynamicData?.prefered_age}
                                            />

                                            <Inputs
                                                label='Preferred gender'
                                                type='select2'
                                                id='span-2'
                                                name='preferredGender'
                                                value={preferredGender}
                                                handlingClearSelectInput={handlingClearSelectInput}
                                                onChangeHandler={onChangeHandlerForForm}
                                                placeholder='Select preferred gender'
                                                selectInputOptions={roomRentsDynamicData?.gender}
                                            />
                                        </>
                                        : ``
                                }

                                <section id="span-2">
                                    <div>
                                        <label className="tagline-main">Tagline (optional)</label>
                                        <div>
                                            <input
                                                type="text"
                                                name="tagline"
                                                value={inputValue}
                                                onChange={handleTagInputChange}
                                                onKeyPress={handleKeyPress}
                                                placeholder="Enter tagline"
                                            />
                                            <button onClick={handleAddTag} style={{ display: "none" }} >Add Tag</button>
                                        </div>


                                        <div>
                                            {tags.length > 0 && (
                                                <div>
                                                    {tags.map((tag, index) => (
                                                        <span key={index} style={{ margin: "5px", padding: "5px", border: "1px solid #ccc", display: "inline-block" }}>
                                                            {tag}
                                                            <button
                                                                onClick={() => handleRemoveTag(tag)}
                                                                style={{ marginLeft: "5px", cursor: "pointer", background: "red", color: "white", border: "none", borderRadius: "50%", width: "20px", height: "20px" }}
                                                            >
                                                                ×
                                                            </button>
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        {/* Display message if the limit is exceeded */}
                                        {inputValue.length > 25 && <p style={{ color: 'red' }}>Tag must be less than 25 characters</p>}
                                    </div>

                                </section>

                                {/* <section id='span-2'>
                                    <MultiSelect
                                        label='Tagline'
                                        id='tagline'
                                        value={selectedTagline}
                                        allowCreate={true}
                                        onCreate={onCreateteHandler}
                                        name='tagline'
                                        data={roomRentsDynamicData?.data?.tagline}
                                        dataKey="keywords"
                                        textField="name_email"
                                        placeholder="Enter tagline"
                                        onChange={(admin) => upatedSelectedTagline(admin)}
                                        onInputChange={(input) => {
                                            // Truncate input to 25 characters
                                            if (input.length > 25) {
                                                console.warn("Maximum character limit is 25.");
                                                return input.slice(0, 25); // Truncated input
                                            }
                                        }}
                                    />
                                </section> */}

                                <SelectLocation callBackFunction={callBackFunction} location={location_name}
                                    latitude={latitude}
                                    longitude={longitude}
                                    //    abc={abcfunction} 
                                    locationResponseData={locationResponseData}
                                    setLocationResponseData={setLocationResponseData}
                                    required={true} />

                                {/* <Inputs
                                    label='Description'
                                    type='textarea'
                                    required={true}
                                    name='descriptions'
                                    value={descriptions}
                                    onChangeHandler={onChangeHandlerForForm}
                                    placeholder='Enter description'
                                    id='span-2'
                                /> */}

                                <RichTextEditor value={descriptions} updateFormData={updateFormData} formData={formData} label="Description" required={true} />

                                {/* <input type="file" name='photos' onChange={handleFileChange} multiple/> */}

                                <ImageUpload images={images} updateImage={updateImage} handleFileChange={handleFileChange} setSelectedFiles={setSelectedFiles}
                                    selectedFiles={selectedFiles} myadsId={myadsId} myadsType={myadsType} />
                            </>
                        } else if (steps === 2) {
                            return <AdsListerInfo
                                onChangeHandler={onChangeHandlerForForm}
                                formData={formData}
                                isHideMobileNumberChecked={isHideMobileNumberChecked}
                                isHideEmailAddressChecked={isHideEmailAddressChecked}
                                isHideEmailAddress1Checked={isHideEmailAddress1Checked}
                                hideEmailNumberSwitchButtonHandler={hideEmailNumberSwitchButtonHandler}
                            />
                        }

                        else if (steps === 3 && !adsIds) {
                            return <AdsPlansUpdated
                                steps={steps}
                                updateSteps={updateSteps}
                                adsId={roomRentsAdsId}
                                adsCategoriesId={adsCategoriesId}
                            />
                        }

                        else if (steps === 3 && adsIds && status != "active") {
                            { console.log(status, "statusss") }
                            return <AdsPlansUpdated
                                steps={steps}
                                updateSteps={updateSteps}
                                adsId={roomRentsAdsId}
                                adsCategoriesId={adsCategoriesId || adsType}
                                adsIds={adsIds}
                            />
                        }

                        else if (steps === 4) {
                            return <CongratulationsScreen />
                        }

                    })()

                }

                {/* (steps !== 4 && steps !== 3) */}
                {
                    (steps !== 4 && steps !== 3) &&
                    <FormButton
                        cancelButtonText='Back'

                        submitBtnText={(steps === 2 && status === 'active') ? "Update My Ad" :
                            (steps === 3 && status === 'draft' && (adsIds || adsId)) ? "Update My Ad" : "Next"}

                        id="span-2"
                        className={style.form_button_section}
                        backBtnClickHandler={backBtnClickHandler}
                        onClickHandler={handleFormSubmit}
                        showLoader={showLoader}
                    />
                }


            </form>

        </main>
    )
}

