/**
 * @function
 * @description Appends all data in an object (including arrays and one-level-deep nested Objects) to the provided FormData instance
 * @param {Object} objToAppend Object whose keys/value pairs are to be appended to the provided FormData instance
 * @param {Object} formDataInst FormData instance to append provided object's key/value pairs to
 * @returns {Object} Modified FormData instance
 */

function formDataAppender(objToAppend = null, formDataInst = null) {
    if (objToAppend && typeof objToAppend === 'object') {
        for (let key in objToAppend) {
            if (objToAppend[key] && Array.isArray(objToAppend[key])) {
                objToAppend[key].forEach((value, index) => {
                    formDataInst.append(`${key}[${index}]`, value);
                });
            } else {
                formDataInst.set(key, objToAppend[key]);
            }
        }
    }

    return formDataInst;
}

export default formDataAppender;