import { useState, useEffect, useMemo,useRef } from 'react';
import Footer_menu from '../../component/footer_menu';
import style from './profile.module.css'
import VerifyOTP from '../../component/verify-otp-card/VerifyOTPCard';
import { useNavigate } from "react-router-dom"
import { toast } from 'react-toastify';
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import { isTruthyString, isValidEmailAddress, isValidIndianMobileNumber } from '../../utils/verification';
import DatePicker from "react-datepicker";
import { confirmUserActionsweetalert } from "../../utils/confirmUserAction"
import Header from '../../component/header/Header';
import LoaderButton from '../../component/buttons/loader-button/LoaderButton';
import CommonModalWrapper from '../../component/common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../../component/Icons/CloseIcon';
import "react-datepicker/dist/react-datepicker.css";
import VerifyOTPForEmail from '../../component/verify-top-for-email/VerifyOTPForEmail';
import VerifyOTPCard from '../../component/verify-otp-card/VerifyOTPCard';
import Inputs from '../../component/Inputs/Inputs';
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import appURL from '../../appURL';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import { CircularProgress } from '@mui/material';


export default function Profile() {
  const formRef = useRef(null);

  const baseURL = appURL();

  const [showLoader, toggleLoader] = useState(false);
  const [userName, updateUserName] = useState(``);

  const [nameInitial, updateNameInitial] = useState({
    firstName: ``,
    lastName: ``
  });
  const [showLoaderForEmailChange, toggleShowLoaderForEmailChange] = useState(false);
  const [locationReponseData, toogleLoactionResponseData] = useState(false);

  const [isFormDataModifieds, setIsFormDataModified] = useState(false);

  const [loginSource, updateLoginSource] = useState(false);
  const [address, updateAddress] = useState(``);
  const [coordinate, updateCoordinate] = useState({
    lat: ``,
    lng: ``
  });


  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    toogleLoactionResponseData(true)
    toggleIsProfileFieldChange(false)
    // Update the keyword of the input element
    setValue(e.target.value);
    setIsFormDataModified(true)
  };


  const handleSelect =
    ({ description }) =>
      () => {

        // When user selects a place, we can replace the keyword without request data from API
        // by setting the second parameter to "false"
        setValue(description, false);
        clearSuggestions();

        // Get latitude and longitude via utility functions
        getGeocode({ address: description }).then((results) => {
          updateAddress(results[0].formatted_address)
          const { lat, lng } = getLatLng(results[0]);
          updateCoordinate({
            lat: lat, lng: lng
          })

          // console.log("📍 Coordinates: ", { lat, lng });
        });
      };

  const [isDirty, setIsDirty] = useState(false);


  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());

  const [modalIsOpenForEmailChange, updateModalVisibilityForEmailChange] = useState(false);
  const [modalIsOpenForVerifyEmail, updateModalIsOpenForVerifyEmail] = useState(false);
  const [isModalOpenForEmailChangeInfo, toggleIsModalOpenForEmailChangeInfo] = useState(false);
  const [modalIsOpenForPhoneNumberChange, updateModalVisibilityForPhoneNumberChange] = useState(false);
  const [isMobileNumberExist, updateIsMobileNumberExist] = useState(false);
  const [isEmailAddressExist, updateIsEmailAddressExist] = useState(false);
  const [isProfileFieldChange, toggleIsProfileFieldChange] = useState(true);
  const [imageUpload, updateimageUpload] = useState({});
  const [loader,setloader]=useState(false);
  const [countryCodes,setcountryCodes]=useState([]);

  const [updated, setUpdated] = useState(false);


  const [deleteProfile, setDeleteProfile] = useState(false);



  const [modalIsOpenForVerifyOTP, updateModalVisibilityForVerifyOTP] = useState(false);

  const toggleModalForVerifyOTP = (toggleValue = false) => {
    // updateModalVisibilityForRegister(false)
    // toggleModalForRegister(false)
    updateModalVisibilityForVerifyOTP(toggleValue)
  }


  const fetchConfig = getFetchConfig();

  const [isLoading, setIsLoading] = useState(false),
    [userDetail, setUserDetail] = useState({}),
    [updateField, updateUpdateField] = useState({
      email_address: "",
      phone_number: "",
      country_code: "+91"
    }),
    [imgaeUrl, updateImageUrl] = useState(``),
    [user, setUser] = useState(null);


  const [isEmailAdressInputDisable, updateIsEmailAdressInputDisable] = useState(true),
    [isPhoneNumberInputDisable, updateIsPhoneNumberInputDisable] = useState(true);

  const { name, email_address, gender, date_of_birth, phone_number, profile_image, my_website, location, short_bio, public_info, private_info, created_at } = userDetail;
  const [showDialog, setShowDialog] = useState(false);



  //onChange handler function

  let isFormDataModified = false;
  const onChangeHandler = (e) => {
    setShowDialog(true)
    setUserDetail((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    toggleIsProfileFieldChange(false);
    isFormDataModified = true;
    setIsFormDataModified(true);
  };

  window.onbeforeunload = (e) => {
    if (isFormDataModified) {
      const confirmationMessage = 'You have unsaved changes. Are you sure you want to leave?';
      e.returnValue = confirmationMessage;  
      return confirmationMessage;
    }
  };


 

  //onChange handler function
  const onChangeHandlerForUpdate = (e) => {
    setShowDialog(true)
    updateUpdateField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    isFormDataModified = true;
    setIsFormDataModified(true);
  };


  useEffect(() => {
    localStorage.setItem('updated_email', updateField.email_address);
  }, [updateField.email_address]);



  const fetchingSellerAccountDetail = () => {
    setIsDirty(false);
    setloader(true);
    fetch(`${baseURL}/user-profile`, {
      ...fetchConfig,
      method: `GET`
    })
      .then(handleFetchErrors)
      .then((sellerAccountJOSNReponse) => {

        const sellerAccountDetail = sellerAccountJOSNReponse?.user;


        if (sellerAccountDetail) {

          // //destructing sller data Object
          const sellerDataObj = {
            name: sellerAccountDetail?.name,
            email_address: sellerAccountDetail?.email_address,
            gender: sellerAccountDetail?.gender,
            source: sellerAccountDetail?.source,
            phone_number: sellerAccountDetail?.phone_number,
            profile_image: sellerAccountDetail?.profile_image || ``,
            date_of_birth: sellerAccountDetail?.date_of_birth,
            my_website: sellerAccountDetail?.my_website || ``,
            short_bio: sellerAccountDetail?.short_bio || ``,
            location: sellerAccountDetail?.address || ``,
            created_at: sellerAccountDetail?.createdAt || ``,
          };


          localStorage.setItem(`profilePicture`, sellerAccountDetail?.profile_image);
          localStorage.setItem('listerInfoChange',sellerAccountDetail?.staticListerInfo);
          setStartDate(new Date(sellerAccountDetail?.date_of_birth) ? new Date(sellerAccountDetail?.date_of_birth) : new Date());

          if (sellerAccountDetail?.address && sellerAccountDetail?.address == "null") {
            setValue(``)
            updateAddress(``)
          } else {
            setValue(sellerAccountDetail?.address)
            updateAddress(sellerAccountDetail?.address)
          }

          if (sellerAccountDetail?.profile_image && sellerAccountDetail?.profile_image == "null") {
            updateImageUrl(``)
          } else {
            updateImageUrl(sellerAccountDetail?.profile_image)
          }

          if (sellerAccountDetail?.name) {
            updateUserName((sellerAccountDetail?.name).trim())
          }

          setUserDetail(sellerDataObj)

          clearSuggestions();
        }
        setloader(false);
      })
      .catch(standardFetchHandlers.error);
  };
  function isValidURL(string) {
    var res = string.match(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi);
    return (res !== null);
  };


  

  const onSubmitHandler = async (e, operation) => {
    if (e) {
      e.preventDefault(); 
    }

    const missingData = [],
      invalidData = [];

    // seller detail
    if (!isTruthyString(name)) missingData.push(`name`);

    // if (!email_address) missingData.push(`email`);
    // else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`email`);
    if (my_website && !isValidURL(my_website)) invalidData.push(`Website`);

    if (!startDate) missingData.push(`date of birth`);

    // Show errors if needed
    if (missingData.length || invalidData.length) {
      if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
      if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);

      return;
    }

    try {


      const leadFormData = new FormData();

      if (imageUpload && imageUpload?.name) {
        leadFormData.append(`picture`, imageUpload)
      } else {
        leadFormData.append('picture', String(imgaeUrl));
      }


      leadFormData.append('name', name);
      if (gender) leadFormData.append('gender', gender);
      leadFormData.append('date_of_birth', startDate);
      leadFormData.append('short_bio', short_bio);
      leadFormData.append('my_website', my_website);
      leadFormData.append('long', String(coordinate.lng));
      leadFormData.append('lat', String(coordinate.lat));
      leadFormData.append('location', String(address));

      if (operation === 2) {
        leadFormData.append('phone_number', "");
      }

      //append the values with key, value pair

      const updatedFetchConfig = fetchConfig;
      delete updatedFetchConfig.headers['Content-Type'];

      if (await confirmUserActionsweetalert()) {
        toggleLoader(true)

        const updateSellerAccountRawReponse = await fetch(`${baseURL}/update-profile`, {
          ...fetchConfig,
          method: `PATCH`,
          body: leadFormData
        });


        const updateSellerAccountJOSNReponse = await handleFetchErrors(updateSellerAccountRawReponse);

        console.log(`updateSellerAccountJOSNReponse`, updateSellerAccountJOSNReponse)

        if (updateSellerAccountJOSNReponse.status == 200) {


          toogleLoactionResponseData(false);
          toast.success(`Account details updated`);
          const userString = localStorage.getItem('user'); // Replace 'userKey' with the actual key you used to store the object
          let user = userString ? JSON.parse(userString) : null;

          // 2. Update the key (for example, let's update the 'phone_number')
          if (user) {
              user.name = updateSellerAccountJOSNReponse?.data?.name; // Replace 'newPhoneNumber' with the new value
          }
          // 3. Save the updated object back to localStorage
          localStorage.setItem('user', JSON.stringify(user));
          window.location.href = "/";  
          // window.location.reload(false);
        } else {
          toggleLoader(false)
          // setUpdated(!updated)
          // window.location.reload(false);
          toast.error(updateSellerAccountRawReponse.message);
        }
      }


    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false);
    }

  };

  // opening emal change modal
  const toggleModalForEmailChange = (toggleValue = false) => {
    updateModalVisibilityForEmailChange(toggleValue)
  }

  // opening emal change modal
  const   toggleModalForPhoneNumberChnage = (toggleValue = false) => {
    updateModalVisibilityForPhoneNumberChange(toggleValue)
  }

  const onSubmitHandlerForEmailChange = (e, isResend = false) => {

    e.preventDefault();
    toast.dismiss();
    const emailAddress = updateField?.email_address

    if (!emailAddress) return toast.error(`Please provide email address`);
    else if (emailAddress && !isValidEmailAddress(emailAddress)) return toast.error(`Please provide valid email address`);


    fetch(`${baseURL}/generate-otp-for-email-change`, {
      ...fetchConfig,
      method: `POST`,
      body: JSON.stringify({
        new_email_address: emailAddress,
      })
    })
      .then(handleFetchErrors)
      .then((generatedOTP) => {

        if (generatedOTP && generatedOTP.status === 200) {
          updateIsMobileNumberExist(false)
          updateIsEmailAddressExist(true)
          updateModalVisibilityForEmailChange(false)
          updateModalIsOpenForVerifyEmail(true)
          return toast.success(`Otp send successfully on ${email_address} `);
        } else {
          toast.error(generatedOTP?.message);
        }

      })
      .catch((error) => {
        toast.error(`Email already exists`);
      })

  }

  const onSubmitHandlerForPhoneNumberChange = (e, isResend = false) => {

    e.preventDefault();
    const phoneNumber = updateField?.phone_number;
    // alert(updateField.country_code)


    if (!phoneNumber) toast.error(`Please provide valid mobile number`);
    else if (phoneNumber && isNaN(phoneNumber)) toast.error(`Mobile number must be number`);

    fetch(`${baseURL}/generate-otp-for-mobile-email-change`, {
      ...fetchConfig,
      method: `POST`,
      body: JSON.stringify({
        phone_number: phoneNumber,
        source: 1,
      })
    })
      .then(handleFetchErrors)
      .then((generatedOTP) => {
        console.log(generatedOTP)
        if (generatedOTP && generatedOTP.status === 200) {

          // updateIsMobileNumberExist(true)
          updateIsEmailAddressExist(false)
          updateModalVisibilityForPhoneNumberChange(false);
          updatePhoneNumber(updateField.country_code,phoneNumber,1);
          // updateModalVisibilityForVerifyOTP(true)

          

          if (isResend) {
            toast.success(`Otp send successfully`)
          }

        } else {
          toast.error(generatedOTP?.message);
        }

      })
      .catch(standardFetchHandlers.error);

  }

  useEffect(()=>{
    const getCountryCodes=async()=>{
      await axios.get(`${baseURL}/country_code_lists`)
      .then((res)=>{
        console.log("res codes",res?.data?.countryCode);
        setcountryCodes(res?.data?.countryCode);
      })
      .catch((err)=>{
        console.log("err",err)
      })
    }
    getCountryCodes();
  },[])

  const updatePhoneNumber=async(country_code,phoneNumber,source)=>{
    setloader(true);
    fetch(`${baseURL}/verify-otp-for-email-mobile-change`, {
      ...fetchConfig,
      method: `POST`,
      body: JSON.stringify({
        country_code: country_code,
        phone_number:phoneNumber,
        source
      })
    })
    .then((res)=>{console.log('phoneNumber Updated Success');
      Swal.fire({
        title: 'Success!',
        text: 'Phone number changed succesfully',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(()=>{
        setloader(false);
        window.location.reload()})
      })
    .catch((err)=>{console.log('error while updating number');setloader(false)})

  }


  const onSubmitHanderForEmailUpdate = (event, otp) => {
    event.preventDefault();

    toggleShowLoaderForEmailChange(true)
    fetch(`${baseURL}/update_email_or_password`, {
      ...fetchConfig,
      method: `POST`,
      body: JSON.stringify({
        "email_address": updateField.email_address,
        "otp": otp.otp_for_email,
        "source": 2
      })
    })
      .then(handleFetchErrors)
      .then((generatedOTP) => {
        toggleShowLoaderForEmailChange(false)
        if (generatedOTP && generatedOTP.status === 200) {
          fetchingSellerAccountDetail();
          updateModalVisibilityForVerifyOTP(false)
        } else {
          toast.error(generatedOTP?.message);
        }

      })
      .catch(standardFetchHandlers.error)
      .finally(() => {
        setTimeout(() => {
          toggleShowLoaderForEmailChange(false);
        }, 1000);
      });

  }


  console.log(`updateField`, updateField)


  const onSubmitHanderForPhoneNumberUpdate = (event, otp) => {

    event.preventDefault();

    fetch(`${baseURL}/verify-otp-for-email-mobile-change`, {
      ...fetchConfig,
      method: `POST`,
      body: JSON.stringify({
        "source": 1,
        "phone_number_otp": otp.otp_for_mobile_number,
        "country_code": updateField.country_code,
        "phone_number": updateField.phone_number
      })
    })
      .then(handleFetchErrors)
      .then((generatedOTP) => {

        if (generatedOTP && generatedOTP.status === 200) {
          fetchingSellerAccountDetail();
          updateModalVisibilityForVerifyOTP(false)
        } else {
          toast.error(generatedOTP?.message);
        }

      })
      .catch(standardFetchHandlers.error);

  }

  const openInputFieldToUploadInHandPicture = () => {
    toggleIsProfileFieldChange(false)

    let input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (e) => {

      const fileDataObj = e.target.files[0];

      const dd = URL.createObjectURL(fileDataObj);

      updateImageUrl(dd)

      updateimageUpload(fileDataObj)

    }
    input.click();
  };

  const deletePhone=async()=>{
    const token=localStorage.getItem('token');
    setloader(true)
    await axios.patch(`${baseURL}/update-profile`,{
      phone_number:"null"
    },{
      headers:{
        "Authorization":`Bearer ${token}`
      }
    })
    .then((res)=>{console.log("res",res);
      Swal.fire({
        title: 'Success!',
        text: 'Phone number deleted succesfully',
        icon: 'success',
        confirmButtonText: 'OK'
      }).then(()=>{
        setloader(false);
        window.location.reload()})
    })
    .catch((err)=>{console.log("err",err);setloader(false)})
  }


  const removingImageURLHandler = () => {
    updateImageUrl(``)
    updateimageUpload({})
    toggleIsProfileFieldChange(false)
    setDeleteProfile(true)
  }

  const handleDateChange = (date) => {
    setStartDate(date)
    toggleIsProfileFieldChange(false)
    setIsFormDataModified(true);
  }



  useEffect(() => {

    var x = localStorage.getItem("token");

    if (!x) navigate("/")

    const source = localStorage.getItem(`source`);
    if (source) updateLoginSource(true)
    else updateLoginSource(false)
  }, [])

  useMemo(() => {
    if (userName && userName.length) {

      const nameArr = userName.split(" ");

      updateNameInitial({
        firstName: nameArr[0][0],
        lastName: nameArr?.[1]?.[0] ? nameArr[1][0] : ``
      })

    }

  }, [userDetail]);


  // useEffect(saveUserToState, []);
  useEffect(fetchingSellerAccountDetail, []);

  useEffect(fetchingSellerAccountDetail, [updated]);

  useEffect(() => {
    clearSuggestions();

  }, [userDetail]);

  const colorList = {
    A: "#00FFFF",
    B: "#0000FF",
    C: "#FF8C00",
    D: "#FFD700",
    E: "#008000",
    F: "#FF00FF",
    G: "#DDA0DD",
    H: "#FF69B4",
    I: "#4B0082",
    J: "#00A86B",
    K: "#F0E68C",
    L: "#E6E6FA",
    M: "#000080",
    N: "#E0115F",
    O: "#808000",
    P: "#800080",
    Q: "#E0D0C6",
    R: "#FF0000",
    S: "#C0C0C0",
    T: "#008080",
    U: "#808000",
    V: "#D2691E",
    W: "#EE82EE",
    X: "#738678",
    Y: "#FFFF00",
    Z: "#000000",
  };


  const handleModalForEmailChangeInfo = () => {
    toggleIsModalOpenForEmailChangeInfo(!isModalOpenForEmailChangeInfo)
  }
  
  // const goToBack = () => {
  //   if (isFormDataModified) {
  //     const confirmation = window.confirm('Do you want to save your changes?');
  //     if (confirmation) {
  //       onSubmitHandler();
  //     } else {
  //       window.location.href = "/";
  //     }
  //   } else {
  //     window.location.href = "/";
  //   }
  // };


  // const goToBack = (event) => {
  //   event.preventDefault();
  //   const confirmation = window.confirm('Do you want to save your changes?');
  //   if (confirmation) {
  //     onSubmitHandler(null, 1);

  //   } else {
  //     window.location.href = "/";
  //   }
  // };   

  const goToBack = (event) => {
    event.preventDefault();
    if (isFormDataModifieds || imgaeUrl || deleteProfile) {
      const confirmation = window.confirm('Do you want to save your changes?');
      if (confirmation) {
        onSubmitHandler(null, 1);
      } else {
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  };
  



  useEffect(() => {
    const goToBackCLICK = (event) => {
      goToBack(event);
    };

    const aTags = document.querySelectorAll('a');
    aTags.forEach((aTag) => {
      aTag.addEventListener('click', goToBackCLICK);
    });

    const linkTags = document.querySelectorAll('a.react-router-link');
    linkTags.forEach((linkTag) => {
      linkTag.addEventListener('click', goToBackCLICK);
    });

    return () => {
      aTags.forEach((aTag) => {
        aTag.removeEventListener('click', goToBackCLICK);
      });

      linkTags.forEach((linkTag) => {
        linkTag.removeEventListener('click', goToBackCLICK);
      });
    };
    
  }, [userDetail,updateField,address,startDate, nameInitial,deleteProfile,imgaeUrl]);





  const updated_new_email= localStorage.getItem('updated_email');

  // useEffect(() => {
  //   const backgroundColor = colorList[nameInitial?.firstName ? nameInitial?.firstName[0]?.toUpperCase() : userName[0]?.toUpperCase()];
  //   localStorage.setItem('backgroundColor', backgroundColor);
    
  //   // const initial = nameInitial && nameInitial?.firstName && nameInitial?.lastName ? `${nameInitial?.firstName}${nameInitial?.lastName}` : userName[0];
  //   const initial = nameInitial && nameInitial?.firstName ? `${nameInitial?.firstName}` : userName[0];
  //   localStorage.setItem('initial', initial);
  // }, [name]);
  

  return (
    <>
    {loader && <div className={style.loader_overlay}>
        <CircularProgress/>  
      </div>
}      <CommonModalWrapper isOpen={modalIsOpenForEmailChange} className={style.modal_wrapper}  >
        <CloseIcon onClick={() => toggleModalForEmailChange(false)} />
        <form onSubmit={onSubmitHandlerForEmailChange} id={style.update_email_form}>

          <input type="text" placeholder='Enter your email address' name='email_address' value={updateField.email_address} onChange={onChangeHandlerForUpdate} />

          {
            showLoaderForEmailChange ? <LoaderButton /> : <button style={{ zIndex: 1000 }} className='btn1' type='submit' >Submit</button>
          }

        </form>
      </CommonModalWrapper>

      <CommonModalWrapper isOpen={modalIsOpenForPhoneNumberChange} className={style.change_phone_number_modal} >
        <CloseIcon onClick={() => toggleModalForPhoneNumberChnage(false)} />
        <form style={{ marginTop: "25px" }} onSubmit={onSubmitHandlerForPhoneNumberChange} id={style.update_email_form}>

          <div>
            <span id='label'>Phone number</span>
            <div className={style.change_number_section_wrapper}>

              <section>
                <Inputs
                  inputId={style.currency_input_field}
                  type='select'
                  name='country_code'
                  value={updateField.country_code}
                  onChangeHandler={onChangeHandlerForUpdate}
                  selectInputOptions={countryCodes}
                />
              </section>
              <section>

                <Inputs
                  inputId={style.phone_number_input_field}
                  name='phone_number'
                  value={updateField.phone_number}
                  onChangeHandler={onChangeHandlerForUpdate}
                  type='number'
                  id='span-2'

                  placeholder='Enter phone number'
                />

              </section>

            </div>
          </div>

          {/* <input type="numbber" placeholder='Enter your phone number' name='phone_number' value={updateField.phone_number} onChange={onChangeHandlerForUpdate} /> */}

          <button style={{ zIndex: 100 }} className='btn1' type='submit'>Submit</button>

        </form>
      </CommonModalWrapper>



      <CommonModalWrapper isOpen={modalIsOpenForVerifyOTP} className={style.verify_modal_wrapper} >
        <CloseIcon onClick={() => toggleModalForVerifyOTP(false)} />
        <VerifyOTPCard isEmailOTP={isEmailAddressExist} onSubmitHandler={!isEmailAddressExist ? onSubmitHanderForPhoneNumberUpdate : onSubmitHanderForEmailUpdate} isMobileOTP={isMobileNumberExist} reGenerateOTPForPhoneNumber={onSubmitHandlerForPhoneNumberChange} />
      </CommonModalWrapper>


      <CommonModalWrapper isOpen={modalIsOpenForVerifyEmail} className={style.verify_modal_wrapper} >
        <CloseIcon onClick={() => updateModalIsOpenForVerifyEmail(false)} />
        <VerifyOTPForEmail updateModalIsOpenForVerifyEmail={updateModalIsOpenForVerifyEmail} fetchingSellerAccountDetail={fetchingSellerAccountDetail} emailAddress={updateField?.email_address} handleModalForEmailChangeInfo={handleModalForEmailChangeInfo} />
      </CommonModalWrapper>

      <CommonModalWrapper isOpen={isModalOpenForEmailChangeInfo} className={style.verify_modal_wrapper} >
        <div className={style.email_change_info_section}>
          <span style={{textAlign:"justify"}}>Email Verification link has been sent to your new email : {updated_new_email}</span>
          <br />
          <br />
          <p style={{textAlign:"justify"}}>Please log in to your email and verify your account by clicking the verification link from your email</p>
          <p>The link expires in next 4 hours</p>
          <button onClick={handleModalForEmailChangeInfo} className={`${style.submitBtn} btn1`} type='submit'>OK</button>
        </div>
      </CommonModalWrapper>


      {/* <Header search={false} /> */}

      {
        imgaeUrl ? <section style={{
          backgroundImage: `url(${imgaeUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100px',
          height: '140px',
          maxWidth: '140px',
          position: 'relative'
        }} className={style.profile_image_section}>
          <svg onClick={openInputFieldToUploadInHandPicture} className={style.camera_icon} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#f29906" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><g transform="translate(2 3)"><path d="M20 16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3l2-3h6l2 3h3a2 2 0 0 1 2 2v11z" /><circle cx="10" cy="10" r="4" /></g></svg>
          {
            imgaeUrl ?
              <span onClick={removingImageURLHandler} className={`${style.cross_icon}`} >Delete profile image</span> : ``
          }

        </section>

          : <section style={{ backgroundColor: colorList[nameInitial?.firstName ? nameInitial?.firstName[0]?.toUpperCase() : userName[0]?.toUpperCase()] }} className={style.profile_image_section}>
            <p className={style.name_initial}>
              {/* {nameInitial && nameInitial?.firstName && nameInitial?.lastName ? `${nameInitial?.firstName}${nameInitial?.lastName}` : userName[0]} */}
              {nameInitial && nameInitial?.firstName  ? `${nameInitial?.firstName}` : userName[0]}
            </p>
            <svg onClick={openInputFieldToUploadInHandPicture} className={style.camera_icon} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#f29906" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><g transform="translate(2 3)"><path d="M20 16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V5c0-1.1.9-2 2-2h3l2-3h6l2 3h3a2 2 0 0 1 2 2v11z" /><circle cx="10" cy="10" r="4" /></g></svg>

            {
              imgaeUrl ?
                <span onClick={removingImageURLHandler} className={`${style.cross_icon}`} >Delete profile image</span> : ``
            }

          </section>

      }
      
<div  className="arrow_back" style={{position:"absolute"}} onClick={goToBack}>
<i class="fa-solid fa-arrow-left"></i>
</div>

      <p id={!imgaeUrl && style.join_since_text} className={style.joined_text}><span>Joined since</span> {created_at ? new Date(created_at).toLocaleDateString('en-us', { month: "short", year: "numeric", }) : ``}</p>


      <form ref={formRef} onSubmit={(e) => onSubmitHandler(e, 1)} className={style.profile_section_wrapper}>

        <div className={style.public_info_section_wrapper}>
          <span className={style.public_info_heading} >Public Info</span>

          <aside className={style.public_info_section}>
            <section id="span-2" className={style.input_Wrapper}>
              <label htmlFor="name">Full Name</label>

              <input onChange={onChangeHandler} value={name} type="text" name='name' className='name' id='name' placeholder="John" />
            </section>

            <section id="span-2" className={style.input_Wrapper}>
              <label htmlFor="name">Short Bio</label>
              <textarea onChange={onChangeHandler} name="short_bio" value={short_bio} id="short_bio" rows="5" placeholder='Enter short bio'>
                {short_bio}
              </textarea>

            </section>
          </aside>

        </div>


        <div className={style.private_info_section_wrapper}>
          <span className={style.private_info_heading}>Private Info</span>
          <aside className={style.private_info_section}>

            <section className={style.input_Wrapper}>
              <label htmlFor="name">Email Address</label>

              <input id={style.gray_background} onChange={onChangeHandler} type="email" disabled={isEmailAdressInputDisable} value={email_address} name='email' className='email' placeholder="john@gmail.com" />
              {JSON.parse(localStorage.getItem(`user`))?.source == "email" ? <span onClick={() => { toggleModalForEmailChange(true) }} className={style.change_email_address_text}>{email_address ? `Change Email` : `Add Email`}</span> : ``}

            </section>





            <section className={style.input_Wrapper}>
              <label htmlFor="phone_number">Phone Number</label>

              <input disabled={isPhoneNumberInputDisable} onChange={onChangeHandler} value={phone_number} type="text" name='phone_number' className='phone_number' id='phone_number' placeholder="Provide your phone number" />
              <div className={style.change_delete_mobile_section}>
                <span onClick={() => { toggleModalForPhoneNumberChnage(true) }} className={style.change_phone_number_text}>{phone_number ? `Change Phone` : `Add Phone`}</span>
                {phone_number ? <span onClick={(e) => deletePhone(e)} className={style.delete_mobile_number_text} >Delete</span> : ``}

              </div>
            </section>


            <section id={JSON.parse(localStorage.getItem(`user`))?.source == "email" ? style.dob_input_wrapper : style.dob_input_wrapper1} className={style.input_Wrapper}>
              <label className={style.date_of_birth_label} htmlFor="date_of_birth">Date of Birth</label>
              <DatePicker maxDate={new Date()} peekNextMonth
                showMonthDropdown
                showYearDropdown dateFormat="MM/dd/yyyy" selected={startDate} onChange={(date) => { handleDateChange(date) }} />

            </section>

            <section id={JSON.parse(localStorage.getItem(`user`))?.source == "email" ? style.gender_input_wrapper1 : style.gender_input_wrapper} className={style.radio_button} >
              <p>Please select your gender:</p>
              <div className={style.radio_button_list}>


                <input onChange={onChangeHandler} type="radio" id="male" name="gender" value="1" checked={Number(gender) == 1} />
                <label for="male">Male</label>


                <input onChange={onChangeHandler} type="radio" id="female" name="gender" value="2" checked={Number(gender) == 2} />
                <label for="female">Female</label>
                <input onChange={onChangeHandler} type="radio" id="other" name="gender" value="3" checked={Number(gender) == 3} />
                <label for="other">Other</label>
              </div>

            </section>



            <section id={style.location_input_wrapper} className={style.input_Wrapper}>
              <label htmlFor="name">location</label>

              <div ref={ref}>
                <input
                  value={value}
                  onChange={handleInput}
                  disabled={!ready}
                  placeholder="Enter your location"
                />
                {/* We can use the "status" to decide whether we should display the dropdown or not */}
                {status === "OK" && locationReponseData && <ul>{
                  data.map((suggestion) => {
                    const {
                      place_id,
                      structured_formatting: { main_text, secondary_text },
                    } = suggestion;

                    return (
                      <li key={place_id} onClick={handleSelect(suggestion)}>
                        <strong>{main_text}</strong> <small>{secondary_text}</small>
                      </li>
                    );
                  })
                }</ul>}
              </div>

            </section>


          </aside>

        </div>

        {
          showLoader ?
            <LoaderButton />
            :
            <section className={style.submit_btn} >
              <button disabled={isProfileFieldChange} className='btn1' type='submit'>Update</button>
            </section>
        }

      </form>




      {/* <div className=" footer">
        <div className="footer-wraper">
          <img src="/images/menehariya_logo.svg" alt="logo" />
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc viverra efficitur convallis. Mauris non hendrerit nulla.</p>
        </div>
        <Footer_menu title="QUICK LINKS" text1="Babysitters and Nannies" text2="Room/Houses for Rent" text3="Jobs" text4="Events" />
        <Footer_menu title="MENEHARIYA" text1="Contact Us" text2="Blogs" text3="Term & Condition" text4="Privacy Policy" />
        <Footer_menu title="TRENDING SEARCH" text1="Lorem Ipsum" text2="Lorem Ipsum" text3="Lorem Ipsum" text4="Lorem Ipsum" />
        <div className="media-heading">
          <h6>SOCIAL MEDIA</h6>
          <p className='footer_icon'><svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18.75" cy="18.75" r="18.75" fill="#424242" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.6667 14.3333V11.8529C20.6667 10.7331 20.924 10.1667 22.7318 10.1667H25V6H21.2151C16.5771 6 15.0469 8.04427 15.0469 11.5534V14.3333H12V18.5H15.0469V31H20.6667V18.5H24.4854L25 14.3333H20.6667V14.3333Z" fill="white" />
          </svg>

            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18.75" cy="18.75" r="18.75" fill="#424242" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M29 12.8958C28.2626 13.2167 27.4743 13.4333 26.6436 13.5333C27.4912 13.0333 28.1439 12.2417 28.449 11.3C27.6565 11.7625 26.7792 12.1 25.8426 12.2792C25.0924 11.4917 24.0244 11 22.8462 11C20.5787 11 18.7436 12.8083 18.7436 15.0375C18.7436 15.3542 18.7775 15.6625 18.8495 15.9583C15.4378 15.7917 12.4117 14.1833 10.3901 11.7375C10.0384 12.3333 9.83492 13.0292 9.83492 13.7667C9.83492 15.1667 10.5639 16.4042 11.6658 17.1292C10.9877 17.1125 10.352 16.9292 9.80102 16.625C9.80102 16.6417 9.80102 16.6583 9.80102 16.675C9.80102 18.6333 11.2166 20.2625 13.0941 20.6333C12.7508 20.725 12.3863 20.775 12.0134 20.775C11.7506 20.775 11.4921 20.75 11.242 20.7C11.7633 22.3042 13.2806 23.4708 15.0776 23.5042C13.6747 24.5875 11.9032 25.2333 9.97902 25.2333C9.64844 25.2333 9.3221 25.2125 9 25.175C10.8097 26.3333 12.9669 27 15.281 27C22.8377 27 26.9657 20.8458 26.9657 15.5083C26.9657 15.3333 26.9614 15.1583 26.953 14.9875C27.754 14.4167 28.449 13.7083 29 12.8958V12.8958Z" fill="white" />
            </svg>

            <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="19.25" cy="18.75" r="18.75" fill="#424242" />
              <circle cx="19.25" cy="19" r="3.5" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1274 15.338C17.034 14.4314 18.2393 13.9268 19.5214 13.9268C20.8035 13.9268 22.0089 14.4341 22.9155 15.3407C23.4907 15.9159 23.9017 16.6071 24.1254 17.3786H27.7286V12.5357C27.7286 11.3996 26.8504 10.5214 25.7143 10.5214H13.3714C12.2353 10.5214 11.2714 11.3996 11.2714 12.5357V17.3786H14.9174C15.1412 16.6071 15.5521 15.9132 16.1274 15.338ZM26.3572 14.0868C26.3572 14.3898 26.1116 14.6354 25.8086 14.6354H24.1629C23.8599 14.6354 23.6143 14.3898 23.6143 14.0868V12.4411C23.6143 12.1381 23.8599 11.8925 24.1629 11.8925H25.8086C26.1116 11.8925 26.3572 12.1381 26.3572 12.4411V14.0868Z" fill="white" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9155 22.1262C22.0089 23.0328 20.8035 23.5268 19.5214 23.5268C18.2393 23.5268 17.034 23.0355 16.1274 22.1289C15.2224 21.224 14.7234 19.9929 14.7215 18.75H11.2714V24.8786C11.2714 26.0147 12.2353 26.9786 13.3714 26.9786H25.7143C26.8504 26.9786 27.7286 26.0147 27.7286 24.8786V18.75H24.3213C24.3195 19.9929 23.8205 21.2213 22.9155 22.1262V22.1262Z" fill="white" />
            </svg>

          </p>
          <div className='footer_language'>
            <select className="selectpicker" data-width="fit">
              <option data-content='<span className="flag-icon flag-icon-us"></span> English'>English
              </option>
              <option data-content='<span className="flag-icon flag-icon-mx"></span> Español'>Español
              </option>
            </select>
          </div>
        </div>
      </div> */}

    </>

  )

}
