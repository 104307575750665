import React from "react";
import { useEffect, useState } from "react";
import "../../assest/index.css";
import Card from "../../component/card";
import Jobcard from "../../component/jobcard";
import Babysittercard from "../../component/babysitter";
import Eventlistcard from "../../component/Eventlistcard";
import Buysellcard from "../../component/Buysellcard";
import Button from "../../component/button";
import LocalBizcard from "../../component/LocalBizServices";
import AdsOnCard from "../../component/AdsOnCard";
import UrgentCard from "../../component/UrgentCard";
import Footer_menu from "../../component/footer_menu";
import Header from "../../component/header/Header";
import getFetchConfig from "../../utils/getFetchConfig";
import appURLs from "../../appURL";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link,json,useNavigate } from "react-router-dom";
import SimpleImageSlider from "react-simple-image-slider";
import Banner from "../../component/Banner/Banner";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import getSymbolFromCurrency from 'currency-symbol-map'
import socialMediaLink from "../../component/socialMediaLink/socialMediaLink";

import moment from 'moment-timezone';
import 'moment-duration-format';




export default function Home({ adsId = "" , latitudes, longitudes, setCount}) {
  const navigate = useNavigate();
  const fetchConfig = getFetchConfig(),
    appURL = appURLs();

const [roomRentData , setRoomRentData] = useState([]);
const [myJobsData, setMyJobData] = useState([]);
const [eventsData, setEventData] = useState([]);
const [babySitterData, setBabySitterData] = useState([]);
const [buySellData, setBuySellData] = useState([]);
const [localBizData, setLocalBizData] = useState([]);
const[filterlocations,sefilterlectLocation] =useState();

const [rentfeatureStatus , setRentFeaturedStatus] = useState(false);
const [jobFeaturedStatus , setJobFeaturedStatus] = useState(false);
const [eventFeaturedStatus, setEventFeaturedStatus] = useState(false);
const [buySellFeaturedStatus, setBuySellFeaturedStatus] = useState(false);
const [bizFeaturedStatus, setBizFeaturedStatus] = useState(false);
const [babyFeaturedStatus, setBabyFeaturedStatus] = useState(false);

const [recommendData, setRecommendedData] = useState([]);
const [loader,setloader]=useState(false);

// aDS oN

const [adsOnData, setAdsOnData] = useState([]);// Home page gallery
const [urgentData, setUrgentData] = useState([]);
const[upcomingEventData, setUpcomingEventData] = useState([]);
const[priceDropData, setPriceDropData] = useState([]);

const [notificationCount, setNotificationCount] = useState(0);

console.log(notificationCount, "6notificationCount")

console.log(latitudes, "latitudes")

 console.log(adsOnData,"adsondata")

const [imageArray, setImageArray] = useState([]); 
const [bannerData, setBannerData] = useState([]);

const [lat , setLat] = useState(null);
  const [lang , setLang] = useState(null);


  console.log(lat, "9876545678987654567876")
  console.log(lang, "9876545678987654567876")



const latitude = localStorage.getItem("latitude");
const longitude = localStorage.getItem("longitude");


const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 4, // This sets how many slides should be scrolled on each navigation
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const geetingDataLTLNG=(lat,lang)=>{
  setLang(lat);
    setLat(lang)
}

  // ****************** Rooms Rents Data ****************************/
  const handleRedirect = () => {
    localStorage.setItem('selectedSubCategory', JSON.stringify(null));
     // Set selectedSubCategory to null
     localStorage.setItem('hoveredCategory', JSON.stringify(null))
     localStorage.removeItem('rental_isFavorite')
    navigate('/view_for_rent'); // Redirect to the desired page
  };

  const handleRedirectJobs = () => {
    localStorage.setItem('selectedSubCategory', JSON.stringify(null)); // Set selectedSubCategory to null
    localStorage.setItem('hoveredCategory', JSON.stringify(null))
    localStorage.removeItem('job_isFavorite')
    navigate('/view_for_job'); // Redirect to the desired page
  };
  const handleRedirectLocalBiz = () => {
    localStorage.setItem('selectedSubCategory', JSON.stringify(null)); // Set selectedSubCategory to null
    localStorage.setItem('hoveredCategory', JSON.stringify(null))
    localStorage.removeItem('localBiz_isFavorite')
    navigate('/view_for_localBiz_and_Services'); // Redirect to the desired page
  };
  const handleRedirectEvents = () => {
    localStorage.setItem('selectedSubCategory', JSON.stringify(null)); // Set selectedSubCategory to null
         localStorage.setItem('hoveredCategory', JSON.stringify(null))
         localStorage.removeItem('event_isFavorite')
    navigate('/view_for_events'); // Redirect to the desired page
  };
  const handleRedirectBuySell = () => {
    localStorage.setItem('selectedSubCategory', JSON.stringify(null)); // Set selectedSubCategory to null
    localStorage.setItem('hoveredCategory', JSON.stringify(null));
    localStorage.removeItem('buySell_isFavorite')
    navigate('/view_for_Buy_and_Sell'); // Redirect to the desired page
  };
  const handleRedirectBabySitter = () => {
    localStorage.setItem('selectedSubCategory', JSON.stringify(null)); // Set selectedSubCategory to null
         localStorage.setItem('hoveredCategory', JSON.stringify(null))
         localStorage.removeItem('babysitter_isFavorite')
    navigate('/view_for_Babysitter_and_nannies'); // Redirect to the desired page
  };
  
  const myPage = 20

  const fetchRoomRentData = async () => {
    try {
      // setloader(true);
      const token = localStorage.getItem("token");
     const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };
  
      let apiUrl = `${appURL}/posts/room-rents/fetchAds`;
  
      // Check if lat and lang are defined (not null or undefined)
      if (latitudes !== null && longitudes !== null) {
        apiUrl += `?longitude=${longitudes}&latitude=${latitudes}&maxDistance=100`;
      }

      if (latitudes === null && longitudes === null) {
        apiUrl += `?longitude=null&latitude=null&maxDistance=100`;
      }
  
      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const jsonData = await response.json();
      
    
      const mergedData = [
        // ...jsonData.AdOnsData.featuredData,
        // ...jsonData.AdOnsData.bumpupData,
        ...jsonData.records,
      ];
      
      const roomRentDataWithCleanedDescriptions = mergedData.slice(0, 21).map((record) => ({
        ...record,
        adsInfo: {
          ...record.adsInfo,
          descriptions: record.adsInfo.descriptions.replace(/<[^>]+>/g, ""), // Remove HTML tags
        },
      }));


      const featuredStatus = jsonData.AdOnsData.featuredData.map((record) => {
        const isFeatured =
          record.addons_validity &&
          record.addons_validity.some(
            (addon) => addon.name === "Featured"
          );
      
        return isFeatured;
      });

      console.log(featuredStatus)

      setRentFeaturedStatus(featuredStatus);
      setRoomRentData(roomRentDataWithCleanedDescriptions);
      // setloader(false);
      console.log(jsonData, "Rooms rent data 23334");
    } catch (error) {
      // setloader(false);
      console.error("Error fetching data:", error);
    }
  };
  

  //****************** Jobs Data *************************** */

  const fetchJobsData = async () => {
    try {
      const token = localStorage.getItem("token");
     const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      let apiUrl = `${appURL}/posts/jobs/fetchAds`;
  
      // Check if lat and lang are defined (not null or undefined)
      if (longitudes !== null && latitudes !== null) {
        apiUrl += `?longitude=${longitudes}&latitude=${latitudes}&maxDistance=100`;
      }

      if (latitudes === null && longitudes === null) {
        apiUrl += `?longitude=null&latitude=null&maxDistance=100`;
      }

      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();

      const mergedData = [
        // ...jsonData.AdOnsData.featuredData,
        // ...jsonData.AdOnsData.bumpupData,
        ...jsonData.records,
      ];

      const featuredStatus = jsonData.AdOnsData.featuredData.map((record) => {
        const isFeatured =
          record.addons_validity &&
          record.addons_validity.some(
            (addon) => addon.name === "Featured"
          );
      
        return isFeatured;
      });

      console.log(featuredStatus)

      setJobFeaturedStatus(featuredStatus);

      setMyJobData(mergedData.slice(0, 21))
      // setData(jsonData);
      console.log(jsonData, "Jobs data");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //****************** Events Data *************************** */

  const fetchEventsData = async () => {
    try {
      const token = localStorage.getItem("token");
     const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      let apiUrl = `${appURL}/posts/events/fetchAds`;
  
      
      if (latitudes !== null && longitudes !== null) {
        apiUrl += `?longitude=${longitudes}&latitude=${latitudes}&maxDistance=100`;
      }

      if (latitudes === null && longitudes === null) {
        apiUrl += `?longitude=null&latitude=null&maxDistance=100`;
      }

      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();

      const mergedData = [
        // ...jsonData.AdOnsData.featuredData,
        ...jsonData.records,
      ];

      const featuredStatus = jsonData.AdOnsData.featuredData.map((record) => {
        const isFeatured =
          record.addons_validity &&
          record.addons_validity.some(
            (addon) => addon.name === "Featured"
          );
      
        return isFeatured;
      });

      console.log(featuredStatus)

      setEventFeaturedStatus(featuredStatus);

      setEventData(mergedData.slice(0, 21));
      // setData(jsonData);
      console.log(jsonData, "Events data");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  //****************** BabySitter Data *************************** */

  const fetchBabySitterData = async () => {
    try {
      const token = localStorage.getItem("token");
     const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      let apiUrl = `${appURL}/posts/babysitter/fetchAds`;
  
      
      if (latitudes !== null && longitudes !== null) {
        apiUrl += `?longitude=${longitudes}&latitude=${latitudes}&maxDistance=100`;
      }

      if (latitudes === null && longitudes === null) {
        apiUrl += `?longitude=null&latitude=null&maxDistance=100`;
      }
      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();

      const mergedData = [
        // ...jsonData.AdOnsData.featuredData,
        // ...jsonData.AdOnsData.bumpupData,
        ...jsonData.records,
      ];

      const featuredStatus = jsonData.AdOnsData.featuredData.map((record) => {
        const isFeatured =
          record.addons_validity &&
          record.addons_validity.some(
            (addon) => addon.name === "Featured"
          );
      
        return isFeatured;
      });

      console.log(featuredStatus)

      setBabyFeaturedStatus(featuredStatus);

      setBabySitterData(mergedData.slice(0, 21));
      // setData(jsonData);
      console.log(jsonData, "BabySitter data");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //****************** Buy & Sell Data *************************** */

  const fetchBuySellData = async () => {
    try {
      const token = localStorage.getItem("token");
     const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      let apiUrl = `${appURL}/posts/buysell/fetchAds`;
  
      if (latitudes !== null && longitudes !== null) {
        apiUrl += `?longitude=${longitudes}&latitude=${latitudes}&maxDistance=100`;
      }

      if (latitudes === null && longitudes === null) {
        apiUrl += `?longitude=null&latitude=null&maxDistance=100`;
      }

      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      const mergedData = [
        // ...jsonData.AdOnsData.featuredData,
     // ...jsonData.AdOnsData.bumpupData,
        ...jsonData.records,
      ];

      const featuredStatus = jsonData.AdOnsData.featuredData.map((record) => {
        const isFeatured =
          record.addons_validity &&
          record.addons_validity.some(
            (addon) => addon.name === "Featured"
          );
      
        return isFeatured;
      });

      console.log(featuredStatus)

     setBuySellFeaturedStatus(featuredStatus)

      setBuySellData(mergedData.slice(0, 21));
      // setData(jsonData);
      console.log(jsonData, "BuySell data");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //****************** Local Biz Data *************************** */

  const fetchLocalBizData = async () => {
    try {
      const token = localStorage.getItem("token");
     const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      let apiUrl = `${appURL}/posts/biz/fetchAds`;
  
      if (latitudes !== null && longitudes !== null) {
        apiUrl += `?longitude=${longitudes}&latitude=${latitudes}&maxDistance=100`;
      }

      if (latitudes === null && longitudes === null) {
        apiUrl += `?longitude=null&latitude=null&maxDistance=100`;
      }


      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();
      const mergedData = [
        // ...jsonData.AdOnsData.featuredData,
        // ...jsonData.AdOnsData.bumpupData,
        ...jsonData.records,
      ];

      const featuredStatus = jsonData.AdOnsData.featuredData.map((record) => {
        const isFeatured =
          record.addons_validity &&
          record.addons_validity.some(
            (addon) => addon.name === "Featured"
          );
      
        return isFeatured;
      });

      console.log(featuredStatus)

      setBizFeaturedStatus(featuredStatus)

      setLocalBizData(mergedData.slice(0, 21));
      // setData(jsonData);
      console.log(jsonData, "Local Biz data");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //****************** Recommended data api ****************************/


  const recommendedAds = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        "Content-Type": "application/json",
        ...(token && { Authorization: `Bearer ${token}` }),
      };

      const response = await fetch(
        `${appURL}/posts/ads/recomended-ads?longitude=${longitudes}&latitude=${latitudes}`,
        {
          ...fetchConfig,
          method: "GET",
          headers: headers,
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const jsonData = await response.json();
      setRecommendedData(jsonData.recomended_ads)
  
      console.log(jsonData, "recommended data +++++++++");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };




  //****************** Ads On Validity api ****************************/

  const adsOnValidityData = async () => {
    try {

      let apiUrl = `${appURL}/posts/ads/adons-ads?longitude=${longitudes}&latitude=${latitudes}`;
  
      // if (lat !== null && lang !== null) {
      //   apiUrl += `?longitude=${lang}&latitude=${lat}&maxDistance=100`;
      // }
      
    
      const response = await fetch(apiUrl, {
        ...fetchConfig,
        method: "GET",
    
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const jsonData = await response.json();

      // const images = jsonData.ads_details.adsInfo.image || [];
      // const imageUrls = images.map(img => ({
      //   url: img.url
      // }));
   
      console.log(jsonData, "((((((((((ads On Validity Data }}}}}}}}}}}}}}}}}}}}}");
      console.log(jsonData,"987656789876567876567898765678765");
      console.log(jsonData.banner, "my new banner");
      setNotificationCount(jsonData.unseen_total)
      setCount(jsonData.unseen_total)
      console.log(jsonData.unseen_total, "jsonData.unseen_total")
      localStorage.setItem("unseen_total" , jsonData.unseen_total)
      setBannerData(jsonData.banner)
      setAdsOnData(jsonData.data[0]);
      setUrgentData(jsonData.data[1])
      setUpcomingEventData(jsonData.data[2])
      setPriceDropData(jsonData.data[3])
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //****************** Ads On Validity api ****************************/

  // const getTimeSinceCreation = (createdAt) => {
  //   const createdDate = new Date(createdAt);
  //   const currentDate = new Date();

  //   const timeDiff = currentDate - createdDate;

  //   const secondsDiff = timeDiff / 1000;

  //   if (secondsDiff < 60) {
  //     return "just now";
  //   } else if (secondsDiff < 3600) {
  //     const minutesDiff = Math.floor(secondsDiff / 60);
  //     return minutesDiff === 1 ? "1 minute ago" : `${minutesDiff} minutes ago`;
  //   } else if (secondsDiff < 86400) {
  //     const hoursDiff = Math.floor(secondsDiff / 3600);
  //     return hoursDiff === 1 ? "1 hour ago" : `${hoursDiff} hours ago`;
  //   } else if (secondsDiff < 172800) {
  //     return "Yesterday";
  //   } else {
  //     const daysDiff = Math.floor(secondsDiff / 86400);
  //     return daysDiff === 1 ? "1 day ago" : `${daysDiff} days ago`;
  //   }
  // };


  const currentDate = new Date().toISOString().split('T')[0];

  const currentDates = new Date();
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Use 24-hour format
  };

  const localTimeString = currentDates.toLocaleString('en-US', options);
  const formattedDateTime = `${currentDates.getFullYear()}-${(currentDates.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${currentDates.getDate().toString().padStart(2, '0')} ${currentDates
    .getHours()
    .toString()
    .padStart(2, '0')}:${currentDates.getMinutes().toString().padStart(2, '0')}:${currentDates
    .getSeconds()
    .toString()
    .padStart(2, '0')}`;
  // Get the timezone
    // Format the local time
    

    // Get the timezone
  

    const getTimeSinceCreation = (dateString, mytimers) => {

  // let dateString = rentDetailsData.ads_details.active_on_virtual;

  // Split the string at "T" and concatenate the date and time parts
  let parts = dateString.split("T");
  let extractedDate = parts[0] + "T" + parts[1].split(".")[0];
  
  console.log(extractedDate, "extractedDate");
  const convertToTimeZone = (datetime, fromTimeZone, toTimeZone) => {
    return moment.tz(datetime, fromTimeZone).tz(toTimeZone).format('YYYY-MM-DD HH:mm:ss');
  };

const originalDatetime = extractedDate;
// const originalTimeZone = rentDetailsData.ads_details.location_timezone;
// console.log(originalTimeZone,"sbjsxjxx");

const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
const targetTimeZone = timezoneString;

const convertedDatetime = convertToTimeZone(originalDatetime, mytimers, targetTimeZone);

    
console.log(convertedDatetime,"hhhhgxy",formattedDateTime);





  if (formattedDateTime && convertedDatetime) {
    const secondsDiff = moment.duration(moment(formattedDateTime).diff(convertedDatetime)).asSeconds();


    if (secondsDiff < 60) {
      return "just now";
    } else if (secondsDiff < 3600) {
      const minutesDiff = Math.floor(secondsDiff / 60);
      return minutesDiff === 1 ? "1 minute ago" : `${minutesDiff} minutes ago`;
    } else if (secondsDiff < 86400) {
      const hoursDiff = Math.floor(secondsDiff / 3600);
      return hoursDiff === 1 ? "1 hour ago" : `${hoursDiff} hours ago`;
    } else if (secondsDiff < 172800) {
      return "Yesterday";
    } else {
      const daysDiff = Math.floor(secondsDiff / 86400);
      return daysDiff === 1 ? "1 day ago" : `${daysDiff} days ago`;
    }
  }
  }




  useEffect(() => {
    // fetchRoomRentData();
    fetchJobsData();
    fetchEventsData();
    fetchBabySitterData();
    fetchBuySellData();
    fetchLocalBizData();
    adsOnValidityData();
    recommendedAds();
    console.log("coordds",lat,lang,latitude,longitude);
  },[lat,lang,latitudes, longitudes]);

  useEffect(() => {
    fetchRoomRentData();
   
  },[lat,lang, latitudes, longitudes]);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "grey", borderRadius:"20px", color:"black" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block" ,background: "grey", borderRadius:"20px", color:"black"}}
        onClick={onClick}
      />
    );
  }




  // const titlebtn = (record) => {
  //   if (record.adsType.name === 'Buy & Sell') {
  //     return (
  //       <Link to={`/buySellDetails/${record._id}`} style={{ color: '#F17D3A' }}>
  //         View Details
  //       </Link>
  //     );
  //   } 
  //   else if (record.adsType.name === 'Events') {
  //     return (
  //       <Link to={`/eventDetails/${record._id}`} style={{ color: '#F17D3A' }}>
  //         View Details
  //       </Link>
  //     );
  //   }
  //   else if (record.adsType.name === 'Rentals') {
  //     return (
  //       <Link to={`/rentDetails/${record._id}`} style={{ color: '#F17D3A' }}>
  //         View Details
  //       </Link>
  //     );
  //   }
  //   else if (record.adsType.name === 'Babysitters & Nannies') {
  //     return (
  //       <Link to={`/babySitterDetails/${record._id}`} style={{ color: '#F17D3A' }}>
  //         View Details
  //       </Link>
  //     );
  //   }
  //   else if (record.adsType.name === 'Jobs') {
  //     return (
  //       <Link to={`/jobDetails/${record._id}`} style={{ color: '#F17D3A' }}>
  //         View Details
  //       </Link>
  //     );
  //   }
  //   else if (record.adsType.name === 'Local Biz & Services') {
  //     return (
  //       <Link to={`/localBizDetails/${record._id}`} style={{ color: '#F17D3A' }}>
  //         View Details
  //       </Link>
  //     );
  //   }
  // };

    const titlebtn = (record) => {
      switch (record.adsType.name) {
        case 'Buy & Sell':
          return `/buySellDetails/${record._id}`;
        case 'Events':
          return `/eventDetails/${record._id}`;
        case 'Rentals':
          return `/rentDetails/${record._id}`;
        case 'Babysitters & Nannies':
          return `/babySitterDetails/${record._id}`;
        case 'Jobs':
          return `/jobDetails/${record._id}`;
        case 'Local Biz & Services':
          return `/localBizDetails/${record._id}`;
        default:
          return '/';
      }
    };
  return (
    <>
    { (latitudes === null && longitudes === null) &&
    <div style={{marginTop:'20px'}}>
    <h3 style={{textAlign:'center'}}>Please Select Your Location From Above To Access Your Location Ads</h3>
        <h3 style={{textAlign:'center'}}>የሚፈልጉትን አካባቢ ማስታወቂያ ለማየት ከላይ ያለዉን "Select Location" የሚለዉን በመጫን የሚፈልጉትን አካባቢ ይምረጡ።</h3>
        </div>}
      <div className="Main-wrapper-section">
        {/* <Header search={true}  myBanner={bannerData} getCoordinates={geetingDataLTLNG}  loctiondata ={sefilterlectLocation} 
        notificationCount={notificationCount}
        /> */}

        {/* <Banner myBanner={bannerData} /> */}

        
        {/* <div className="view_project">
          <div className="numberlists">
            <div className="counting-number-lists">
              <h3>15+M</h3>
              <p>Happy User</p>
            </div>
            <div className="counting-number-lists">
              <h3>64+K</h3>
              <p>Verified Experts</p>
            </div>
            <div className="counting-number-lists">
              <h3>98+</h3>
              <p>Categories</p>
            </div>
          </div>
        </div> */}
        <div className="section">


{/* adsOnData */}

{adsOnData && adsOnData.data && adsOnData.data.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">
      {adsOnData.name} - ዋና ዋና ስብስቦች
    </div>
  </div>
)}

{adsOnData && adsOnData.data && adsOnData.data.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    //showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {adsOnData.data.map((record, index) => (
      // <Link to={titlebtn(record)} key={index}>
        <AdsOnCard
        key={index}
        myAdsType= {record.adsType._id}
        myAdsTypeName={record.adsType.name}
        data={record.price_default}
        _id={record._id}
        myFavourite={record.is_favorite}
        title={record.adsInfo.title}
        location={record.adsInfo.location.location_name}
        titleColor=""
        imageSrc={record.adsInfo.image && record.adsInfo.image.map(img => img.url)} 
        // featured={record.is_featured}
        // featured={
        //   record.addons_validity.some(item => item.name === "Featured")
        //     ? true
        //     : false
        // }
        featured={
          record.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        dateType={getTimeSinceCreation(record.active_on_virtual, record.location_timezone)}
        //titlebtn={titlebtn(record)}
        upcomingLabel={
          record.addons_validity.some(addon => addon.name === "Upcoming Event" && addon.active_on <=  currentDate && addon.expired_on >= currentDate)
            ? "Upcoming Event"
            : ""
            }
            urgentLabel={
              record.addons_validity.some(addon => addon.name === "Urgent" && addon.active_on <=  currentDate && addon.expired_on >= currentDate)
                ? "Urgent"
                : ""
            }
      />
      // </Link>
    ))}
  </Carousel>
)}

{/* Recommended Ads */}

{recommendData && recommendData && recommendData?.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">
    Recommended for you -  ለእርስዎ የሚመከር
    </div>
  </div>
)}


{recommendData && recommendData && recommendData?.length > 0? (
  <Carousel
    responsive={responsive}
    ssr
    //showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {recommendData?.map((record, index) => (
      // <Link to={titlebtn(record)} key={index}>
        <AdsOnCard
        key={index}
        myAdsType= {record.adsType._id}
        myAdsTypeName={record.adsType.name}
        data={record.price_default}
        _id={record._id}
        myFavourite={record.isFavorite}
        title={record.adsInfo.title}
        location={record.adsInfo.location.location_name}
        titleColor=""
        imageSrc={record.adsInfo.image && record.adsInfo.image.map(img => img.url)} 
        // featured={record.is_featured}
        // featured={
        //   record.addons_validity.some(item => item.name === "Featured")
        //     ? true
        //     : false
        // }
        featured={
          record.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        dateType={getTimeSinceCreation(record.active_on_virtual, record.location_timezone)}
        //titlebtn={titlebtn(record)}
        upcomingLabel={
          record.addons_validity.some(addon => addon.name === "Upcoming Event" && addon.active_on <=  currentDate && addon.expired_on >= currentDate)
            ? "Upcoming Event"
            : ""
            }
            urgentLabel={
              record.addons_validity.some(addon => addon.name === "Urgent" && addon.active_on <=  currentDate && addon.expired_on >= currentDate)
                ? "Urgent"
                : ""
            }
      />
      // </Link>
    ))}
  </Carousel>
):(loader && (latitudes !== null && longitudes !== null)) && <div className="skeleton-loader">
<div className="skeleton skeleton-header"></div>
<div className="skeleton skeleton-text"></div>
<div className="skeleton skeleton-text"></div>
<div className="skeleton skeleton-text"></div>
</div>} 


{/* Urgent Data */}


{urgentData && urgentData.data && urgentData.data.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">
      {urgentData.name} - አስቸኳይ
    </div>
  </div>
)}

{urgentData && urgentData.data && urgentData.data.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {urgentData.data.map((record, index) => (
      // <Link to={titlebtn(record)} key={index}>
      <AdsOnCard
        key={index}
        _id={record._id}
        myFavourite={record.is_favorite}
        data={record.price_default}
        myAdsType= {record.adsType._id}
        myAdsTypeName={record.adsType.name}
        title={record.adsInfo.title}
        location={record.adsInfo.location.location_name}
        titleColor=""
        imageSrc={record.adsInfo.image && record.adsInfo.image.map(img => img.url)} 
        // featured={record.is_featured}
        featured={
          record.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        urgentLabel={
          record.addons_validity.some(addon => addon.name === "Urgent" && addon.active_on <=  currentDate && addon.expired_on >= currentDate)
            ? "Urgent"
            : ""
        }
        dateType={getTimeSinceCreation(record.active_on_virtual, record.location_timezone)}
        // titlebtn={titlebtn(record)}
      />
    // </Link>
    ))}
  </Carousel>
)}

{upcomingEventData && upcomingEventData.data && upcomingEventData.data.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">
      {upcomingEventData.name} - በቅርቡ የሚመጡ ዝግጅቶች
    </div>
  </div>
)}

{upcomingEventData && upcomingEventData.data && upcomingEventData.data.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {upcomingEventData.data.map((record, index) => (
    //  <Link to={titlebtn(record)} key={index}>
      <AdsOnCard
        key={index}
        _id={record._id}
        myFavourite={record.is_favorite}
        data={record.price_default}
        myAdsType= {record.adsType._id}
        myAdsTypeName={record.adsType.name}
        title={record.adsInfo.title}
        location={record.adsInfo.location.location_name}
        titleColor=""
        imageSrc={record.adsInfo.image && record.adsInfo.image.map(img => img.url)} 
        // featured={record.is_featured}
        featured={
          record.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        dateType={getTimeSinceCreation(record.active_on_virtual, record.location_timezone)}
        // titlebtn={titlebtn(record)}
        upcomingLabel={
          record.addons_validity.some(addon => addon.name === "Upcoming Event" && addon.active_on <=  currentDate && addon.expired_on >= currentDate)
            ? "Upcoming Event"
            : ""
        }
      />
      //  </Link> 
    ))}
  </Carousel>
)}




{/* Price Drop Data */}
{priceDropData && priceDropData.data && priceDropData.data.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">
      {priceDropData.name} - የዋጋ ቅናሽ
    </div>
  </div>
)}

{priceDropData && priceDropData.data && priceDropData.data.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {priceDropData.data.map((record, index) => (
      //  <Link to={titlebtn(record)} key={index}>
      <AdsOnCard
        key={index}
        _id={record._id}
        myFavourite={record.is_favorite}
        data={record.price_default}
        myAdsType= {record.adsType._id}
        myAdsTypeName={record.adsType.name}
        title={record.adsInfo.title}
        location={record.adsInfo.location.location_name}
        titleColor=""
        imageSrc={record.adsInfo.image && record.adsInfo.image.map(img => img.url)} 
        // featured={record.is_featured}
        featured={
          record.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        urgentLabel={
          record.addons_validity.some(addon => addon.name === "Urgent" && addon.active_on <=  currentDate && addon.expired_on >= currentDate)
            ? "Urgent"
            : ""
        }
        dateType={getTimeSinceCreation(record.active_on_virtual, record.location_timezone)}
        // titlebtn={titlebtn(record)}
      />
    // </Link>
    ))}
  </Carousel>
)}




{/* {!roomRentData || roomRentData.length === 0 && <Shimmer width={"100%"} height={300} />} */}
  

{/* Rooms for Rent data */}
{roomRentData && roomRentData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">
      Rentals - የኪራይ ማስታወቂያዎች
    </div>
    <div className="view-btn">
      <i onClick={handleRedirect}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i>
    </div>
  </div>
)}

{roomRentData && roomRentData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {roomRentData.map((record, index) => (
      //  <Link to={`/rentDetails/${record._id}`} key={index}>
      <Card
        key={index}
        _id={record._id}
        title={record.adsInfo.title}
        adsButton="Price Drop"
        location={record.adsInfo.location.location_name}
         shareTitle ={"rentDetails"}
        titleColor=""
        myFavourite={record.is_favorite}
        imageSrc={record.adsInfo.image && record.adsInfo.image.map(img => img.url)} 
        // featured={record.isfeatured}
        featured={
          record.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        // featured={rentfeatureStatus[index] ? true : false} 
        dateType={getTimeSinceCreation(record.active_on_virtual, record.location_timezone)}
        rentAmount={
          record.adsInfo.rent.is_contact && record.adsInfo.rent.amount === null ? (
            <p>Please Contact</p>
          ) : (
            (record.adsInfo.rent.negotiable && record.adsInfo.rent.amount !== null) ? (
              <p>
                {getSymbolFromCurrency(record.adsInfo.rent.currency)}
                {record.adsInfo.rent.amount} {' '}{record.adsInfo.rent_info || ''} {' '}
                Negotiable 
              </p>
            ) : (
              record.adsInfo.rent.negotiable ? (
                <p>Negotiable</p>
              ) : (
                (record.adsInfo.rent.currency && record.adsInfo.rent.amount !== null) ? (
                  <p>{getSymbolFromCurrency(record.adsInfo.rent.currency)}{record.adsInfo.rent.amount}{record.adsInfo.rent_info || ''}</p>
                ) : (
                  null
                )
              )
            )
          )
        }
        
        
        UrgentLabel={
          record.addons_validity.some(item => item.name === "Urgent" && item.active_on <=  currentDate && item.expired_on >= currentDate)
            ? "Urgent"
              : ""
              }   
        
        
      />
    //  </Link>
    ))}
  </Carousel>
)}





{/* Job data */}
{myJobsData && myJobsData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Jobs - የስራ ማስታወቂያዎች</div>
    <div className="view-btn">
      <i onClick={handleRedirectJobs}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i>
    </div>
  </div>
)}


{myJobsData && myJobsData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {myJobsData.map((job, index) => (
        //  <Link to={`/jobDetails/${job._id}`} style={{ color: "#F17D3A" }}>
      <Jobcard
        key={index}
        _id={job._id}
        title={job.adsInfo.title}
        shareTitle ={"jobDetails"}
        myFavourite={job.is_favorite}
        location={job.adsInfo.location.location_name}
        imageSrc={job.adsInfo.image && job.adsInfo.image.map(img => img.url)} 
        // featured={job.is_featured.value}
        featured={
          job.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        // featured={jobFeaturedStatus[index] ? true : false} 
        dateType={getTimeSinceCreation(job.active_on_virtual, job.location_timezone)}
        jobAmount={
          (job.adsInfo.salary.amount) ? (
            <p>{getSymbolFromCurrency(job.adsInfo.salary.currency)} {job.adsInfo.salary.amount} {job.adsInfo.salary_info}</p>
          ) : (
          null
          )
        }
        eventLabel={
          job.addons_validity.some(item => item.name === "Upcoming Event" && item.active_on <=  currentDate && item.expired_on >= currentDate)
            ? "Upcoming Event"
            : job.addons_validity.some(item => item.name === "Urgent" && item.active_on <=  currentDate && item.expired_on >= currentDate)
              ? "Urgent"
              : ""
        }   
      />
      //  </Link>
    ))}
  </Carousel>
)}

              
            
        {/* Event data  */}
        {eventsData && eventsData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Events - የተለያዩ ዝግጅቶች</div>
    <div className="view-btn">
      <i onClick={handleRedirectEvents}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i>
    </div>
  </div>
)}

{eventsData && eventsData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {eventsData.map((e, index) => (
      //  <Link to={`/eventDetails/${e._id}`} style={{ color: "#F17D3A" }}>
      <Eventlistcard
        key={index}
        _id={e._id}
        title={e.adsInfo.title}
        shareTitle ={"eventDetails"}
        myFavourite={e.is_favorite}
        location={e.adsInfo.location.location_name}
        titleColor=""
        imageSrc={e.adsInfo.image && e.adsInfo.image.map(img => img.url)}
        // featured={e.isfeatured} 
        featured={
          e.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        // featured={eventFeaturedStatus[index] ? true : false} 
        dateType={getTimeSinceCreation(e.active_on_virtual, e.location_timezone)}
        eventAmount={
          <p>
            {(e.adsInfo.ticket_price.regular_ticket_price ?? 0) === 0
              ? "Free"
              : `${getSymbolFromCurrency(e.adsInfo.ticket_price.currency)}${e.adsInfo.ticket_price.regular_ticket_price}`}
          </p>
        }
         
        eventLabel={
          e.addons_validity.some(item => item.name === "Upcoming Event" && item.active_on <=  currentDate && item.expired_on >= currentDate)
            ? "Upcoming Event"
            : e.addons_validity.some(item => item.name === "Urgent" && item.active_on <=  currentDate && item.expired_on >= currentDate)
              ? "Urgent"
              : ""
        }        

      />
      //  </Link>
    ))}
  </Carousel>
)}
              
           

{/* Babysitters & Nannies Data */}
{babySitterData && babySitterData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Babysitters & Nannies - የሞግዚት ማስታወቂያዎች </div>
    <div className="view-btn">
      <i onClick={handleRedirectBabySitter}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i>
    </div>
  </div>
)}

{babySitterData && babySitterData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {babySitterData.map((baby, index) => (
        //  <Link to={`/babySitterDetails/${baby._id}`} style={{ color: "#F17D3A" }}>
      <Babysittercard
        key={index}
        _id={baby._id}
        title={baby.adsInfo.title}
        shareTitle ={"babySitterDetails"}
        // titlebtn={
        //   <Link to={`/babySitterDetails/${baby._id}`} style={{ color: "#F17D3A" }}>
        //     View Details
        //   </Link>
        // }
        location={baby.adsInfo.location.location_name}
        imageSrc={baby.adsInfo.image && baby.adsInfo.image.map(img => img.url)} 
        duration=""
        titleColor=""
        myFavourite={baby.is_favorite}
        // featured={baby.isfeatured} 
        featured={
          baby.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        // featured={babyFeaturedStatus[index] ? true : false} 
        dateType={getTimeSinceCreation(baby.active_on_virtual,baby.location_timezone)}
        babyAmount = {
          baby.adsInfo.expected_salary_amount && baby.adsInfo.expected_salary_amount.is_contact ? (
            <p>Please Contact</p>
          ) : (
            baby.adsInfo.expected_salary_amount ? (
              baby.adsInfo.expected_salary_amount.amount === 0 ? (
                <p>Please Contact</p>
              ) : (
                <p>
                  {getSymbolFromCurrency(baby.adsInfo.expected_salary_amount.currency)}
                  {baby.adsInfo.expected_salary_amount.amount}
                  {baby.adsInfo.expected_salary_rate}
                </p>
              )
            ) : null
          )
        }
        
        
        eventLabel={
          baby.addons_validity.some(item => item.name === "Upcoming Event" && item.active_on <=  currentDate && item.expired_on >= currentDate)
            ? "Upcoming Event"
            : baby.addons_validity.some(item => item.name === "Urgent" && item.active_on <=  currentDate && item.expired_on >= currentDate)
              ? "Urgent"
              : ""
        }   
        
      />
      // </Link>
    ))}
  </Carousel>
)}


        


   {/* Buy & Sell Data */}
{buySellData && buySellData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Buy & Sell - ግዢና ሽያጮች</div>
    <div className="view-btn">
      <i onClick={handleRedirectBuySell}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i>
    </div>
  </div>
)}


{buySellData && buySellData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {buySellData.map((buy, index) => (
      //  <Link to={`/buySellDetails/${buy._id}`} style={{ color: "#F17D3A" }}>
      <Buysellcard
        key={index}
        _id={buy._id}
        title={buy.adsInfo.title}
        location={buy.adsInfo.location.location_name}
        imageSrc={buy.adsInfo.image && buy.adsInfo.image.map(img => img.url)}
        // featured={buy.isfeatured}  
        featured={
          buy.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        eventLabel={
          buy.addons_validity.some(item => item.name === "Urgent" && item.active_on <=  currentDate && item.expired_on >= currentDate)
            ? "Urgent"
              : ""
        } 
        // featured={buySellFeaturedStatus[index] ? true : false} 
        myFavourite={buy.is_favorite}
        shareTitle ={"buySellDetails"}
        dateType={getTimeSinceCreation(buy.active_on_virtual, buy.location_timezone)}
        localbtn="Local"
        buyAmount={
          buy.adsInfo.price.is_contact ? (
            <p>Please Contact</p>
          ) : (
            <div style={{display:"flex"}}>
              {buy.price_drop && buy.adsInfo.price.amount > 0 ? (
                <>
                  <p style={{ textDecoration: 'line-through', color:"grey" }}>
                    {getSymbolFromCurrency(buy.adsInfo.price.currency)}
                    {buy.adsInfo.price.amount}
                  </p>
                  <p>
                    {getSymbolFromCurrency(buy.adsInfo.price.currency)}
                    {buy.price_drop}
                  </p>
                  {buy.adsInfo.price.negotiable ? (
                    <p>Negotiable</p>
                  ) : null}
                </>
              ) : (
                <>
                  {buy.adsInfo.price.amount === 0 ? (
                    <p>Free</p>
                  ) : (
                    <>
                      <p>
                        {getSymbolFromCurrency(buy.adsInfo.price.currency)}
                        {buy.adsInfo.price.amount}
                      </p>
                      {buy.adsInfo.price.negotiable ? (
                        <p>Negotiable</p>
                      ) : null}
                    </>
                  )}
                </>
              )}
            </div>
          )
        }
        
        // buyAmount={
        //   buy.adsInfo.price.is_contact ? (
        //     <p>Please Contact</p>
        //   ) : buy.adsInfo.price.negotiable && buy.adsInfo.price.amount > 0 ? (
        //     <p>{getSymbolFromCurrency(buy.adsInfo.price.currency)}
        //     {buy.adsInfo.price.amount} Negotiable</p>
        //   ) : (
        //    ""
        //   )
        // }
        // priceDrop={buy.price_drop && buy.adsInfo.price.amount > 0 ? (
        //   <p style={{ textDecoration: 'line-through' }}>
        //     {getSymbolFromCurrency(buy.adsInfo.price.currency)}
        //     {buy.adsInfo.price.amount}
        //   </p>
        // ) : null}
        
     
        
      />
      // </Link>
    ))}
  </Carousel>
)} 

            
         

       {/* Local Biz & Services Data */}
{localBizData && localBizData.length > 0 && (
  <div className="body-card">
    <div className="body-card-text">Local Biz & Services - ንግድና አገልግሎቶች</div>
    <div className="view-btn">
      <i onClick={handleRedirectLocalBiz}>
        <button type="button" className="btn btn-view">
          View All
        </button>
      </i>
    </div>
  </div>
)}

{localBizData && localBizData.length > 0 && (
  <Carousel
    responsive={responsive}
    ssr
    // showDots={true}
    infinite={true}
    containerClass="carousel-container"
    itemClass="carousel-item-padding-40-px"
  >
    {localBizData.map((biz, index) => (
       <Link to={`/localBizDetails/${biz._id}`} style={{ color: "#F17D3A" }}>
      <LocalBizcard
        key={index}
        _id={biz._id}
        shareTitle ={"localBizDetails"}
        title={biz.adsInfo.title}
        location={biz.adsInfo.location.location_name}
        myFavourite={biz.is_favorite}
        featured={
          biz.addons_validity.some(item => item.name === "Featured" && item.active_on <=  currentDate && item.expired_on >= currentDate)
        }
        
        // featured={bizFeaturedStatus[index] ? true : false} 
        imageSrc={biz.adsInfo.image && biz.adsInfo.image.map(img => img.url)} 
        localbtn="Local"
        dateType={getTimeSinceCreation(biz.active_on_virtual, biz.location_timezone)}
        eventLabel={
          biz.addons_validity.some(item => item.name === "Urgent" && item.active_on <=  currentDate && item.expired_on >= currentDate)
            ? "Urgent"
              : ""
        }   
      />
  </Link>
    ))}
  </Carousel>
)}



       







          {/* <div className="container-fluid">
            <div className="image-card-section">
              <div className="events-text">
                <h4>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </h4>
                <p>
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                  viverra efficitur convallis. Mauris non hendrerit nulla. Proin
                  tellus odio, commodo accumsan accumsan est est tempus neque
                  est tempus neque vel dolor etw.tempus neque est tempus neque
                  vel dolor etw. Lorem ipsum{" "}
                </p>
                <p>
                  dolor non hendrerit nulla. Proin tellus odio,non hendrerit
                  nulla. Proin tellus odio,non hendrerit nulla. Proin tellus
                  odio, sit amet.
                </p>
                <div className="button_boxes">
                  <Button title="Events" />
                  <Button title="Jobs" />
                </div>
                <div className="button_boxes">
                  <Button title="House for Rent" />
                  <Button title="Local Biz and Services" />
                </div>
                <div className="button_boxes">
                  <Button title="Buy and Sell" />
                  <Button title="Babysitters and Nannies" />
                </div>
              </div>
              <div className="event-img">
                <img src="/images/paragraph.png" alt="logo" />
              </div>
            </div>
          </div> */}
        </div>

        {/* <div className=" customer_feedback">
          <div className="customer_feedbac_text">
            <h4>Our Customer Feedback</h4>
            <p>Don't take our word for it. Trust our customers.</p>
          </div>
          <div className="customer_feedback_boxes">
            <div className="customer_feedback_box">
              <div className="rating-image-wrapper">
                <div>
                  <img src="/images/Rectangle 729 (1).svg" alt="logo" />
                </div>
                <div>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star-half-stroke"></i>
                  <i className="fa-regular fa-star"></i>
                </div>
              </div>
              <div className="customer-feedback-content">
                <h5>Floyd Miles</h5>
                <p>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </p>
              </div>
            </div>
            <div className="customer_feedback_box">
              <div className="rating-image-wrapper">
                <div>
                  <img src="/images/Rectangle 729 (2).svg" alt="logo" />
                </div>
                <div>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star-half-stroke"></i>
                  <i className="fa-regular fa-star"></i>
                </div>
              </div>
              <div className="customer-feedback-content">
                <h5>Floyd Miles</h5>
                <p>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </p>
              </div>
            </div>
            <div className="customer_feedback_box">
              <div className="rating-image-wrapper">
                <div>
                  <img src="/images/Rectangle 729 (3).svg" alt="logo" />
                </div>
                <div>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star"></i>
                  <i className="fa-solid fa-star-half-stroke"></i>
                  <i className="fa-regular fa-star"></i>
                </div>
              </div>
              <div className="customer-feedback-content">
                <h5>Floyd Miles</h5>
                <p>
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.
                  Exercitation veniam consequat sunt nostrud amet. Amet minim
                  mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                  Velit officia consequat duis enim velit mollit. Exercitation
                  veniam consequat sunt nostrud amet.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="related-blogs">
          <div className="blogs-text">
            <h2>Related Blogs</h2>
          </div>
        </div> */}

        {/* <div className="container-fluid">
          <div className="blogs-box cards-items">
            <div className="card-image">
              <div className="card">
                <div className="cardimage"></div>
                <img src="/images/room.jpg" alt="logo" className="image"/>
                <div className="container card-text">
                  <h6>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </h6>
                  <p>
                    Despite the economic advantage of buying used equipment,
                    some business owners shy away from this option because
                    they....
                  </p>
                  <h5>November 23,2022</h5>
                </div>
              </div>
            </div>
            <div className=" card-image">
              <div className="card">
                <div className="cardimage"></div>
                <img src="/images/room.jpg" alt="logo" className="image"/>
                <div className="container card-text">
                  <h6>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </h6>
                  <p>
                    Despite the economic advantage of buying used equipment,
                    some business owners shy away from this option because
                    they....
                  </p>
                  <h5>November 23,2022</h5>
                </div>
              </div>
            </div>
            <div className=" card-image">
              <div className="card">
                <div className="cardimage"></div>
                <img src="/images/room.jpg" alt="logo" className="image"/>
                <div className="container card-text">
                  <h6>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </h6>
                  <p>
                    Despite the economic advantage of buying used equipment,
                    some business owners shy away from this option because
                    they....
                  </p>
                  <h5>November 23,2022</h5>
                </div>
              </div>
            </div>
            <div className=" card-image">
              <div className="card">
                <div className="cardimage"></div>
                <img src="/images/room.jpg" alt="logo" className="image"/>
                <div className="container card-text">
                  <h6>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </h6>
                  <p>
                    Despite the economic advantage of buying used equipment,
                    some business owners shy away from this option because
                    they....
                  </p>
                  <h5>November 23,2022</h5>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        
        {/* <div className="">
          <div className="body-card-text view-more-button">
            <button>View more</button>
          </div>
        </div> */}

        {/* <div className="apps">
          <div className="apps-section">
            <h4>TRY THE MENEHARIYA APP </h4>
            <p> 
              Buy, sell and find just about anything <br />
              using the app on your mobile.
            </p>
          </div>

          <div className="apps-section1">
            <h6>GET YOUR APP TODAY </h6>
            <div className="links-images">
              <img src="/images/app.png" alt="logo" onClick={()=>{
                window.open("https://apps.apple.com/in/app/menehariya/id6446753677");
              }}/>
              <img src="/images/google.png" alt="logo" onClick={()=>{
                window.open("https://play.google.com/store/apps/details?id=com.menehariya.menehariyaapp&pli=1");
              }}/>
              <img src="/images/gallery.jpg" alt="logo" style={{visibility:'hidden'}} />
            </div>
          </div>
        </div> */}

        {/* <div className=" footer">
          <div className="footer-wraper">
            <img src="/images/logo_final.png" alt="logo" />
            <p>
              Lorem ipsum dolor sit amett, consectetur adipiscing elit. Nunc
              viverra efficitur convallis. Mauris non hendrerit nulla.
            </p>
          </div>
          <Footer_menu
            title="QUICK LINKS"
            text1="Babysitters and Nannies"
            text2="Room/Houses for Rent"
            text3="Jobs"
            text4="Events"
          />
          <Footer_menu
            title="MENEHARIYA"
            text1="Contact Us"
            text2="Blogs"
            text3="Term & Condition"
            text4="About us"
            text5="Privacy Policy"
          />
          <Footer_menu
            title="TRENDING SEARCH"
            text1="Lorem Ipsum"
            text2="Lorem Ipsum"
            text3="Lorem Ipsum"
            text4="Lorem Ipsum"
          />
          <div className="media-heading">
            <h6>SOCIAL MEDIA</h6>
            <p className="footer_icon">
              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18.75" cy="18.75" r="18.75" fill="#424242" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.6667 14.3333V11.8529C20.6667 10.7331 20.924 10.1667 22.7318 10.1667H25V6H21.2151C16.5771 6 15.0469 8.04427 15.0469 11.5534V14.3333H12V18.5H15.0469V31H20.6667V18.5H24.4854L25 14.3333H20.6667V14.3333Z"
                  fill="white"
                />
              </svg>

              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18.75" cy="18.75" r="18.75" fill="#424242" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M29 12.8958C28.2626 13.2167 27.4743 13.4333 26.6436 13.5333C27.4912 13.0333 28.1439 12.2417 28.449 11.3C27.6565 11.7625 26.7792 12.1 25.8426 12.2792C25.0924 11.4917 24.0244 11 22.8462 11C20.5787 11 18.7436 12.8083 18.7436 15.0375C18.7436 15.3542 18.7775 15.6625 18.8495 15.9583C15.4378 15.7917 12.4117 14.1833 10.3901 11.7375C10.0384 12.3333 9.83492 13.0292 9.83492 13.7667C9.83492 15.1667 10.5639 16.4042 11.6658 17.1292C10.9877 17.1125 10.352 16.9292 9.80102 16.625C9.80102 16.6417 9.80102 16.6583 9.80102 16.675C9.80102 18.6333 11.2166 20.2625 13.0941 20.6333C12.7508 20.725 12.3863 20.775 12.0134 20.775C11.7506 20.775 11.4921 20.75 11.242 20.7C11.7633 22.3042 13.2806 23.4708 15.0776 23.5042C13.6747 24.5875 11.9032 25.2333 9.97902 25.2333C9.64844 25.2333 9.3221 25.2125 9 25.175C10.8097 26.3333 12.9669 27 15.281 27C22.8377 27 26.9657 20.8458 26.9657 15.5083C26.9657 15.3333 26.9614 15.1583 26.953 14.9875C27.754 14.4167 28.449 13.7083 29 12.8958V12.8958Z"
                  fill="white"
                />
              </svg>

              <svg
                width="38"
                height="38"
                viewBox="0 0 38 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="19.25" cy="18.75" r="18.75" fill="#424242" />
                <circle cx="19.25" cy="19" r="3.5" fill="white" />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.1274 15.338C17.034 14.4314 18.2393 13.9268 19.5214 13.9268C20.8035 13.9268 22.0089 14.4341 22.9155 15.3407C23.4907 15.9159 23.9017 16.6071 24.1254 17.3786H27.7286V12.5357C27.7286 11.3996 26.8504 10.5214 25.7143 10.5214H13.3714C12.2353 10.5214 11.2714 11.3996 11.2714 12.5357V17.3786H14.9174C15.1412 16.6071 15.5521 15.9132 16.1274 15.338ZM26.3572 14.0868C26.3572 14.3898 26.1116 14.6354 25.8086 14.6354H24.1629C23.8599 14.6354 23.6143 14.3898 23.6143 14.0868V12.4411C23.6143 12.1381 23.8599 11.8925 24.1629 11.8925H25.8086C26.1116 11.8925 26.3572 12.1381 26.3572 12.4411V14.0868Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.9155 22.1262C22.0089 23.0328 20.8035 23.5268 19.5214 23.5268C18.2393 23.5268 17.034 23.0355 16.1274 22.1289C15.2224 21.224 14.7234 19.9929 14.7215 18.75H11.2714V24.8786C11.2714 26.0147 12.2353 26.9786 13.3714 26.9786H25.7143C26.8504 26.9786 27.7286 26.0147 27.7286 24.8786V18.75H24.3213C24.3195 19.9929 23.8205 21.2213 22.9155 22.1262V22.1262Z"
                  fill="white"
                />
              </svg>
            </p>
            <div className="footer_language">
              <select className="selectpicker" data-width="fit">
                <option data-content='<span className="flag-icon flag-icon-us"></span> English'>
                  English
                </option>
                <option data-content='<span className="flag-icon flag-icon-mx"></span> Español'>
                  Español
                </option>
              </select>
            </div>
          </div>
        </div> */}

        {/* <div className="modal bd-example-modal-sm" id="login">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title"><b>Log into your account</b></h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email </label>
                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your Email" />
                  </div>

                  <div className="form-group">
                    <label for="exampleInputPassword1">Password <span>*</span></label>
                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Create a password" />
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-confirm">
                      Confirm
                    </button>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-signup">
                      <span><img style={{ width: '5%', height: '5%' }} src="/images/googleicon.png" alt="logo" />
                        &nbsp;&nbsp; Sign up with Google</span>
                    </button>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-signup">
                      <span><img style={{ width: '5%', height: '5%' }} src="/images/facebook.png" alt="logo" />
                        &nbsp;&nbsp;  Sign up with Facebook </span>
                    </button>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-signup">
                      <span><img style={{ width: '10%', height: '5%' }} src="/images/apple.png" alt="logo" />
                        &nbsp;&nbsp; Sign up with Apple </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="modal bd-example-modal-sm" id="signup">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h6 className="modal-title"><b>Sign up</b></h6>
                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label for="exampleInputName1">Name <span>*</span></label>
                    <input type="text" className="form-control" id="exampleInputName1" aria-describedby="nameHelp" placeholder="Enter your name" />
                  </div>

                  <div className="form-group">
                    <label for="exampleInputEmail1">Email <span>*</span></label>
                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter your Email" />
                  </div>

                  <div className="form-group">
                    <label for="exampleInputPassword1">Password <span>*</span></label>
                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Create a password" />
                  </div>
                  <p>Must be at least 8 characters.</p>
                  <div className="form-group">
                    <button type="submit" className="btn btn-confirm">
                      Confirm
                    </button>
                    <p className="account">Already Have an account &nbsp;
                      <a data-bs-toggle="modal" data-bs-target="#login" id="logins">Login</a></p>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-signup">
                      <span><img style={{ width: '5%', height: '5%' }} src="/images/googleicon.png" alt="logo" />
                        &nbsp;&nbsp; Sign up with Google</span>
                    </button>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-signup">
                      <span><img style={{ width: '5%', height: '5%' }} src="/images/facebook.png" alt="logo" />
                        &nbsp;&nbsp;  Sign up with Facebook </span>
                    </button>
                  </div>
                  <div className="form-group">
                    <button type="submit" className="btn btn-signup">
                      <span><img style={{ width: '10%', height: '5%' }} src="/images/apple.png" alt="logo" />
                        &nbsp;&nbsp; Sign up with Apple </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}
