import React, { useState } from "react";
import style from "./forgetPassword.module.css"
import getFetchConfig from '../../utils/getFetchConfig';
import standardFetchHandlers from '../../utils/standardFetchHandlers';
import handleFetchErrors from '../../utils/handleFetchErrors';
import CommonModalWrapper from "../common-modal-Wrapper/CommonModalWrapper";
import VerifyOTPCardForForgetPassword from "../verify-otp-for-forget-password/VerifyOTPforforgetPassword";
import UpdatePassword from "../update-password/UpdatePassword";
import CloseIcon from "../Icons/CloseIcon";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoaderButton from "../buttons/loader-button/LoaderButton";
import appURLs from "../../appURL";

import { isTruthyString, isValidEmailAddress, isValidIndianMobileNumber } from '../../utils/verification';

export default function ForgetPassword({ updateModalVisibilityForForgetPassword = false }) {


    const appURL = appURLs(); 


    const navigate = useNavigate();
    const fetchConfig = getFetchConfig();


    const [showLoader, toggleLoader] = useState(false),
        [formData, updateFormData] = useState({ email_address: '', });

    const [modalIsOpenForVerifyOTP, updateModalVisibilityForVerifyOTP] = useState(false);
    const [modalIsOpenForUpdatePassword, updateModalVisibilityForUpdatePassword] = useState(false);
    const { email_address } = formData;

    const toggleModalForVerifyOTP = (toggleValue = false) => {
        updateModalVisibilityForVerifyOTP(toggleValue)
    }

    const toggleModalForUpdatePassword = (toggleValue = false) => {
        updateModalVisibilityForUpdatePassword(toggleValue)
    }

    const onChangeHandlerForFormData = (e) => {
        const inputName = e?.target?.name || e?.name,
            inputValue = e?.target?.value || e?.value;

        updateFormData((prev) => ({
            ...prev,
            [inputName]: inputValue
        }));
    };



    const onSubmitHandler = async (e, submitType) => {
        e.preventDefault();
        toast.dismiss();
        const missingData = [],
            invalidData = [];


        if (!email_address) missingData.push(`email address`);
        else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`email address`);

        // Show errors if needed
        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);
            return;
        }

        toggleLoader(true)
        fetch(`${appURL}/forget-password`, {
            ...fetchConfig,
            body: JSON.stringify({ email_address })
        })
            .then(handleFetchErrors)
            .then((res) => {
                toggleLoader(false)

                if (res?.status === 200) {
                    if(submitType === 1){
                        toggleModalForVerifyOTP(true)
                    } else {
                        toast.success(`OTP resend successfully`)
                    }

                    
                } else if (res?.status === 400) {
                    // return toast.error(res?.message)
                    return toast.error("Please enter correct email")
                } else {
                    return toast.error(`Something isn't working right`);
                }

            }).catch(standardFetchHandlers.error)
            .finally(() => {
                setTimeout(() => {
                    toggleLoader(false);
                }, 1000);
            });
    };


    return (

        <>
            <CommonModalWrapper isOpen={modalIsOpenForVerifyOTP} className={style.verify_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForVerifyOTP(false)} />
                <VerifyOTPCardForForgetPassword updateModalVisibilityForVerifyOTP={updateModalVisibilityForVerifyOTP} updateModalVisibilityForUpdatePassword={updateModalVisibilityForUpdatePassword} email_address={email_address} reGenerateOTPForEmail={onSubmitHandler} />
            </CommonModalWrapper>

            <CommonModalWrapper isOpen={modalIsOpenForUpdatePassword} className={style.verify_modal_wrapper} >
                <CloseIcon onClick={() => toggleModalForUpdatePassword(false)} />
                <UpdatePassword email_address={email_address} updateModalVisibilityForUpdatePassword={updateModalVisibilityForUpdatePassword} updateModalVisibilityForForgetPassword={updateModalVisibilityForForgetPassword} />
            </CommonModalWrapper>

            <main>

                {/* <h1 className={style.heading}>Change Password</h1> */}
                <form onSubmit={(e)=>onSubmitHandler(e, 1)} >

                    <div className="form-group">
                        <label for="exampleInputName1">Email Address </label>
                        <input type="email" onChange={onChangeHandlerForFormData} name='email_address' className="form-control" placeholder="Enter your email address" autoComplete="off" />
                    </div>
                    {
                        showLoader ?
                            <LoaderButton /> :
                            <div id={style.submit_btn} className="form-group">
                                <button type="submit" className="btn btn-confirm">
                                    Submit
                                </button>

                            </div>


                    }

                </form>
            </main>
        </>
    )
}