import React from 'react';
import style from "./checkoutButton.module.css";
import LoaderButton from '../loader-button/LoaderButton';

export default function CheckoutButton({
  cancelButtonText = "",
  submitBtnText = "",
  backBtnClickHandler = () => {},
  onClickHandler = () => {},
  className = ``,
  id = ``,
  showLoadder = false,
  disabled,
  showAdsPlan
}) {
  return (
    <section id={id} className={`${style.button_wrapper} ${className} button_wrapper`}>
      
      {/* Render back button only if cancelButtonText is not empty */}
      {cancelButtonText && (
        <div
          onClick={!showLoadder ? backBtnClickHandler : null}
          id="back_button"
          className="form-group"
        >
          <p id={style.back_button} className="btn-confirm">
            {cancelButtonText}
          </p>
        </div>
      )}

      <div id={style.submit_btn_wrapper} className="form-group">
        {showLoadder ? (
          // Replace this with <LoaderButton /> if needed
          <span
            onClick={onClickHandler}
            type="submit"
            className={`btn btn-confirm ${disabled ? 'disabled' : ''}`}
          >
            {submitBtnText}
          </span>
        ) : (
          <span
            onClick={onClickHandler}
            type="submit"
            className={`btn btn-confirm ${disabled ? 'disabled' : ''}`}
          >
            {submitBtnText}
          </span>
        )}
      </div>
    </section>
  );
}
