import React from "react";
import "./HelpAndSupport.css"; // Link to the external CSS file

const HelpAndSupport = () => {
  return (
    <div className="help-and-support">
      {/* <header>
        <img src="images/logo_final.svg" alt="Logo" width="300" />
      </header> */}

      <main>
        <div>
          <h1>Contact Us</h1>
          <p>
            Welcome to the Help and Support page for Menehariya! We're here to
            assist you with any questions or issues you may encounter while
            using our app. Below are commonly asked questions and helpful tips
            to ensure you have a smooth and enjoyable experience. If you are
            unable to find the information you need, please don't hesitate to
            contact our support team directly at{" "}
            <a href="#">support@menehariya.com</a>.
          </p>

          <h2>Frequently Asked Questions</h2>
          <ul>
            <li>
              <strong>How do I create an account?</strong>
              <p>
                To create an account, simply open the app and click on the "Sign
                Up" button. You will be prompted to enter your email address,
                create a password, and provide any other required information.
                Follow the on-screen instructions to complete the process.
              </p>
            </li>
            <li>
              <strong>I forgot my password. How can I reset it?</strong>
              <p>
                If you forgot your password, click on the "Forgot Password" link
                on the login screen. You will receive an email with instructions
                on how to reset your password.
              </p>
            </li>
            <li>
              <strong>How do I update my profile information?</strong>
              <p>
                To update your profile information, navigate to the "Profile"
                section within the app. Click on the "Edit Profile" button and
                make the desired changes. Don't forget to save your updates
                before exiting the page.
              </p>
            </li>
            <li>
              <strong>
                I am experiencing technical issues with the app. What should I
                do?
              </strong>
              <p>
                If you encounter technical issues, such as app crashes or error
                messages, we recommend restarting your device and ensuring that
                you have the latest version of the app installed. If the problem
                persists, please contact our support team for further
                assistance.
              </p>
            </li>
          </ul>

          <h2>Contact Support</h2>
          <p>
            If you have any additional questions or require further assistance,
            please feel free to reach out to our support team by emailing at{" "}
            <a href="#" className="support-link">support@menehariya.com</a>. We strive to respond to all
            inquiries within 24 hours and are dedicated to resolving any issues
            you may have.
          </p>
          <p>
            Thank you for choosing Menehariya! We appreciate your support and
            hope you enjoy using our app.
          </p>
          <p>Menehariya Support Team</p>
        </div>
      </main>

      {/* <footer>
        <nav>
          <a href="index.html">Home</a> |{" "}
          <a href="privacy.html">Privacy Policy</a> |{" "}
          <a href="terms.html">Terms and Conditions</a> |{" "}
          <a href="aboutus.html">About Us</a> |{" "}
          <a href="contactus.html">Contact Us</a>
        </nav>
      </footer> */}
    </div>
  );
};

export default HelpAndSupport;
