import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import getFetchConfig from "../../../utils/getFetchConfig";
import handleFetchErrors from "../../../utils/handleFetchErrors";
import standardFetchHandlers from "../../../utils/standardFetchHandlers";
import { DatePicker, Space } from "antd";
import { DOMParser } from "../../../utils/DOMParser";
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb'; // Import the locale for en-GB
import { format } from "date-fns";
import './Events.css';
import { useRef } from "react";
// import TimePicker from "../../../utils/TimePicker";
import styled from "styled-components";
import TimePicker from "rc-time-picker";
import 'rc-time-picker/assets/index.css';




import {
  isTruthyString,
  isValidURL,
  isValidEmailAddress,
  isValidYoutubURL,
  isValidFacebookVideoURL,
  isValidZoomVideoURL,
  isValidInstagramVideoURL,
  isValidTiktokVideoURL,
  isValidGoogleMeetURL,
  validateTeamsVideoCallUrl,
  isDescriptionHas5Words,
} from "../../../utils/verification";

import CongratulationsScreen from "../congratulations-screen/CongratulationsScreen";

import Inputs from "../../Inputs/Inputs";
import PostAdsHeading from "../post-ads-heading/PostAdsHeading";
import FormButton from "../../buttons/form-button/FormButton";
import AdsListerInfo from "../ads-lister-info/AdsListerInfo";
// import AdsPlans from "../ads-plans/AdsPlans";
import AdsPlansUpdated from "../ads-plans/AdsPlansUpdated";
import RichTextEditor from "../../form-input/RichTextEditor";
import ImageUpload from "../images-upload/ImageUpload";
import MultiSelect from "../../form-input/MultiSelect";
import SelectLocation from "../select-location/SelectLocation";
import formDataAppender from "../../../utils/formDataAppender";
import appURLs from "../../../appURL";

import style from "./events.module.css";
import cuid from "cuid";


// const formatDate = (date, format) => {
//     const year = date.getUTCFullYear();
//     let month = date.getUTCMonth() + 1; // Months are zero-based
//     let day = date.getUTCDate();

//     // Ensure month and day are always two digits
//     month = month < 10 ? '0' + month : month;
//     day = day < 10 ? '0' + day : day;

//     if (format === "MM/dd/yyyy") {
//         return `${month}/${day}/${year}`;
//     } else {
//         // Handle other formats if needed
//         return ""; // Return empty string for unsupported format
//     }
// };


export default function Events({
  toggleModalVisibilty = () => { },
  adsCategoriesId = ``,
  adsId = "",
  adsIds,
  setadsIds,
}) {

  dayjs.locale('en-gb'); // Replace 'en' with your desired locale

  const fetchConfig = getFetchConfig(),
    appURL = appURLs();

  // const format = "HH:mm";

  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventsDetailData, seteventsDetailData] = useState();
  const [forEventDate, setforEventDate] = useState(false);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [platformLinks, setPlatformLinks] = useState({});
  const [openStart, setopenStart] = useState(false);
  const [openEnd, setopenEnd] = useState(false);

  const [myOther, setMyOther] = useState(false);

  const [locationResponseData, setLocationResponseData] = useState(false);
  const [selectedLocationFromSuggestions, setSelectedLocationFromSuggestions] =
    useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [viperrorMessage, setVipErrorMessage] = useState("");
  const timePickerRef = useRef(null);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [eventsDynamicData, updateEventsDynamicData] = useState({}),
    [selectedTagline, upatedSelectedTagline] = useState([]),
    [showLoader, toggleLoader] = useState(false),
    [isAddPlatFormOpen, toggleIsAddPlatFormOpen] = useState(true),
    [platformListsArr, updatePlatformListsArr] = useState([
      `Facebook`,
      `Instagram`,
      `Tiktok`,
      `Zoom`,
      `Youtube`,
      "Microsoft Teams",
      "Google Meet",
      `Other`,
    ]),
    [selectedPlatform, updateSelectedPlatform] = useState([]),
    [isCheckedForFreeTicketPrice, updateIsCheckedForFreeTicketPrice] =
      useState(false),
    [eventIds, updateEventIds] = useState(null),
    [images, updateImage] = useState([]),
    [steps, updateSteps] = useState(0);

  useEffect(() => {
    console.log("isCheckedForFreeTicketPrice", isCheckedForFreeTicketPrice);
  }, [isCheckedForFreeTicketPrice])

  const [lat, setlat] = useState("");
  const [lng, setlng] = useState("");

  const [time, setTime] = useState(""); // State to store selected time
  const [showPicker, setShowPicker] = useState(false); // State to control visibility of time picker
  // const timeInputRef = useRef(null); // Reference to input field

  const handleInputChange = (e) => {
    setTime(e.target.value);
  };

  const togglePicker = () => {
    setShowPicker((prevState) => !prevState);
  };

  const format = 'h:mm a';

  const now = moment().hour(20).minute(22);
  console.log("now", now);


  // const

  console.log(eventStartDate, "eventStartDate");

  const CustomTimePicker = styled(TimePicker)`
  & {
    font-family: "Open Sans";
    font-size: 12px;

    .picker-container .picker-column .picker-item.picker-item-selected {
      color: #3f3f46;
    }

    .picker-container .picker-column .picker-item {
      color: #7d7d7d;
    }

    & .picker-container .picker-inner {
      padding: 0;
    }

    & .picker-highlight {
      color: #212529;
      &::before {
        transform: scaleY(1);
        background-color: #d1d1d1;
      }
      &::after {
        transform: scaleY(1);
        background-color: #d1d1d1;
      }
    }
  }
`;

  const [timepickerVisible, setTimepickerVisible] = useState(false);
  const [selectedHour, setSelectedHour] = useState('12');
  const [selectedMinute, setSelectedMinute] = useState('00');
  const [selectedAmpm, setSelectedAmpm] = useState('AM');
  const timeInputRef = useRef(null);
  const [eStartTime, seteStartTime] = useState(null);
  const [eEndTime, seteEndTime] = useState(null);
  const [myadsId, setmyadsId] = useState('');
  const [myadsType, setmyadsType] = useState('');

  const toggleTimepicker = () => {
    setTimepickerVisible(!timepickerVisible);
  };

  const handleOkClick = () => {
    timeInputRef.current.value = `${selectedHour}:${selectedMinute} ${selectedAmpm}`;
    setTimepickerVisible(false);
  };

  const generateTimeItems = (range, unit) => {
    return [...Array(range).keys()].map(i => {
      const value = i < 10 ? '0' + i : i.toString();
      return (
        <div
          key={i}
          className={`time-item ${unit === 'hour' && selectedHour === value ? 'selected' : ''} ${unit === 'minute' && selectedMinute === value ? 'selected' : ''}`}
          onClick={() => unit === 'hour' ? setSelectedHour(value) : setSelectedMinute(value)}
        >
          {value}
        </div>
      );
    });
  };

  const [valueGroups, setValueGroups] = useState({
    hour: '12',
    minute: '00',
    period: 'AM'
  });

  const optionGroups = {
    hour: [
      { value: '01', label: '01' },
      { value: '02', label: '02' },
      { value: '03', label: '03' },
      { value: '04', label: '04' },
      { value: '05', label: '05' },
      { value: '06', label: '06' },
      { value: '07', label: '07' },
      { value: '08', label: '08' },
      { value: '09', label: '09' },
      { value: '10', label: '10' },
      { value: '11', label: '11' },
      { value: '12', label: '12' }
    ],
    minute: [
      { value: '00', label: '00' },
      { value: '15', label: '15' },
      { value: '30', label: '30' },
      { value: '45', label: '45' }
    ],
    period: [
      { value: 'AM', label: 'AM' },
      { value: 'PM', label: 'PM' }
    ]
  };

  // Update the value in response to user picking event
  const handleChange = (name, value) => {
    setValueGroups((prevValueGroups) => ({
      ...prevValueGroups,
      [name]: value,
    }));
  };

  const [isHideMobileNumberChecked, toggleIsHideMobileNumberChecked] =
    useState(false),
    [isHideEmailAddressChecked, toggleIsHideEmailAddressChecked] =
      useState(false),
    [isHideEmailAddress1Checked, toggleIsHideEmailAddress1Checked] =
      useState(false);
  // [placeholder, setPlaceholder] = useState('Select platform to enter link');

  // lister info
  const hideEmailNumberSwitchButtonHandler = (e) => {
    const inputName = e?.target?.name;

    if (inputName === `hide_secondary_phone_number`)
      toggleIsHideEmailAddressChecked(!isHideEmailAddressChecked);
    if (inputName === `hide_primary_phone_number`)
      toggleIsHideMobileNumberChecked(!isHideMobileNumberChecked);
    if (inputName === `hide_my_email_address`)
      toggleIsHideEmailAddress1Checked(!isHideEmailAddress1Checked);
  };

  const [formData, updateFormData] = useState({
    type: "",
    title: "",
    category: "",
    descriptions: "",
    venue_name: "",
    recurring_type: "",
    location: "",
    location_name: "",
    latitude: "",
    longitude: "",
    is_regular_price_free: false,
    regular_ticket: null,
    ticket_price: null,
    regular_ticket_price: 1,
    vip_ticket_price: null,
    no_of_vip_ticket: null,
    no_of_regular_ticket: null,
    vip_ticket: null,
    currency: eventsDynamicData?.currency?.[0],
    currency_vip: eventsDynamicData?.currency?.[0],
    myDef: "",
    instagram_platform: "",
    zoom_platform: "",
    youtube_platform: "",
    tiktok_platform: "",
    other_platform: "",
    other_platform_name: "",
    facebook_platform: "",
    microsoft_teams_platform: "",
    google_meet_platform: "",

    time_zone: "",
    video_link: "",
    event_start_time: "",
    event_end_time: "",
    event_start_date: "",
    event_end_date: "",
    tagline: [],
    hosted_by: JSON.parse(localStorage.getItem(`user`))?.name,
    email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
    primary_phone_number: eventsDetailData?.lister_basic_info?.primary_mobile_number?.primary_phone_number
      ? eventsDetailData?.lister_basic_info?.primary_mobile_number?.primary_phone_number : JSON.parse(localStorage.getItem(`user`))?.phone_number,
    secondary_phone_number: "",
    website: "",
    hide_secondary_phone_number: false,
    hide_primary_phone_number: false,
    link: "",
    organization_name: "",
    address_info: "",
    preferableModeContact: null,
    hideAddress: 1,
    addressInfo: "",
    phoneNumber: null,
    adsType: "",
    status: ""
  });

  console.log(formData, "formData");

  const {
    type,
    title,
    category,
    descriptions,
    venue_name,
    currency_vip,
    recurring_type,

    is_regular_price_free,

    myDef,
    instagram_platform,
    zoom_platform,
    youtube_platform,
    tiktok_platform,
    other_platform,
    other_platform_name,
    facebook_platform,
    microsoft_teams_platform,
    google_meet_platform,
    location,
    location_name,
    latitude,
    longitude,

    time_zone,
    tagline,

    event_start_time,
    event_end_time,
    event_start_date,
    event_end_date,

    ticket_price,
    currency,
    regular_ticket_price,
    vip_ticket_price,
    regular_ticket,
    vip_ticket,
    video_link,

    no_of_vip_ticket,
    no_of_regular_ticket,

    link,
    organization_name,

    hosted_by,
    email_address,
    primary_phone_number,
    secondary_phone_number,
    hide_primary_phone_number,
    hide_secondary_phone_number,
    adsType,
    status
  } = formData;

  console.log(title, "title");

  const handleOnChangeFreeRegularFreePrice = () => {
    updateFormData({
      ...formData,
      ticket_price: ``,
      regular_ticket_price: !isCheckedForFreeTicketPrice ? null : 1
    });
    updateIsCheckedForFreeTicketPrice(!isCheckedForFreeTicketPrice);
    console.log(isCheckedForFreeTicketPrice);
  };

  const [dd, ddd] = useState({});
  const [adsDetails, setadsDetails] = useState(null);

  const [hours, setHours] = useState(1);
  const [minutes, setMinutes] = useState(0);
  const [amPm, setAmPm] = useState('AM');

  const [endHours, setendHours] = useState(1);
  const [endMinutes, setendMinutes] = useState(0);
  const [endAmPm, setendAmPm] = useState('AM');

  const [formattedTime, setFormattedTime] = useState('');
  const [formattedEnd, setformattedEnd] = useState('');

  useEffect(() => {
    console.log("formattedTime", formattedTime);
  }, [formattedTime])

  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");

  // Handle typing in the input field (with character limit)
  const handleTagInputChange = (e) => {
    const value = e.target.value;
    if (value.length <= 25) {
      setInputValue(value);  // Only update the input if the length is <= 25
    }
  };

  // Handle adding a tag (either pressing Enter or clicking the Add button)
  const handleAddTag = () => {
    if (inputValue.trim() && !tags.includes(inputValue)) {
      updateFormData((prevData) => ({
        ...prevData,
        tagline: [...prevData.tagline, inputValue],  // Append to tagline array
      }));
      setTags([...tags, inputValue]);  // Add the tag to the list
      setInputValue("");  // Clear the input field
    }
  };

  // Handle tag removal
  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter(tag => tag !== tagToRemove));  // Remove the tag from the list
  };

  // Handle the Enter key press
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleAddTag();
    }
  };

  // useEffect(() => {
  //   console.log("timePickerRef",timePickerRef);
  //   if (openStart && !timePickerRef.current) {
  //     timePickerRef.current.focus(); // Focus the time picker input when it opens
  //   }
  // }, [openStart]);

  const onChangeForTime = (time, timeString, type) => {
    const selectedHour = moment(timeString, "HH:mm").hour(); // extract the selected hour
    console.log(`Selected hour: ${selectedHour}`);
    console.log(selectedHour, "selectedHour");
    if (type === 1) {
      updateFormData({
        ...formData,
        event_start_time: timeString,
      });
    } else if (type === 2) {
      updateFormData({
        ...formData,
        event_end_time: timeString,
      });
    }
  };


  useEffect(() => {
    formatTime();
  }, [hours, minutes, amPm])


  useEffect(() => {
    formatEndTime();
  }, [endHours, endMinutes, endAmPm])

  const formatTime = () => {
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    updateFormData({
      ...formData,
      event_start_time: `${formattedHours}:${formattedMinutes} ${amPm}`,
    });
    // setFormattedTime(`${formattedHours}:${formattedMinutes} ${amPm}`);
  };

  const formatEndTime = () => {
    const formattedHours = endHours < 10 ? `0${endHours}` : endHours;
    const formattedMinutes = endMinutes < 10 ? `0${endMinutes}` : endMinutes;
    updateFormData({
      ...formData,
      event_end_time: `${formattedHours}:${formattedMinutes} ${endAmPm}`,
    });
    // setformattedEnd(`${formattedHours}:${formattedMinutes} ${endAmPm}`);
  };

  useEffect(() => {
    console.log("event_start_time", event_start_time);
  }, [event_start_time])

  const handleHourChange = (e) => {
    setHours(e.target.value)
    // Handle hour change logic
  };
  const handleEndHourChange = (e) => {
    setendHours(e.target.value)
    // Handle hour change logic
  };

  const handleMinuteChange = (e) => {
    setMinutes(e.target.value)
    // Handle minute change logic
  };
  
  const handleEndMinuteChange = (e) => {
    setendMinutes(e.target.value)
    // Handle minute change logic
  };

  const handleAmPmChange = (e) => {
    setAmPm(e.target.value);
    // Handle AM/PM change logic
  };
  const handleEndAmPmChange = (e) => {
    setendAmPm(e.target.value);
    // Handle AM/PM change logic
  };

  function convertTime(input) {
    // Extract the time part from the input string
    let timePart = input.toString().match(/\d{2}:\d{2}:\d{2}/)[0];
    let [hours, minutes, seconds] = timePart.split(':').map(Number);

    // Determine AM or PM
    let period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // Converts 0 hour to 12 for midnight

    // Format the hours and minutes to ensure they are two digits
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Construct the result string
    let result = `${formattedHours}:${formattedMinutes} ${period}`;
    updateFormData({
      ...formData,
      event_start_time: result,
    });

    console.log("result got time", result);
  }
  function convertTimeEnd(input) {
    // Extract the time part from the input string
    let timePart = input.toString().match(/\d{2}:\d{2}:\d{2}/)[0];
    let [hours, minutes, seconds] = timePart.split(':').map(Number);

    // Determine AM or PM
    let period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    hours = hours % 12 || 12; // Converts 0 hour to 12 for midnight

    // Format the hours and minutes to ensure they are two digits
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Construct the result string
    let result = `${formattedHours}:${formattedMinutes} ${period}`;
    updateFormData({
      ...formData,
      event_end_time: result,
    });

    console.log("result got time", result);
  }

  const onChangeHandlerForForm = (e) => {
    const inputName = e?.target?.name || e?.name;
    let inputValue = e?.target?.value || e?.value;

    console.log(inputValue);

    // Input type for validation of numbers

    if (
      inputName === "ticket_price" ||
      inputName === "regular_ticket_price" ||
      inputName === "vip_ticket"
    ) {
      // let inputValue1 = e.target.value.replace(/\D/g, '');
      let inputValue1 = e.target.value.replace(/[^0-9.]/g, "");

      if (/^0+$|^0+[1-9][0-9]*$/.test(inputValue1)) {
        setErrorMessage("Please enter a valid amount.");
      } else {
        setErrorMessage("");
      }

      if (inputValue1.length > 7) {
        inputValue1 = inputValue1.slice(0, 7);
      }

      inputValue = inputValue1;
    }

    if (inputName === "vip_ticket_price") {
      let inputValue2 = e.target.value.replace(/[^0-9.]/g, "");
      if (/^0+$|^0+[1-9][0-9]*$/.test(inputValue2)) {
        setVipErrorMessage("Please enter a valid amount.");
      } else {
        setVipErrorMessage("");
      }

      if (inputValue2.length > 7) {
        inputValue2 = inputValue2.slice(0, 7);
      }

      inputValue = inputValue2;
    }

    // Input type for validation of numbers

    if (
      inputName === `primary_phone_number` ||
      inputName === `secondary_phone_number`
    ) {
      const inputNumber1 = e.target.value.replace(/\D/g, "");

      if (inputNumber1.length <= 15) {
        updateFormData((prev) => ({
          ...prev,
          [inputName]: inputValue,
        }));
      }
    } else {
      updateFormData((prev) => ({
        ...prev,
        [inputName]: inputValue,
      }));
    }
  };

  const disabledEndDate = (current) => {
    // disable dates before the selected start date
    return current && eventStartDate && current < eventStartDate;
  };

  const disabledStartDate = (current) => {
    // disable dates before today
    return current && current < moment().startOf("day");
  };

  const onChangeForDate = (date, dateString, pickerNumber) => {
    setforEventDate(false);
    // handle date changes for start and end date pickers
    console.log("pickerNumber", pickerNumber);

    if (pickerNumber === 1) {
      console.log("date", date);
      setEventStartDate(date);

      setEventEndDate(null);
      updateFormData({
        ...formData,
        event_start_date: dateString,
        event_end_date: null,
      });
    } else if (pickerNumber === 2) {
      updateFormData({
        ...formData,
        event_end_date: dateString,
      });
      setEventEndDate(date);
    }
  };
  // const onChangeForDate = (date, dateString, pickerNumber) => {
  //     // handle date changes for start and end date pickers

  //     if (pickerNumber === 1) {
  //       setEventStartDate(date);
  //       setEventEndDate(null);
  //       updateFormData({
  //         ...formData,
  //         event_start_date: dateString,
  //         event_end_date: null
  //       });
  //     } else if (pickerNumber === 2) {
  //       const startDate = eventStartDate;
  //       const endDate = date;

  //       const diffInDays = endDate.diff(startDate, 'days');

  //       if (diffInDays < 30) {
  //         const newEndDate = endDate.clone().add(1, 'day');
  //         setEventEndDate(newEndDate);
  //         updateFormData({
  //           ...formData,
  //           event_end_date: dateString
  //         });

  //         // Store the next day of the end date in local storage
  //         localStorage.setItem('event_end_date', newEndDate.format(dateFormat));
  //       } else {
  //         const newEndDate = startDate.clone().add(1, 'month').startOf('month');
  //         setEventEndDate(newEndDate);
  //         updateFormData({
  //           ...formData,
  //           event_end_date: newEndDate.format(dateFormat)
  //         });

  //         // Store the start date of the next month in local storage
  //         localStorage.setItem('event_end_date', startDate.clone().add(1, 'month').format(dateFormat));
  //       }
  //     }
  //   };

  // console.log(eventEndDate)


  useEffect(() => {
    console.log("selectedFiles", selectedFiles);
    // alert(`selectedFiles${selectedFiles}`)
  }, [selectedFiles])

  // useEffect(()=>{
  //   alert(`Steps:${steps}`)
  // },[steps])

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    toast.dismiss();
    console.log("eventtime", eventStartDate, eventEndDate, steps);
    console.log("sourabh adsType", adsCategoriesId || adsType);

    // alert(`Steps:${steps}`);


    if (steps === 4) return toggleModalVisibilty("babySitterAndNanny", false);

    const missingData = [],
      invalidData = [];

    if (steps === 0) {
      if (!(title || type || descriptions || images.length === 0))
        return toast.error(`Please provide all data`);

      if (!isTruthyString(type)) missingData.push(`Event type`);
      if (!isTruthyString(title)) missingData.push(`Event title`);
      // if (!isTruthyString(category)) missingData.push(`Event category`);

      if (!isTruthyString(descriptions)) missingData.push(`description`);
      else if (isDescriptionHas5Words(descriptions)) {
        return toast.error(
          "You must write at least 5 words in your description"
        );
      }

      // if (!isTruthyString(location)) missingData.push(`location`);

      // if (!isTruthyString(location_name)) missingData.push("location");
      // else if (!locationResponseData && !adsIds) {
      //   return toast.error("Please select a location from the suggestions");
      // }

      // if (venue_name && !isTruthyString(venue_name))
      //   missingData.push(`venue name`);
      // if (!venue_name || !isTruthyString(venue_name)) {
      //   missingData.push('venue name');
      //   console.log(venue_name.length,"venue_name.length")
      // } else 

      if (venue_name && venue_name.length > 70) {
        console.log(venue_name.length, "venue_name.length")
        return toast.error('Venue name should not exceed 70 characters');
      }

      if (
        type === "Live Event" ||
        type === "Both Venue based and Live Streaming Event"
      ) {
        if (selectedPlatform.length) {
          selectedPlatform.forEach((platform) => {
            console.log(`platform`, platform);

            if (
              platform.trim().toLowerCase() === "instagram" &&
              !isTruthyString(instagram_platform)
            )
              missingData.push(`Instagram platform`);
            // else if ((platform.trim()).toLowerCase() === "instagram" && instagram_platform && !isValidInstagramVideoURL(instagram_platform)) invalidData.push(`Instagram platform link`);
            else if (
              platform.trim().toLowerCase() === "instagram" &&
              instagram_platform
            ) {
              const regex = /^(?:https?:\/\/)?(?:www\.)?instagram\.com/i;
              if (!regex.test(instagram_platform)) {
                invalidData.push("Invalid Instagram platform link");
              }
            }

            if (
              platform.trim().toLowerCase() === "tiktok" &&
              !isTruthyString(tiktok_platform)
            )
              missingData.push(`Tiktok platform`);
            else if (
              platform.trim().toLowerCase() === "tiktok" &&
              tiktok_platform &&
              !isValidTiktokVideoURL(tiktok_platform)
            )
              invalidData.push(`Tiktok platform link`);

            if (
              platform.trim().toLowerCase() === "facebook" &&
              !isTruthyString(facebook_platform)
            )
              missingData.push(`Facebook platform`);
            else if (
              platform.trim().toLowerCase() === "facebook" &&
              facebook_platform
            ) {
              const isValid = /^(?:https?:\/\/)?(?:www\.)?facebook\.com/i.test(
                facebook_platform
              );
              if (!isValid) {
                invalidData.push("Facebook platform link");
              }
            }

            if (
              platform.trim().toLowerCase() === "youtube" &&
              !isTruthyString(youtube_platform)
            )
              missingData.push(`Youtube platform`);
            else if (
              platform.trim().toLowerCase() === "youtube" &&
              youtube_platform &&
              !isValidYoutubURL(youtube_platform)
            )
              invalidData.push(`Youtube platform link`);

            if (
              platform.trim().toLowerCase() === "zoom" &&
              !isTruthyString(zoom_platform)
            )
              missingData.push(`Zoom platform missing`);
            else if (
              platform.trim().toLowerCase() === "zoom" &&
              zoom_platform &&
              !isValidZoomVideoURL(zoom_platform)
            )
              invalidData.push(`Zoom platform link`);

            if (
              platform.trim().toLowerCase() === "google meet" &&
              !isTruthyString(google_meet_platform)
            )
              missingData.push(`Google Meet platform missing`);
            else if (
              platform.trim().toLowerCase() === "google meet" &&
              google_meet_platform &&
              !isValidGoogleMeetURL(google_meet_platform)
            )
              invalidData.push(`Google Meet platform link`);

            if (
              platform.trim().toLowerCase() === "microsoft teams" &&
              !isTruthyString(microsoft_teams_platform)
            )
              missingData.push(`Microsoft team platform missing`);
            else if (
              platform.trim().toLowerCase() === "microsoft teams" &&
              microsoft_teams_platform &&
              !validateTeamsVideoCallUrl(microsoft_teams_platform)
            )
              invalidData.push(`Microsoft team  platform link`);

            // if ((platform.trim()).toLowerCase() === "microsoft teams" && !isTruthyString(microsoft_teams_platform)) missingData.push(`Microsoft team platform missing`);
            // else if (microsoft_teams_platform && !isValidZoomVideoURL(microsoft_teams_platform)) invalidData.push(`Microsoft team  platform link`);
          });
        }

        if (selectedPlatform.includes("other")) {
          const isDataIncomplete = otherPlatformList.some(
            (otherPlatform) =>
              !otherPlatform.platform_name || !otherPlatform.platform_link
          );

          console.log(isDataIncomplete);

          if (isDataIncomplete || otherPlatformList.length === 0)
            missingData.push(`Other platform data`);
        }
      }

      // if (tagline && !isTruthyString(tagline)) missingData.push(`tagline`);
    } else if (steps === 1) {
      // alert(`eventStartDate ${eventStartDate}`)
      function parseDateFromData(eventStartDate) {
        const { $d } = eventStartDate;
        return new Date($d);
      }
      function stripTime(date) {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
      }
      const startDate = stripTime(new Date());;
      const givenDate = stripTime(parseDateFromData(eventStartDate !== null && eventStartDate));
      // alert(`givenDate ${givenDate} startdate ${startDate}`);
      // alert(`isCheckedForFreeTicketPrice:${isCheckedForFreeTicketPrice}ticket_price:${ticket_price}regular_ticket_price${vip_ticket_price}`)
      if (givenDate < startDate) {
        // alert(`givenDate ${givenDate} startdate ${startDate}`);
        setforEventDate(true);
        missingData.push('Please change your Event start date and Event end date');
        // return;
      }
      if (eventStartDate === null) {
        console.log("eventStartDate", eventStartDate);
        missingData.push(`Event start date`);
      }

      if (eventEndDate === null) {
        missingData.push(`Event end date`);
      }

      if (!isTruthyString(event_start_time))
        missingData.push(`Event start time`);
      if (!isTruthyString(event_end_time)) missingData.push(`Event end time`);

      if (!isTruthyString(time_zone)) missingData.push(`Time Zone`);

      if (recurring_type && !isTruthyString(recurring_type))
        missingData.push(`Recurring event`);

      // if (!isCheckedForFreeTicketPrice && isNaN(regular_ticket_price)){
      // console.log("event date",event_start_date);
      //   missingData.push(`Ticket price`);}

      // if (!isCheckedForFreeTicketPrice && !regular_ticket_price)
      //   missingData.push(`Ticket price`);
      // else if (
      //   !isCheckedForFreeTicketPrice &&
      //   ticket_price &&
      //   isNaN(regular_ticket_price)
      // )
      //   invalidData.push(`Price of regular ticket`);
      // else if (
      //   !isCheckedForFreeTicketPrice &&
      //   ticket_price &&
      //   isNaN(vip_ticket_price)
      // )
      //   invalidData.push(`Price of vip ticket`);

      if (video_link && !isValidYoutubURL(video_link))
        invalidData.push(`Youtube video link`);

      if (
        event_start_date &&
        event_end_date &&
        event_start_time &&
        event_end_time &&
        event_start_date === event_end_date
      ) {
        if (event_start_time === event_end_time)
          return toast.error(
            `Event start time and end time can not be same when event start and end date same `
          );
      }
      if (regular_ticket_price < 0) {
        return toast.error(`Please enter a valid amount`);
      }

      if (/^0+$|^0+[1-9][0-9]*$/.test(vip_ticket_price)) {
        return toast.error(`Please enter a valid amount`);
      }
    } else if (steps === 2) {
      if (
        !(
          email_address ||
          primary_phone_number ||
          secondary_phone_number ||
          primary_phone_number
        )
      )
        return toast.error(`PLlease provide all data`);

      // if (!isTruthyString(hosted_by)) missingData.push(`Name`);
      if (!email_address) missingData.push(`Email address`);
      else if (email_address && !isValidEmailAddress(email_address))
        invalidData.push(`Email address`);

      if (!primary_phone_number) missingData.push(`Primary phone number`);
      else if (primary_phone_number && isNaN(primary_phone_number))
        invalidData.push(`Primary phone number`);
      else if (
        primary_phone_number &&
        (primary_phone_number.length < 10 || primary_phone_number.length > 15)
      )
        invalidData.push(`Primary phone number`);

      if (secondary_phone_number && isNaN(secondary_phone_number))
        invalidData.push(`Secondary phone number`);
      else if (
        secondary_phone_number &&
        (secondary_phone_number.length < 10 ||
          secondary_phone_number.length > 15)
      )
        invalidData.push(`secondary phone number`);

      if (
        secondary_phone_number &&
        secondary_phone_number &&
        primary_phone_number === secondary_phone_number
      )
        return toast.error(
          `Primary phone number can not be same as secondary phone number`
        );

      if (!isTruthyString(hosted_by)) missingData.push(`hosted by`);
    }



    function removeDuplicates(arr) {
      return arr.filter((item, index) => arr.indexOf(item) === index);
    }

    // Show errors if needed
    if (missingData.length || invalidData.length) {
      if (missingData.length)
        toast.error(`Missing: ${removeDuplicates(missingData).join(`, `)}`);
      if (invalidData.length)
        toast.error(`Invalid: ${removeDuplicates(invalidData).join(`, `)}`);
      return;
    } else if (steps !== 1 && steps !== 2) {
      updateSteps(steps + 1);
    }


    if (steps == 2 && adsIds && status === "active") {
      toast.success("Ad edit successfully");
      window.location.reload();
    }

    let formDataObj = {
      redirect_uri_success: "menehariya.netscapelabs.com/success",
      adsType: adsCategoriesId || adsType,
      currency,
      currency_vip,
      ticket_price: !is_regular_price_free ? 0 : ticket_price,
      preferableModeContact: 1,
    };

    // if (type) formDataObj.type = type;
    if (title) formDataObj.title = title;
    if (descriptions) formDataObj.details = descriptions;
    if (category) formDataObj.category = category;

    if (instagram_platform) formDataObj.instagram_platform = instagram_platform;
    if (zoom_platform) formDataObj.zoom_platform = zoom_platform;
    if (youtube_platform) formDataObj.youtube_platform = youtube_platform;
    if (tiktok_platform) formDataObj.tiktok_platform = tiktok_platform;

    if (otherPlatformList.length > 0 && myOther)
      formDataObj.other_platform = otherPlatformList.map(
        (item) => item.platform_link
      );
    if (otherPlatformList.length > 0 && myOther)
      formDataObj.other_platform_name = otherPlatformList.map(
        (item) => item.platform_name
      );

    if (facebook_platform) formDataObj.facebook_platform = facebook_platform;
    if (location_name) formDataObj.location_name = location_name;
    if (latitude) formDataObj.latitude = latitude;
    if (longitude) formDataObj.longitude = longitude;
    if (type) formDataObj.type = type;
    if (time_zone) formDataObj.time_zone = time_zone;
    // if (currency) formDataObj.currency = currency;
    // if (currency_vip) formDataObj.currency_vip = currency_vip;

    if (event_start_date) formDataObj.start_date = event_start_date;
    if (event_end_date) formDataObj.end_date = event_end_date;

    if (event_start_time) formDataObj.start_time = event_start_time;
    if (event_end_time) formDataObj.end_time = event_end_time;

    if (regular_ticket) formDataObj.regular_ticket = regular_ticket;

    if (regular_ticket_price) { console.log("regular price"); formDataObj.regular_ticket_price = regular_ticket_price; }
    if (vip_ticket_price) formDataObj.vip_ticket_price = vip_ticket_price;

    if (no_of_vip_ticket) formDataObj.no_of_vip_ticket = no_of_vip_ticket;
    if (no_of_regular_ticket)
      formDataObj.no_of_regular_ticket = no_of_regular_ticket;

    if (vip_ticket) formDataObj.vip_ticket = vip_ticket;

    if (recurring_type) formDataObj.recurring_type = recurring_type;
    if (venue_name) formDataObj.venue_name = venue_name;
    if (video_link) formDataObj.video = video_link;

    if (organization_name) formDataObj.organization_name = organization_name;
    if (hosted_by) formDataObj.hosted_by = hosted_by;
    if (link) formDataObj.link = link;
    if (tagline) formDataObj.tagline = tagline;

    if (hosted_by) formDataObj.hosted_by = hosted_by;
    if (email_address) formDataObj.email_address = email_address;
    if (primary_phone_number)
      formDataObj.primary_phone_number = primary_phone_number;
    if (secondary_phone_number)
      formDataObj.secondary_phone_number = secondary_phone_number;
    if (hide_primary_phone_number)
      formDataObj.hide_primary_phone_number = hide_primary_phone_number;
    if (hide_secondary_phone_number)
      formDataObj.hide_secondary_phone_number = hide_secondary_phone_number;

    if (isHideMobileNumberChecked)
      formDataObj.hide_my_phone = isHideMobileNumberChecked;
    if (isHideEmailAddressChecked)
      formDataObj.hide_my_secondary_phone = isHideEmailAddressChecked;

    const eventsFormData = new FormData();

    // alert(!((adsId || adsIds) && status === 'draft')?"true":"false")

    if ((adsId || adsIds) && status === 'draft') {
      // alert("infirsty")
      steps === 2 && selectedFiles.forEach((file, index) => {
        eventsFormData.append('photos', file);
      })
    }
    if (!((adsId || adsIds) && status === 'draft')) {
      // alert("insecond")
      steps === 2 && selectedFiles.forEach((file, index) => {
        eventsFormData.append('photos', file);
      })
    }
    if (status === 'active') {
      // alert("inthird")
      steps === 1 && selectedFiles.forEach((file, index) => {
        eventsFormData.append('photos', file);
      })
    }

    // {(((adsId || adsIds) || status === 'draft'&&steps === 2) || steps ===1) && selectedFiles.forEach((file, index) => {
    //   eventsFormData.append('photos', file);
    //       });}

    // alert(eventsFormData.photos);

    const data = formDataAppender(formDataObj, eventsFormData);

    for (const pair of eventsFormData.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }

    const updatedFetchConfig = fetchConfig;
    delete updatedFetchConfig.headers["Content-Type"];

    if (steps === 1 && !eventIds && !adsIds) {
      toggleLoader(true);
      // deleting content type json
      const leadCreationFetchConfig = JSON.parse(JSON.stringify(fetchConfig));


      delete leadCreationFetchConfig.headers["Content-Type"];

      fetch(`${appURL}/posts/events/new-event`, {
        ...leadCreationFetchConfig,
        body: data,
      })
        .then(handleFetchErrors)
        .then((res) => {
          console.log(res);
          toggleLoader(false);
          if (res?.status == 200) {
            console.log(res);
            const eventsObjectId = res?.postEventAdObjToSend?._id;

            updateSteps(steps + 1);
            updateEventIds(eventsObjectId);

            localStorage.setItem(
              "adsInfo",
              JSON.stringify({
                id: eventsObjectId,
                title: title,
                adsType: "events",
                adsCategories: adsCategoriesId,
              })
            );
          } else {
            return toast.error(res?.message);
          }
        })
        .catch((err) => {
          console.log(err);
          toggleLoader(false);
        });
    } else if ((steps === 2 || steps === 1) && eventIds || adsIds) {
      console.log(`jagdjahgdj`);
      toggleLoader(true);


      // deleting content type json
      const leadCreationFetchConfig = JSON.parse(JSON.stringify(fetchConfig));

      delete leadCreationFetchConfig.headers["Content-Type"];

      fetch(`${appURL}/posts/events/edit/${eventIds || adsIds}`, {
        ...leadCreationFetchConfig,
        method: "PATCH",
        body: data,
      })
        .then(handleFetchErrors)
        .then((res) => {
          // debugger
          console.log(res.updateEventAdObjToSend.advertisement_id, "my_new_id");

          localStorage.setItem(
            "advertisementId",
            res.updateEventAdObjToSend.advertisement_id
          );
          toggleLoader(false);
          if (res?.status === 200) {
            updateSteps(steps + 1);
          } else {
            return toast.error(res?.message);
          }
          console.log(`resresresres111`, res);
          console.log(
            "my end date",
            res.updateEventAdObjToSend.adsInfo.date_time.end_date
          );
          localStorage.setItem(
            "start_date",
            new Date(
              res.updateEventAdObjToSend.adsInfo.date_time.start_date
            ).toLocaleDateString("en-GB")
          );
          localStorage.setItem(
            "end_date",
            new Date(
              res.updateEventAdObjToSend.adsInfo.date_time.end_date
            ).toLocaleDateString("en-GB")
          );
        })
        .catch((err) => {
          toggleLoader(false);
          console.log(err, "mmkkkkkkkk");
          // debugger
        });
    }
  };

  // fetching ads categories
  const fetchingDynamicsData = () => {
    fetch(`${appURL}/posts/events/dynamics-data?PostType=${adsCategoriesId || "64e48f98f9eeb14b6e2135bc"}`, {
      ...fetchConfig,
      method: "GET",
    })
      .then(handleFetchErrors)
      .then((res) => {
        console.log(res, "data tobe dynamcics");

        if (res.status === 200) {
          const eventsDynamicDataObj = res?.data;
          if (eventsDynamicDataObj)
            console.log("eventsDynamicDataObj", eventsDynamicDataObj);
          updateEventsDynamicData(eventsDynamicDataObj);
          updateFormData({
            ...formData,
            currency_vip: eventsDynamicData?.currency?.[0] || "USD",
            currency: eventsDynamicData?.currency?.[0] || "USD",
          });
        } else {
          return toast.error(`Enable fetch dynamics data`);
        }
      })
      .catch((err) => standardFetchHandlers.error(err));
  };

  const backBtnClickHandler = () => {
    if (steps === 0) return toggleModalVisibilty("events", false);
    updateSteps(steps - 1);
  };

  // image upload handler
  const onClickHandlerForImageUpload = (id) => {
    let input = document.createElement("input");
    input.type = "file";
    input.multiple = true;
    input.accept = "image/*";

    input.onchange = (e) => {
      const imagesArr = e?.target?.files;

      if (imagesArr?.length) {
        const promises = Array.from(imagesArr).map((element) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = URL.createObjectURL(element);

            img.onload = () => {
              const width = img.width;
              const height = img.height;

              if (
                width === 305 &&
                height === 237 &&
                element.size <= 10 * 1024 * 1024
              ) {
                const encodedUrl = URL.createObjectURL(element);
                resolve(encodedUrl);
              } else {
                let message = ``;
                if (element.size >= 10 * 1024 * 1024)
                  message = `please provide image size less tha 10mb`;
                else message = `please provide image with demssion 33x32`;
                reject(new Error(message));
              }
            };

            img.onerror = () => {
              reject(new Error("Error loading image"));
            };
          });
        });

        Promise.all(promises)
          .then((imageURLArr) => {
            updateImage([...images, ...imageURLArr]);
          })
          .catch((error) => {
            toast.error(error.message);
            console.error(error);
          });
      }
    };

    input.click();
  };

  const handleFileChange = (event) => {
    console.log("called file change");
    const files = event.target.files;
    setSelectedFiles([...selectedFiles, ...files]);
  };

  // selecting location
  const callBackFunction = (address = "", lat = "", lng = "") => {
    updateFormData({
      ...formData,
      location_name: address,
      latitude: lat,
      longitude: lng,
    });
  };

  const dateFormat = "MM/DD/YYYY";

  const onCreateteHandler = (value) => {
    if (value && selectedTagline.length <= 4) {
      upatedSelectedTagline([...selectedTagline, value]);
    }
  };

  console.log(selectedTagline, "selectedTagline====77777")

  const remove = (tag) => {
    console.log(`kebdehfvhew`);
    console.log(tag);
  };

  const convertDateFormat = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1; // Months are 0-indexed, so we add 1
    const year = date.getUTCFullYear();
    return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
  };

  let startTime;
  let endTime;



  const fetchingAdsById = () => {
    let id = adsId || adsIds;
    fetch(`${appURL}/posts/events/ad_details?adsId=${id}`, {
      ...fetchConfig,
      method: "GET",
    })
      .then(handleFetchErrors)
      .then((res) => {
        console.log(`resres`, res);


        if (res?.status == 200) {
          seteventsDetailData(res?.ads_details);
          setmyadsId(res?.ads_details?._id);
          setmyadsType(res?.ads_details?.adsType);
          console.log("res id", res?.ads_details?.adsInfo?.image);
          // const images = res?.ads_details?.adsInfo?.image || [];
          // const halfLength = Math.ceil(images.length / 2); // Calculate the half length (rounding up)
          // const firstHalfImages = images.slice(0, halfLength); // Slice the array to get the first half
          setSelectedFiles(res?.ads_details?.adsInfo?.image);
          // setSelectedFiles(res?.ads_details?.adsInfo?.image);
          console.log("res?.data?.ads_details?.adsInfo", res?.ads_details?.adsInfo);
          convertTimeToMoment(res?.ads_details?.adsInfo?.date_time?.start_time);
          convertTimeToMomentEnd(res?.ads_details?.adsInfo?.date_time?.end_time);
          // convertToFullDate(res?.ads_details?.adsInfo?.date_time?.start_time, null);
          console.log("sourbh ticket price", res?.ads_details?.adsInfo?.ticket_price?.regular_ticket_price);
          if (res?.ads_details?.adsInfo?.ticket_price?.regular_ticket_price === undefined) {
            console.log("called");
            updateIsCheckedForFreeTicketPrice(true);
          }

          setadsDetails(res?.ads_details?.adsInfo);
          console.log("sourabh", res?.ads_details?.adsInfo?.date_time?.start_date.slice(0, 10));
          console.log("new sourabh", dayjs(ApiDateChange(res?.ads_details?.adsInfo?.date_time?.start_date.slice(0, 10))));
          setEventStartDate(dayjs(ApiDateChange(res?.ads_details?.adsInfo?.date_time?.start_date.slice(0, 10))));
          setEventEndDate(dayjs(ApiDateChange(res?.ads_details?.adsInfo?.date_time?.end_date.slice(0, 10))));
          const data = res?.ads_details,
            listerInfo = data?.lister_basic_info,
            adsInfo = data?.adsInfo;
            setTags(adsInfo?.tagline)
           const startDate = convertDateFormat(adsInfo?.date_time?.start_date)
          const endDate = convertDateFormat(adsInfo?.date_time?.end_date)

          // Set formatted dates into state or variables
          // setEventStartDate(startDate);
          // setEventEndDate(endDate);

          // console.log(startDate, "check the startDate");
          console.log("ticket_price", adsInfo?.ticket_price?.regular_ticket_price);
          const dataObj = {
            ...formData,
            type: adsInfo?.type || "",
            title: adsInfo?.title || "",
            category: adsInfo?.category?._id || "",
            descriptions: adsInfo?.descriptions || "",
            venue_name: adsInfo?.venue_name || "",
            recurring_type: adsInfo?.recurring_type || "",
            location: adsInfo?.location?.location_name || "",
            location_name: adsInfo?.location?.location_name || "",
            latitude: adsInfo?.location?.coordinates[1] || "",
            longitude: adsInfo?.location?.coordinates[0] || "",
            ticket_price: adsInfo?.ticket_price?.regular_ticket_price,
            regular_ticket_price: adsInfo?.ticket_price?.regular_ticket_price,
            vip_ticket_price: adsInfo?.ticket_price?.vip_ticket_price,
            no_of_regular_ticket: adsInfo?.no_of_ticket?.no_of_regular_ticket,
            no_of_vip_ticket: adsInfo?.no_of_ticket?.no_of_vip_ticket,
            currency: adsInfo?.ticket_price?.currency || "",
            currency_vip: adsInfo?.ticket_price?.currency || "",
            time_zone: adsInfo?.date_time?.time_zone || "",
            video_link: adsInfo?.video || "",
            event_start_time: adsInfo?.date_time?.start_time || "",
            event_end_time: adsInfo?.date_time?.end_time || "",

            event_start_date: startDate,
            event_end_date: endDate,
            // start_date:startDate,
            // // eventStartDate: formattedStartDate,
            // eventEndDate: endDate,
            // event_start_date:
            //   new Date(adsInfo?.date_time?.start_date)
            //     .toISOString()
            //     .substring(0, 10) || "",
            // event_end_date:
            //   new Date(adsInfo?.date_time?.end_date)
            //     .toISOString()
            //     .substring(0, 10) || "",
            tagline: adsInfo?.tagline || [],
            hosted_by: JSON.parse(localStorage.getItem(`user`))?.name,
            email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
            primary_phone_number: data?.lister_basic_info?.primary_mobile_number?.primary_phone_number ? data?.lister_basic_info?.primary_mobile_number?.primary_phone_number : JSON.parse(localStorage.getItem(`user`))?.phone_number,
            secondary_phone_number:
              listerInfo?.secondary_mobile_number?.secondary_phone_number ||
              null,
            hide_secondary_phone_number:
              listerInfo?.hide_my_secondary_phone || false,
            hide_primary_phone_number: listerInfo?.hide_my_phone || false,
            organization_name: listerInfo?.organization_name || "",
            email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
            adsType: data.adsType,
            status: data.status
          };

          updateFormData(dataObj);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const removeDuplicates = (imageArray) => {
    const uniqueUrls = new Set();
    return imageArray.filter(image => {
      if (uniqueUrls.has(image.url)) {
        return false;
      } else {
        uniqueUrls.add(image.url);
        return true;
      }
    });
  };


  function convertTimeToMoment(timeString) {
    // Extract hour, minute, and period (AM/PM)
    const [time, period] = timeString.split(' ');
    let [hour, minute] = time.split(':');

    // Convert to integers to remove leading zeros
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    // Convert to 24-hour format if PM and hour is not 12
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    }

    // Handle 12 AM case
    if (period === 'AM' && hour === 12) {
      hour = 0;
    }

    // Use moment.js to set the time
    startTime = moment().hour(hour).minute(minute);
    seteStartTime(moment().hour(hour).minute(minute));
    updateFormData({
      ...formData,
      event_start_time: moment().hour(hour).minute(minute),
    });
    console.log("event start time sourabh", moment().hour(hour).minute(minute));
  }

  function convertTimeToMomentEnd(timeString) {
    // Extract hour, minute, and period (AM/PM)
    const [time, period] = timeString.split(' ');
    let [hour, minute] = time.split(':');

    // Convert to integers to remove leading zeros
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);

    // Convert to 24-hour format if PM and hour is not 12
    if (period === 'PM' && hour !== 12) {
      hour += 12;
    }

    // Handle 12 AM case
    if (period === 'AM' && hour === 12) {
      hour = 0;
    }

    // Use moment.js to set the time
    startTime = moment().hour(hour).minute(minute);
    seteEndTime(moment().hour(hour).minute(minute));
    updateFormData({
      ...formData,
      event_end_time: moment().hour(hour).minute(minute),
    });
    console.log("event start time sourabh", moment().hour(hour).minute(minute));
  }

  function convertToFullDate(timeString, dateString) {
    // Ensure the input is a string and split time and period (AM/PM)
    let [time, period] = timeString.trim().split(' ');
    let [hours, minutes] = time.split(':').map(Number);

    // Convert to 24-hour format
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // Ensure hours and minutes are two digits
    let formattedHours = hours.toString().padStart(2, '0');
    let formattedMinutes = minutes.toString().padStart(2, '0');

    // Construct the time part in 24-hour format
    let formattedTime = `${formattedHours}:${formattedMinutes}:00`;

    // Use the provided date string or create a new date string
    let fullDateString = dateString || new Date().toDateString();

    // Construct the final result with the desired format
    let result = `${fullDateString} ${formattedTime} GMT+0530 (India Standard Time)`;
    updateFormData({
      ...formData,
      event_start_time: result,
    });
    console.log("result first", result);
  }


  const ApiDateChange = (date) => {
    console.log("date", date);
    const inputDateParts = date.split('-');
    const day = inputDateParts[2];
    let month = inputDateParts[1];
    const year = inputDateParts[0];
    if (month.toString().length === 1) { month = "0" + month }
    const outputDate = `${year}-${month}-${day}`;
    console.log("output date", outputDate);
    console.log("check date", outputDate === '2024-04-')
    return outputDate;
  }

  console.log(event_start_date, "event_end_date");
  console.log(event_end_date);

  useEffect(() => {
    fetchingDynamicsData();
    if (adsId || adsIds) fetchingAdsById();
  }, []);

  useEffect(() => {
    console.log("eStartTime", eStartTime);
  }, [eStartTime])

  const handleAddBtnClick = () => {
    toggleIsAddPlatFormOpen(!isAddPlatFormOpen);
    if (isAddPlatFormOpen) {
      updateSelectedPlatform([]);
    }
  };

  // const handleAddPlatformChange = (e) => {
  //     const value = e?.target?.value;

  //     const foundSelectedPlatform = selectedPlatform.find((platform) => platform.toLowerCase() === value.toLowerCase());

  //     // foundSelectedPlatform = platformListsArr.find((platformList) => platformList.toLowerCase() !== value.toLowerCase());

  //     if (!foundSelectedPlatform) {
  //         updateSelectedPlatform([
  //             ...selectedPlatform,
  //             value
  //         ])
  //         updateOtherPlatformList([{
  //             id: cuid(),
  //             platform_name: "",
  //             platform_link: ""
  //         }])

  //     }
  // }

  const handleAddPlatformChange = (e) => {
    const value = e?.target?.value;

    const foundSelectedPlatform = selectedPlatform.find(
      (platform) => platform.toLowerCase() === value.toLowerCase()
    );

    if (!foundSelectedPlatform) {
      updateSelectedPlatform([...selectedPlatform, value]);
      updateOtherPlatformList([
        {
          id: cuid(),
          platform_name: "",
          platform_link: "",
        },
      ]);

      setPlatformLinks((prevLinks) => ({
        ...prevLinks,
        [value.toLowerCase()]: "",
      }));

      //   e.target.value = "0";
    }

    //   if(foundSelectedPlatform){
    //     updateOtherPlatformList([
    //       {
    //         id: cuid(),
    //         platform_name: "Abhishek",
    //         platform_link: ""
    //       }
    //     ]);

    //     setPlatformLinks((prevLinks) => ({
    //       ...prevLinks,
    //       [value.toLowerCase()]: ""
    //     }));
    //   }
  };

  const handleClosePlatformInput = (platform) => {
    console.log(`filteredSelectedPlatform`, selectedPlatform);

    // console.log(platform)

    const filteredSelectedPlatform = selectedPlatform.filter(
      (data) => data !== platform
    );
    updateSelectedPlatform(filteredSelectedPlatform);
    console.log(`filteredSelectedPlatform`, filteredSelectedPlatform);
    if (filteredSelectedPlatform.length === 0) {
      toggleIsAddPlatFormOpen(false);
      // updateSelectedPlatform([]);
    }

    //     const updatedPlatformListsArr = platformListsArr.filter((platformItem) => {
    //     return platformItem.toLowerCase() !== platform.toLowerCase();
    //   });
    //   updatePlatformListsArr(updatedPlatformListsArr);

    switch (platform) {
      case "Facebook":
        updateFormData({
          ...formData,
          facebook_platform: "",
        });
        break;
      case "Instagram":
        updateFormData({
          ...formData,
          instagram_platform: "",
        });
        break;
      case "Tiktok":
        updateFormData({
          ...formData,
          tiktok_platform: "",
        });
        break;
      case "Zoom":
        updateFormData({
          ...formData,
          zoom_platform: "",
        });
        break;
      case "Youtube":
        updateFormData({
          ...formData,
          youtube_platform: "",
        });
        break;

      case "Microsoft Teams":
        updateFormData({
          ...formData,
          microsoft_teams_platform: "",
        });
        break;

      case "Google Meet":
        updateFormData({
          ...formData,
          google_meet_platform: "",
        });
        break;

      case "Other":
        updateFormData({
          ...formData,
          other_platform: "",
          other_platform_name: "",
        });
        break;

      default:
        break;
    }
  };

  // other platfrom

  const [otherPlatformList, updateOtherPlatformList] = useState([
    {
      id: cuid(),
      platform_name: "",
      platform_link: "",
    },
  ]);

  const handleAddRemoveNewPlatformFields = (operation, id) => {
    // operation
    // 1= add,
    //2= remove
    if (otherPlatformList?.length !== 0 && operation === 1) {
      const isDataIncomplete = otherPlatformList.some(
        (otherPlatform) =>
          !otherPlatform.platform_name || !otherPlatform.platform_link
      );

      const isDataInavlid = otherPlatformList.some(
        (otherPlatform) => !isValidURL(otherPlatform.platform_link)
      );

      let message = ``;

      if (isDataIncomplete) message = `platform name and link are required.`;
      else if (isDataInavlid) message = `please provide valid platform link`;

      if (isDataIncomplete || isDataInavlid) return toast.error(message);
    }

    switch (operation) {
      case 1:
        updateOtherPlatformList([
          ...otherPlatformList,
          {
            id: cuid(),
            platform_name: "",
            platform_link: "",
          },
        ]);
        break;

      case 2:
        const filterResult = otherPlatformList.filter(
          (otherPlatform) =>
            otherPlatform?.id.trim().toLowerCase() !== id.trim().toLowerCase()
        );
        updateOtherPlatformList(filterResult);
        break;

      default:
        break;
    }
  };

  const handleChange1 = (e, id, fieldType) => {
    const index = otherPlatformList.findIndex((item) => item.id === id),
      inputValue = e?.target.value;

    if (index !== -1) {
      const updatedList = [...otherPlatformList];

      if (fieldType === 1) {
        updatedList[index] = {
          ...updatedList[index],
          platform_name: inputValue,
          // platform_link: fieldType === 2 ? e.target.value :`` ,
        };
      } else if (fieldType === 2) {
        updatedList[index] = {
          ...updatedList[index],

          platform_link: inputValue,
        };
      }

      updateOtherPlatformList(updatedList);
      setMyOther(true);
    }
  };

  const handlingClearSelectInput = (name) => {
    switch (name) {
      case "recurring_type":
        updateFormData({
          ...formData,
          recurring_type: ``,
        });
        break;

      default:
        break;
    }
  };

  let HeadingMessage = `Post your Ad`;
  let SubHeadingMessage = ``;

  // alert(`Steps${steps}`)

  if (steps === 2) {
    HeadingMessage = `Post your Ad`;
  } else if (steps === 3) {
    HeadingMessage = `Post your Ad`;
  } else if (steps === 1) {
    SubHeadingMessage = `Basic Information`;
  }

  console.log(formData, "fetchingAdsById");
  console.log(formData.title, "formdata Title");
  console.log(title, "title 1");

  const abcfunction = (data) => {
    setlat(data.lat);
    setlng(data.lng);

    console.log(data.lat, data.lng, "Nothing");
  };
  return (
    <main className={style.main_wrapper}>
      <PostAdsHeading
        headingText={
          steps === 2 && status === 'active' ? 'Update Your Ad' : HeadingMessage
        }
        subHeadingText={SubHeadingMessage}
      />


      <form id={steps === 3 ? undefined : style.room_rents_form}>
        {(() => {
          if (steps === 0) {
            return (
              <>
                <Inputs
                  label="Event type"
                  type="radio"
                  required={true}
                  name="type"
                  id="span-2"
                  value={adsDetails?.type ? adsDetails?.type : type}
                  // disabled={adsIds ? true : false}
                  // disabled={status !== "draft" && (adsIds ? true : false)}
                  onChangeHandler={onChangeHandlerForForm}
                  placeholder="Select event type"
                  radioBtnOtions={eventsDynamicData?.type}
                />

                <Inputs
                  label="Event category"
                  required={true}
                  type="select"
                  id="span-2"
                  name="category"
                  value={category}
                  disabled={status !== "draft" && (adsIds ? true : false)}
                  onChangeHandler={onChangeHandlerForForm}
                  placeholder="Select event category"
                  selectInputOptions={eventsDynamicData?.category}
                />

                <Inputs
                  label="Event title"
                  required={true}
                  type="text"
                  id="span-2"
                  name="title"
                  value={title}
                  onChangeHandler={onChangeHandlerForForm}
                  placeholder="Enter event title"
                />

                <section id="span-2">

                  <section id="span-2">
                    <div>
                      <label className="tagline-main">Tagline (optional)</label>
                      <div>
                        <input
                          type="text"
                          name="tagline"
                          value={inputValue}
                          onChange={handleTagInputChange}
                          onKeyPress={handleKeyPress}
                          placeholder="Enter tagline"
                        />
                        <button onClick={handleAddTag} style={{ display: "none" }} >Add Tag</button>
                      </div>

                      {/* Display tags */}
                      <div>
                        {tags.length  > 0 && (
                          <div>
                            {tags.map((tag, index) => (
                              <span key={index} style={{ margin: "5px", padding: "5px", border: "1px solid #ccc", display: "inline-block" }}>
                                {tag}
                                <button
                                  onClick={() => handleRemoveTag(tag)}
                                  style={{ marginLeft: "5px", cursor: "pointer", background: "red", color: "white", border: "none", borderRadius: "50%", width: "20px", height: "20px" }}
                                >
                                  ×
                                </button>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>

                      {/* Display message if the limit is exceeded */}
                      {inputValue.length > 25 && <p style={{ color: 'red' }}>Tag must be less than 25 characters</p>}
                    </div>

                  </section>


                  {/* <MultiSelect
                    label="Tagline"
                    id="tagline"
                    value={selectedTagline}
                    allowCreate={selectedTagline.length <= 10}
                    onCreate={addTaglinesHandler}
                    name="tagline"
                    data={["Red", "Yellow", "Blue", "Orange"]}
                    dataKey="short_id"
                    textField="name_email"
                    placeholder="Enter tagline"
                    handleTagRemove={remove}
                    onChange={(admin) => {
                      console.log(admin, "tagline======222222");
                      // Ensure the total length of taglines is <= 10 and each tagline is <= 25 characters
                      if (admin.every(tag => tag.length <= 25)) {
                        console.log("tagline.admin")
                        addTaglinesHandler(admin);
                      }
                    }}
                  /> */}

                </section>


                <RichTextEditor
                  value={descriptions}
                  updateFormData={updateFormData}
                  formData={formData}
                  label="Description"
                  required={true}
                />


                <Inputs
                  name="venue_name"
                  required={false}
                  value={venue_name}
                  // onChangeHandler={onChangeHandlerForForm}
                  onChangeHandler={(e) => {
                    if (e.target.value.length <= 70) {
                      onChangeHandlerForForm(e);
                    }
                  }}
                  label="Venue name"
                  type="text"
                  id="span-2"
                  placeholder="Enter Venue name"
                  maxLength={70}
                />

                <SelectLocation
                  callBackFunction={callBackFunction}
                  // location={location_nam
                  location={location_name}
                  latitude={latitude}
                  longitude={longitude}
                  // abc={abcfunction}
                  label="Location"
                  required={true}
                  locationResponseData={locationResponseData}
                  setLocationResponseData={setLocationResponseData}

                />

                {type !== "Venue Based Event" && (
                  <div id="span-2">
                    <div className={style.addbtn_label_section}>
                      <span id="label">
                        Select Platform to add link
                        <span className="optional_text">(Optional)</span>
                      </span>
                      {/* <p onClick={handleAddBtnClick} className={style.add_btn_text}>{isAddPlatFormOpen ? <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="5" y1="12" x2="19" y2="12"></line></svg> : <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>}</p> */}
                    </div>
                    <Inputs
                      required={true}
                      type="select"
                      id="span-2"
                      value="zero"
                      name="select_platform"
                      placeholder="Select platform to enter link"
                      onChangeHandler={handleAddPlatformChange}
                      selectInputOptions={platformListsArr}
                    />

                    {selectedPlatform?.length
                      ? selectedPlatform.map((platform, i) => {
                        return (() => {
                          if (platform.toLowerCase() == "instagram") {
                            return (
                              <section
                                className={style.platform_link_input_field}
                              >
                                <Inputs
                                  key={i}
                                  name="instagram_platform"
                                  required={true}
                                  value={instagram_platform}
                                  onChangeHandler={onChangeHandlerForForm}
                                  type="text"
                                  placeholder={`Enter ${platform} link`}
                                />

                                <div
                                  onClick={() =>
                                    handleClosePlatformInput(platform)
                                  }
                                  className={
                                    style.remove_platform_link_input_btn
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FD7E14"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="bevel"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="9"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="9"
                                      y1="9"
                                      x2="15"
                                      y2="15"
                                    ></line>
                                  </svg>
                                </div>
                              </section>
                            );
                          }

                          if (platform.toLowerCase() == "microsoft teams") {
                            return (
                              <section
                                className={style.platform_link_input_field}
                              >
                                <Inputs
                                  key={i}
                                  name="microsoft_teams_platform"
                                  required={true}
                                  value={microsoft_teams_platform}
                                  onChangeHandler={onChangeHandlerForForm}
                                  type="text"
                                  placeholder={`Enter ${platform} link`}
                                />

                                <div
                                  onClick={() =>
                                    handleClosePlatformInput(platform)
                                  }
                                  className={
                                    style.remove_platform_link_input_btn
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FD7E14"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="bevel"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="9"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="9"
                                      y1="9"
                                      x2="15"
                                      y2="15"
                                    ></line>
                                  </svg>
                                </div>
                              </section>
                            );
                          }

                          if (platform.toLowerCase() == "google meet") {
                            const showInput = google_meet_platform !== "";

                            return (
                              <section
                                className={style.platform_link_input_field}
                              >
                                <Inputs
                                  key={i}
                                  name="google_meet_platform"
                                  required={true}
                                  value={google_meet_platform}
                                  onChangeHandler={onChangeHandlerForForm}
                                  type="text"
                                  placeholder={`Enter ${platform} link`}
                                />

                                <div
                                  onClick={() =>
                                    handleClosePlatformInput(platform)
                                  }
                                  className={
                                    style.remove_platform_link_input_btn
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FD7E14"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="bevel"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="9"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="9"
                                      y1="9"
                                      x2="15"
                                      y2="15"
                                    ></line>
                                  </svg>
                                </div>
                              </section>
                            );
                          }

                          if (platform.toLowerCase() == "facebook") {
                            return (
                              <section
                                className={style.platform_link_input_field}
                              >
                                <Inputs
                                  key={i}
                                  name="facebook_platform"
                                  required={true}
                                  value={facebook_platform}
                                  onChangeHandler={onChangeHandlerForForm}
                                  type="text"
                                  placeholder={`Enter ${platform} link`}
                                />
                                <div
                                  onClick={() =>
                                    handleClosePlatformInput(platform)
                                  }
                                  className={
                                    style.remove_platform_link_input_btn
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FD7E14"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="bevel"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="9"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="9"
                                      y1="9"
                                      x2="15"
                                      y2="15"
                                    ></line>
                                  </svg>
                                </div>
                              </section>
                            );
                          }

                          if (platform.toLowerCase() == "tiktok") {
                            return (
                              <section
                                className={style.platform_link_input_field}
                              >
                                <Inputs
                                  key={i}
                                  name="tiktok_platform"
                                  required={true}
                                  value={tiktok_platform}
                                  onChangeHandler={onChangeHandlerForForm}
                                  placeholder={`Enter ${platform} link`}
                                  type="text"
                                />
                                <div
                                  onClick={() =>
                                    handleClosePlatformInput(platform)
                                  }
                                  className={
                                    style.remove_platform_link_input_btn
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FD7E14"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="bevel"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="9"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="9"
                                      y1="9"
                                      x2="15"
                                      y2="15"
                                    ></line>
                                  </svg>
                                </div>
                              </section>
                            );
                          }

                          if (platform.toLowerCase() == "zoom") {
                            return (
                              <section
                                className={style.platform_link_input_field}
                              >
                                <Inputs
                                  key={i}
                                  name="zoom_platform"
                                  required={true}
                                  value={zoom_platform}
                                  onChangeHandler={onChangeHandlerForForm}
                                  // label={platform}
                                  type="text"
                                  placeholder={`Enter ${platform} link`}
                                />
                                <div
                                  onClick={() =>
                                    handleClosePlatformInput(platform)
                                  }
                                  className={
                                    style.remove_platform_link_input_btn
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FD7E14"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="bevel"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="9"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="9"
                                      y1="9"
                                      x2="15"
                                      y2="15"
                                    ></line>
                                  </svg>
                                </div>
                              </section>
                            );
                          }

                          if (platform.toLowerCase() == "youtube") {
                            return (
                              <section
                                className={style.platform_link_input_field}
                              >
                                <Inputs
                                  key={i}
                                  name="youtube_platform"
                                  required={true}
                                  value={youtube_platform}
                                  onChangeHandler={onChangeHandlerForForm}
                                  // label={platform}
                                  type="text"
                                  placeholder={`Enter ${platform} link`}
                                />
                                <div
                                  onClick={() =>
                                    handleClosePlatformInput(platform)
                                  }
                                  className={
                                    style.remove_platform_link_input_btn
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="23"
                                    height="23"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#FD7E14"
                                    strokeWidth="2"
                                    strokeLinecap="square"
                                    strokeLinejoin="bevel"
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line
                                      x1="15"
                                      y1="9"
                                      x2="9"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="9"
                                      y1="9"
                                      x2="15"
                                      y2="15"
                                    ></line>
                                  </svg>
                                </div>
                              </section>
                            );
                          }

                          if (platform.toLowerCase() == "other") {
                            return (
                              <div
                                className={
                                  style.other_platform_section_wrapper
                                }
                              >
                                <div className={style.addbtn_label_section}>
                                  <span id="label">
                                    Add other platform
                                    <span className="required_field">*</span>
                                  </span>
                                  {otherPlatformList.length <= 4 && (
                                    <p
                                      onClick={() => {
                                        handleAddRemoveNewPlatformFields(
                                          1,
                                          null
                                        );
                                      }}
                                      className={style.add_btn_text}
                                    >
                                      Add
                                    </p>
                                  )}
                                </div>

                                {otherPlatformList.length
                                  ? otherPlatformList.map(
                                    (otherPatform, i) => {
                                      return (
                                        <section
                                          id="span-2"
                                          className={
                                            style.other_platform_input_field_wrapper
                                          }
                                        >
                                          <span
                                            onClick={() => {
                                              handleAddRemoveNewPlatformFields(
                                                2,
                                                otherPatform?.id
                                              );
                                            }}
                                            className={style.close_icons}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="23"
                                              height="23"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke="#FD7E14"
                                              strokeWidth="2"
                                              strokeLinecap="square"
                                              strokeLinejoin="bevel"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                              ></circle>
                                              <line
                                                x1="15"
                                                y1="9"
                                                x2="9"
                                                y2="15"
                                              ></line>
                                              <line
                                                x1="9"
                                                y1="9"
                                                x2="15"
                                                y2="15"
                                              ></line>
                                            </svg>
                                          </span>
                                          <div
                                            key={i}
                                            className={
                                              style.other_field_input
                                            }
                                            id="span-2"
                                          >
                                            <input
                                              type="text"
                                              name={otherPatform?.id}
                                              required={true}
                                              onChange={(e) => {
                                                handleChange1(
                                                  e,
                                                  otherPatform?.id,
                                                  1
                                                );
                                              }}
                                              value={
                                                otherPatform?.platform_name
                                              }
                                              placeholder={`Enter platform`}
                                            />

                                            <input
                                              type="text"
                                              name={otherPatform?.id}
                                              required={true}
                                              onChange={(e) => {
                                                handleChange1(
                                                  e,
                                                  otherPatform?.id,
                                                  2
                                                );
                                              }}
                                              value={
                                                otherPatform?.platform_link
                                              }
                                              placeholder={`Enter platform link`}
                                            />
                                          </div>
                                        </section>
                                      );
                                    }
                                  )
                                  : ``}
                              </div>
                            );
                          }
                        })();
                      })
                      : ``}
                  </div>
                )}

                {/* <input type="file" name='photos' onChange={handleFileChange} multiple/> */}


                <ImageUpload images={images} updateImage={updateImage} handleFileChange={handleFileChange} setSelectedFiles={setSelectedFiles}
                  myadsId={myadsId} myadsType={myadsType} selectedFiles={selectedFiles} />
              </>
            );
          } else if (steps === 1) {
            return (
              <>
                <div>
                  <Space direction="vertical">
                    <span id="label">
                      Event start date<span className="required_field">*</span>{" "}
                    </span>
                    <DatePicker
                      defaultValue={
                        eventStartDate
                          ? dayjs(eventStartDate, "MM/DD/YYYY").toDate()
                          : null
                      }
                      disabledDate={(current) => disabledStartDate(current)}
                      className={style.date_picker_wrapper}
                      placeholder="Event start date"
                      onChange={(date, dateString) =>
                        onChangeForDate(date, dateString, 1)
                      }
                      format={dateFormat}
                      value={eventStartDate}
                      allowClear={false}
                      inputReadOnly={false}
                      style={{ borderColor: `${forEventDate === true ? 'red' : 'lightgray'}` }}
                    />
                  </Space>
                </div>
                <div>
                  <Space direction="vertical">
                    <span id="label">
                      Event end date<span className="required_field">*</span>{" "}
                    </span>
                    <DatePicker
                      defaultValue={
                        eventEndDate
                          ? dayjs(eventEndDate, "MM/DD/YYYY").toDate()
                          : null
                      }
                      // value={eventEndDate ? moment(eventEndDate, "MM/DD/YYYY") : null}
                      disabledDate={(current) => disabledEndDate(current)}
                      className={style.date_picker_wrapper}
                      placeholder="Event end date"
                      onChange={(date, dateString) =>
                        onChangeForDate(date, dateString, 2)
                      }
                      format={dateFormat}
                      value={eventEndDate}
                      allowClear={false}
                      inputReadOnly={false}
                      style={{ borderColor: `${forEventDate ? 'red' : 'lightgray'}` }}
                    />
                  </Space>
                </div>

                <section id="span-2">
                  <div className={style.event_date_time_wrapper}>
                    <Space direction="vertical">
                      <span id="label">
                        Event start time
                        <span className="required_field">*</span>{" "}
                      </span>
                      {/* <div className="time-picker">
      <div className="scroll-container">
        <select value={hours} onChange={handleHourChange} style={{padding:0,backgroundColor:'transparent',border:'none',width:'60px'}}>
          {[...Array(12).keys()].map((hour) => (
            <option key={hour + 1} value={hour + 1}>
              {hour + 1}
            </option>
          ))}
        </select>
        <span>:</span>
        <select value={minutes} onChange={handleMinuteChange} style={{padding:0,backgroundColor:'transparent',border:'none',width:'60px'}}>
          {[...Array(60).keys()].map((minute) => (
            <option key={minute} value={minute}>
              {minute < 10 ? `0${minute}` : minute}
            </option>
          ))}
        </select>
        <select value={amPm} onChange={handleAmPmChange} style={{padding:0,backgroundColor:'transparent',border:'none',width:'60px'}}>
          <option value="AM">AM</option>
          <option value="PM">PM</option>
        </select>
      </div>
    </div> */}
                      <TimePicker
                        showSecond={false}
                        className="xxx"
                        defaultValue={eStartTime}
                        use12Hours
                        inputReadOnly
                        onChange={(item) => { console.log("time changed", item?._d); convertTime(item?._d) }}
                      />
                    </Space>
                    <Space direction="vertical">
                      <span id="label">
                        Event end time<span className="required_field">*</span>{" "}
                      </span>
                      <TimePicker
                        showSecond={false}
                        className="xxx"
                        defaultValue={eEndTime}
                        use12Hours
                        inputReadOnly
                        onChange={(item) => { console.log("time changed", item?._d); convertTimeEnd(item?._d) }}
                      />
                    </Space>
                  </div>
                </section>

                <Inputs
                  label="Timezone"
                  type="select"
                  required={true}
                  name="time_zone"
                  value={time_zone}
                  onChangeHandler={onChangeHandlerForForm}
                  placeholder="Select timezone for event"
                  selectInputOptions={eventsDynamicData?.time_zone}
                />

                <Inputs
                  name="recurring_type"
                  handlingClearSelectInput={handlingClearSelectInput}
                  value={recurring_type}
                  onChangeHandler={onChangeHandlerForForm}
                  label="Recurring event"
                  type="select2"
                  placeholder="Select Recurring Event"
                  selectInputOptions={eventsDynamicData?.recurring_type}
                />

                <div id="span-2">
                  <div className={style.regular_price_wrapper}>
                    <span>
                      {" "}
                      Ticket Price (Optional)
                    </span>

                    <div
                      className={style.free_regular_ticket_price_input_wrapper}
                    >
                      <input
                        checked={isCheckedForFreeTicketPrice}
                        name="is_regular_price_free"
                        onChange={handleOnChangeFreeRegularFreePrice}
                        className={style.free_regular_ticket_price_input}
                        type="checkbox"
                        id="vehicle1"
                      />
                      <label for="vehicle1">Free</label>
                      <br />
                    </div>
                  </div>
                  {!isCheckedForFreeTicketPrice && (
                    <div className={style.expected_salary_input_wrapper}>
                      <section>
                        <Inputs
                          inputId={style.currency_input_field}
                          type="select"
                          name="currency"
                          value={currency}
                          onChangeHandler={onChangeHandlerForForm}
                          selectInputOptions={eventsDynamicData?.currency}
                        />
                      </section>
                      <section>
                        <Inputs
                          inputId={style.price_input_field}
                          name="regular_ticket_price"
                          value={regular_ticket_price > 1 ? regular_ticket_price : ""}
                          onChangeHandler={onChangeHandlerForForm}
                          type="number"
                          id="span-2"
                          placeholder="Enter ticket price"
                        />

                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "10px",
                          }}
                        >
                          {errorMessage}
                        </div>
                      </section>
                    </div>
                  )}
                </div>

                <Inputs
                  name="no_of_regular_ticket"
                  value={no_of_regular_ticket}
                  onChangeHandler={onChangeHandlerForForm}
                  label="Number of regular tickets available"
                  type="number"
                  id="span-2"
                  placeholder="Enter number of regular tickets available"
                />

                {/* <section id='span-2'>

                                        <div className={style.regular_price_wrapper}>
                                            <span>VIP Ticket Price</span>

                                        </div>
                                      
                                        


                                    </section> */}

                {type !== "Live Event" && (
                  <>
                    <div id="span-2">
                      <span id="label">
                        VIP Ticket Price
                        <span className="optional_text">(Optional)</span>
                      </span>

                      <div className={style.expected_salary_input_wrapper}>
                        <section>
                          <Inputs
                            inputId={style.currency_input_field}
                            type="select"
                            name="currency_vip"
                            value={currency_vip}
                            onChangeHandler={onChangeHandlerForForm}
                            selectInputOptions={eventsDynamicData?.currency}
                          />
                        </section>
                        <section>
                          <Inputs
                            inputId={style.price_input_field}
                            name="vip_ticket_price"
                            value={vip_ticket_price}
                            onChangeHandler={onChangeHandlerForForm}
                            type="number"
                            id="span-2"
                            placeholder="Enter ticket price"
                          />

                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              marginTop: "10px",
                            }}
                          >
                            {viperrorMessage}
                          </div>
                        </section>
                      </div>
                    </div>

                    <Inputs
                      name="no_of_vip_ticket"
                      value={no_of_vip_ticket}
                      onChangeHandler={onChangeHandlerForForm}
                      label="Number of VIP tickets available"
                      type="number"
                      id="span-2"
                      placeholder="Enter number of VIP tickets available"
                    />
                  </>
                )}

                <Inputs
                  label="Youtube video link"
                  id="span-2"
                  type="text"
                  name="video_link"
                  value={video_link}
                  onChangeHandler={onChangeHandlerForForm}
                  placeholder="https://www.youtube.com/"
                />
              </>
            );
          } else if (steps === 2) {
            return (
              <AdsListerInfo
                onChangeHandler={onChangeHandlerForForm}
                formData={formData}
                postType="event"
                isHideMobileNumberChecked={isHideMobileNumberChecked}
                isHideEmailAddressChecked={isHideEmailAddressChecked}
                isHideEmailAddress1Checked={isHideEmailAddress1Checked}
                hideEmailNumberSwitchButtonHandler={
                  hideEmailNumberSwitchButtonHandler
                }
              />
            );
          }
          else if (steps === 3 && !adsIds) {
            return (
              <AdsPlansUpdated
                updateSteps={updateSteps}
                steps={steps}
                adsId={eventIds}
                adsCategoriesId={adsCategoriesId}
              />
            );
          }

          else if (steps === 3 && adsIds && status != "active") {
            return (
              <AdsPlansUpdated
                updateSteps={updateSteps}
                steps={steps}
                adsId={eventIds}
                adsCategoriesId={adsCategoriesId || adsType}
                adsIds={adsIds}
              />
            );
          }

          else if (steps === 4) {
            return <CongratulationsScreen />;
          }
        })()}
        {steps !== 4 && steps !== 3 && (
          <FormButton
            cancelButtonText="Back"
            submitBtnText={(steps === 2 && status === 'active') ? "Update My Ad" :
              (steps === 3 && status === 'draft') ? "Post My Ad" :
                "Next"}
            id="span-2"
            className={style.form_button_section}
            backBtnClickHandler={backBtnClickHandler}
            onClickHandler={handleFormSubmit}
            showLoadder={showLoader}
            status={status}
            steps={steps}
          />
        )}
      </form>
    </main>
  );
}
