import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import CommonModalWrapper from '../../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../../Icons/CloseIcon';
import AddonExampleCard from '../addon-example-card/AddonExampleCard';
import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import LoaderScreen from '../loader-screen/LoaderScreen';
import CheckoutButton from '../../buttons/checkout-button/CheckoutButton';
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';
import Inputs from '../../Inputs/Inputs';
import appURLs from '../../../appURL';
import style from './adsPlans.module.css';

const formatDateFun = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${month} ${day} ${year}`;

  return formattedDate;
}

function addDaysToCurrentDate(days) {
  const currentDate = new Date();
  const newDate = new Date(currentDate.setDate(currentDate.getDate() + days));

  return formatDateFun(newDate);
}

function convertToMonth(days) {
  if (days === 30) return '1';
  else if (days === 90) return '3';
  else return '0';
}

export default function AdsPlansUpdated({ updateSteps, steps, adsId, adsCategoriesId, showAdsPlan, setShowAdsPlan, category, setCongratsClose, congratsClose }) {

  const fetchConfig = getFetchConfig(),
    appURL = appURLs();

  const [planData, updatePlanData] = useState([]), //all fetched data from all plans
    [selectedPlanId, setSelectedPlanId] = useState(""),
    [addOnLists, updateAddOnLists] = useState([]),
    [selectedExampleData, updateSelectedExampleData] = useState({}),
    [isModalOpenForExample, toggleExampleModal] = useState(false),
    [selectedPlan, updateSelectedPlan] = useState({}),
    [adExpiry, setAdExpiry] = useState(""),
    [showCongratulations, setShowCongratulations] = useState(false),
    [showLoader, toggleShowLoader] = useState(false),
    [isButtonDisabled, setIsButtonDisabled] = useState(false),
    [showLinkInput, setShowLinkInput] = useState(false),
    [linkToYourWeb, setLinkToYourWeb] = useState(false),
    [addOnsDetails, setAddOnsDetails] = useState([]),
    [totalPrice, setTotalPrice] = useState(0),
    [fetchedPriceId, setFetchedPriceId] = useState([]),
    [isLocal, setIsLocal] = useState(false),
    [selectedDuration, setSelectedDuration] = useState({
      adId: "",
      priceId: "",
      duration: 30,
    });


  const [websiteUrl, setWebsiteURL] = useState("");
  const [myads_id, setmyads_id] = useState('');


  console.log(planData,"planDataaaaaaa")

  useEffect(() => {
    if (planData && planData.length > 0) {
      const initialPlan = planData[0]; // Get the first plan if available
      let priceId = ""; // Default value for priceId

      // Assign priceId based on the duration
      switch (initialPlan.duration) {
        case 30:
          priceId = "64e4db3ef9eeb14b6e2185c8"; // 30 days
          break;
        case 90:
          priceId = "64e4db08f9eeb14b6e2185c4"; // 90 days
          break;
        case 0:
          priceId = "64e4da83f9eeb14b6e2185bc"; // permanent
          break;
        default:
          priceId = ""; // Default case if duration doesn't match
      }

      setSelectedDuration({
        adId: initialPlan._id,
        priceId: priceId,
        duration: initialPlan.duration,
      });
    }
  }, [planData]);

  const isLocalValue = localStorage.getItem("adsCategories"); // Retrieve the value directly
  const editAdId = localStorage.getItem("new_my_id")

  useEffect(() => {
    if (isLocalValue.includes("Local")) {
      setIsLocal(true);
    }
  }, [isLocalValue]); // Add isLocalValue as a dependency

  console.log(isLocal, "isLocalValueee")

  const fetchPlanData = () => {
    toggleShowLoader(true);

    fetch(`${appURL}/posts/ads/fetch_plan?ads_type=${adsCategoriesId}`, {
      ...fetchConfig,
      method: "GET",
    })
      .then(handleFetchErrors)
      .then((res) => {
        toggleShowLoader(false);

        if (res.status === 200 && res?.data?.length) {
          const adsPlansData = res.data[0]; // Ensure adsPlansData is assigned correctly

          if (adsPlansData?.add_ons?.length) {
            // Filter add-ons with the name "Link to your website"
            const filteredAddOns = adsPlansData.add_ons.filter(
              (addon) => addon.name === "Link to your website"
            );

            // Update state if there are filtered add-ons
            if (filteredAddOns.length) {
              updateSelectedPlan(filteredAddOns[0]); // Corrected function name
              updatePlanData(res.data);
            }
          }
        } else {
          toast.error("Something went wrong"); // Ensure meaningful error feedback
        }
      })
      .catch((err) => {
        toggleShowLoader(false);
        console.error("Error fetching plan data:", err); // Log error for debugging
        toast.error("Failed to fetch plan data. Please try again.");
      });
  };

  const backBtnClickHandler = () => {
    updateSteps(steps - 1)
  }

  console.log("selectedPlan:", selectedPlan, "totalPrice:", totalPrice, "fetchedPriceId:", fetchedPriceId, "addOnsDetails:", addOnsDetails, "addOnLists:", addOnLists, "addOnListsssssssss");

  function calculateDuration(days) {
    const daysInMonth = 30;
    const daysInWeek = 7;
    const months = Math.floor(days / daysInMonth);
    const remainingDays = days % daysInMonth;
    const weeks = Math.floor(remainingDays / daysInWeek);
    const remainingDaysInWeek = remainingDays % daysInWeek;

    if (months !== 0 && weeks !== 0 && remainingDaysInWeek !== 0) {
      return `${months * daysInMonth + weeks * daysInWeek + remainingDaysInWeek} days`;
    } else if (months !== 0 && weeks !== 0) {
      return `${months * daysInMonth + weeks * daysInWeek} days`;

    } else if (months !== 0) {
      const monthValue = months === 1 ? "month" : "months";
      return `${months} ${monthValue}`;
    } else if (weeks !== 0 && remainingDaysInWeek !== 0) {
      return `${weeks * daysInWeek + remainingDaysInWeek} days`;
    } else if (weeks !== 0) {
      const weeksValue = weeks === 1 ? "week" : "weeks";
      return `${weeks} ${weeksValue}`;
    } else {
      return `${remainingDaysInWeek} days`;
    }
  }

  const planRadioBtnOption = useMemo(() => {
    return planData.map(plan => ({
      label: calculateDuration(plan?.duration) !== '0 days' ? calculateDuration(plan?.duration) : 'Permanent',
      value: String(plan?._id), // Ensure the _id is used as the value for the radio button
    }));
  }, [planData]);
  useEffect(() => {
    if (adExpiry === "lifetime") {
      setAdExpiry("lifetime");
    } else {
      const date = new Date(adExpiry);
      const formattedDate = date.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
      setAdExpiry(formattedDate);
    }
  }, [adExpiry]);


  useEffect(() => {
    fetchPlanData()
  }, [myads_id, adsCategoriesId])

  const handleExampleModal = () => {
    updateSelectedExampleData({
      title: selectedPlan?.example_title,
      description: selectedPlan?.example_description,
      image: selectedPlan?.example_image
    })
    toggleExampleModal(true)
  }

  const dateFormat = 'DD/MM/YYYY';
  const startDate = localStorage.getItem('start_date');
  const endDate = localStorage.getItem('end_date');

  const start = dayjs(startDate, dateFormat).toDate();
  const end = dayjs(endDate, dateFormat).toDate();
  const dateDiff = Math.floor((end - start) / (1000 * 60 * 60 * 24));

  let expirationDate;

  if (dateDiff > 30) {
    expirationDate = end.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  } else {
    const expirationDay = end.getDate() + 1;
    const expirationMonth = end.getMonth() + 1;
    const expirationYear = end.getFullYear();
    expirationDate = new Date(expirationYear, expirationMonth - 1, expirationDay).toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }

  const updatedFetchConfig = fetchConfig;
  updatedFetchConfig.headers['Content-Type'] = 'application/json';


  const handleSubmission = () => {
    toggleShowLoader(true);
    try {
      // Validate inputs
      if (linkToYourWeb && !websiteUrl) {
        toggleShowLoader(false);
        return toast.error("Please provide website link");
      }

      // Construct the payload
      const payload = {
        postId: adsId || editAdId, //edit api hit id 
        // planId: selectedPlan.plan_id,
        planId: selectedDuration.adId, //
        // add_ons: linkToYourWeb ? [selectedDuration.priceId] : [], // Month ID linked to your website
        add_ons: linkToYourWeb ? [selectedPlan?.price[0]?._id] : [], 
        // price_drop: 0,
        ...(websiteUrl && { website_url: websiteUrl }), // Include websiteUrl only if not empty
        isPromoted: false,
        redirect_uri_success: "http://localhost:3005/success",
        //redirect_uri_success: "https://menehariya.netscapelabs.com/success",
      };

      fetch(`${appURL}/posts/payment/create-payment-intent`, {
        ...updatedFetchConfig,
        body: JSON.stringify(payload),
      })
        .then(handleFetchErrors)
        .then((res) => {
          toggleShowLoader(false);
          // Handle the response
          if (res.status === 201) {
            // setShowCongratulations(true);
            // setCongratsClose(true);
            updateSteps(steps + 1);
          } else if (res.paymentIntent !== null) {
            window.location.href = res.paymentIntent;
          }
        })
        .catch((err) => {
          console.error("Error during submission:", err);
          toggleShowLoader(false);
          toast.error("Something went wrong. Please try again.");
        });
    } catch (err) {
      console.error("Unexpected error:", err);
      toggleShowLoader(false);
      toast.error("Something went wrong. Please try again.");
    }
  };

  if (showLoader) {
    return <LoaderScreen />
  }

  // Plan change handler
  const planChangeHandler = (event) => {
    const selectedPlanId = event.target.value; // Get the value of the selected radio button

    // Find the selected plan from planData based on the selected plan ID
    const selectedPlan = planData.find(plan => plan._id === selectedPlanId);

    if (selectedPlan) {
      let priceId = ""; // Default value for priceId

      // Assign priceId based on the duration
      switch (selectedPlan.duration) {
        case 30:
          priceId = "64e4db3ef9eeb14b6e2185c8"; // 30 days
          break;
        case 90:
          priceId = "64e4db08f9eeb14b6e2185c4"; // 90 days
          break;
        case 0:
          priceId = "64e4da83f9eeb14b6e2185bc"; // 0 days
          break;
        default:
          priceId = ""; // Default case if duration doesn't match
      }

      setSelectedDuration({
        adId: selectedPlan._id,
        priceId: priceId,
        duration: selectedPlan.duration,
      });
    }
  };
  console.log(planRadioBtnOption, "planRadioBtnOptionnnnn")
  console.log(adsId,adsCategoriesId,editAdId, "selectedDuration")

  return (
    <>
      <div className='ad-page-main'>

        {showCongratulations &&
          <CongratulationsScreen adsId={myads_id} />
        }

        <CommonModalWrapper isOpen={isModalOpenForExample} className={style.example_modal} >
          <CloseIcon onClick={() => toggleExampleModal(false)} />
          <AddonExampleCard selectedExampleData={selectedExampleData} handleExampleModal={handleExampleModal} />
        </CommonModalWrapper>

        <section id='span-2' className={style.ads_plan_wrapper} >


          {/* if Not Local Biz Promotion */}
          {!isLocal && (
            <>
              {console.log("Date block")}
              <section className={style.expire_text_section}>
                {
                  planRadioBtnOption.length === 1 && !selectedPlan?.price[0]?.isfree && (
                    <span className={style.ads_expire_info_text}>
                      This Ad will expire on {selectedPlan?.price[0]?.duration ? addDaysToCurrentDate(selectedPlan?.price[0]?.duration) : ''}
                    </span>)}
              </section>
            </>
          )}


          {/* if Local Biz Promotion */}
          {isLocal && (
            <>
              <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Buy Promotions</h5>

              <section id={planRadioBtnOption.length === 1 && style.expire_text_section} className={style.expire_text_section}>
                {
                  planRadioBtnOption.length > 0 &&
                  <Inputs
                    name='careType'
                    value={selectedDuration.adId}
                    onChangeHandler={planChangeHandler}
                    type='radio'
                    id='span-2'
                    required={true}
                    radioBtnOtions={planRadioBtnOption}
                  />
                }

                <div className={style.price_section}>
                  <span className={style.plan_price_text}>
                    Free
                  </span>

                  <p className={style.expire_text}>
                    {
                      selectedDuration ?
                        (
                          selectedDuration.duration === 30 ? "This Ad will expire in 1 Month" :
                            selectedDuration.duration === 90 ? "This Ad will expire in 3 Months" :
                              `This Ad won't expire`
                        ) :
                        `This Ad won't expire`
                    }
                  </p>
                </div>
              </section>
            </>
          )}

          <section className={style.addon_section_wrapper}>
            <section className={style.sub_heading_and_selectall_wrapper}>
              <h4>Make your ad stand out!</h4>
              <div className={style.sub_heading_and_selectall_section}>
                <span>Select an option to promote your ad</span>
              </div>
            </section>

            <section className={style.plan_card_wrapper} key={selectedPlan?._id}>
              <div className={style.check_plan_heading_wrapper}>
                <div
                  className={style.check_box_wrapper}
                  onClick={() => handleExampleModal()}
                  style={{ cursor: "pointer" }}
                >
                  <input
                    type="checkbox"
                    className={style.plans_checkbox}
                    checked={linkToYourWeb}
                    onChange={() => (setLinkToYourWeb(!linkToYourWeb), toggleExampleModal(false))}
                    style={{
                      display: "block",
                      appearance: "checkbox",
                      width: "20px",
                      height: "20px",
                      border: "2px solid orange",
                      marginRight: "5px",
                    }}
                  />
                  <p
                    style={{
                      backgroundColor: selectedPlan?.name === "Link to your website" ? "blue" : "inherit",
                    }}
                    className={style.plan_heading_text}
                  >
                    {selectedPlan?.name}
                  </p>
                </div>

                <p className={style.plan_description_text}>
                  {selectedPlan?.description}{" "}
                  <span
                    onClick={() => handleExampleModal()}
                    className={style.view_example_text}
                  >
                    View example
                  </span>
                </p>

                {linkToYourWeb ? <input
                  value={websiteUrl}
                  onChange={(e) => setWebsiteURL(e.target.value)}
                  type="text"
                  name="website"
                  className={style.website_text_input_field}
                  placeholder="Enter website link"
                /> : ""}

              </div>

              <select
                id={style.select_plan_input}
                className={
                  linkToYourWeb
                    ? `${style.select_plan_input} ${style.active_card}`
                    : style.select_plan_input
                }
              // onChange={(e) => handlePlanChange(e.target.value, selectedPlan?._id)}
              >
                <option value="Free">Free</option>
              </select>
            </section>


            <CheckoutButton
              cancelButtonText="Back"
              submitBtnText={"Post My Ad"}
              id="span-2"
              backBtnClickHandler={backBtnClickHandler}
              onClickHandler={handleSubmission}
              showLoadder={showAdsPlan}
              disabled={isButtonDisabled}
              showAdsPlan={showAdsPlan}
            />

          </section>

        </section>
      </div>
    </>
  )
}
