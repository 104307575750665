import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';

import {
    isTruthyString,
    isValidURL,
    isValidYoutubURL,
    isValidEmailAddress,
    isDescriptionHas5Words
} from '../../../utils/verification';


import Inputs from '../../Inputs/Inputs';
// import MultiSelect from '../../form-input/MultiSelect';
import PostAdsHeading from '../post-ads-heading/PostAdsHeading';
import FormButton from '../../buttons/form-button/FormButton';
import AdsListerInfo from '../ads-lister-info/AdsListerInfo';
// import AdsPlans from '../ads-plans/AdsPlans';
import AdsPlansUpdated from '../ads-plans/AdsPlansUpdated';
import SelectLocation from '../select-location/SelectLocation';
import formDataAppender from '../../../utils/formDataAppender';
import ContactDetails from '../contact-details/ContactDetails';
import RichTextEditor from '../../form-input/RichTextEditor';
import ImageUpload from '../images-upload/ImageUpload';
import MultiSelect from '../../form-input/MultiSelect';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';
import appURLs from '../../../appURL';

import style from "./buyAndSell.module.css"

export default function BuyAndSell({ toggleModalVisibilty = () => { }, adsCategoriesId = null, adsId = "", adsIds }) {

    const fetchConfig = getFetchConfig(),
        appURL = appURLs();

    const [dynamicData, updateDynamicData] = useState({}),
        [showLoader, toggleLoader] = useState(false),
        [selectedTagline, upatedSelectedTagline] = useState([]),
        [buyAndSellAdsId, updateBuyAndSellAdsId] = useState(null),
        [steps, updateSteps] = useState(0),
        [myCat, setmyCat] = useState('');

    const [myCurrency, setCurrency] = useState("")
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState("");

    const [lat, setlat] = useState("")
    const [lng, setlng] = useState("")


    const userTypeRadioBtnOption = [
        {
            label: "Individual",
            value: "Individual"
        },
        {
            label: "Retailer",
            value: "Retailer"
        }
    ];


    const [selectedFiles, setSelectedFiles] = useState([]);
    const [myadsId, setmyadsId] = useState('');
    const [myadsType, setmyadsType] = useState('');

    const [prices, setPrices] = useState('');
    const [images, updateImage] = useState([]),
        [imagesDataArr, updateImagesDataArr] = useState([]),
        [subCategory, updatesSubCategory] = useState([]),
        [formData, updateFormData] = useState({

            categories: "",
            sub_categories: "",
            title: "",
            descriptions: "",
            user_type: "",
            product_condition: "",
            photos: [],

            price: "",
            currency: dynamicData?.currency?.[0],
            tagline: [],
            video_link: "",
            quantity_unit: "",
            quantity: "",


            location_name: "",
            latitude: "",
            longitude: "",
            orginizerEmailAddress: "",
            orginizerName: "",

            name: JSON.parse(localStorage.getItem(`user`))?.name,
            email_address: JSON.parse(localStorage.getItem(`user`))?.email_address,
            primary_phone_number: JSON.parse(localStorage.getItem(`user`))?.phone_number,
            secondary_phone_number: "",
            website: "",
            hide_email_address: false,
            hide_phone_number: false,
            adsType: "",
            status: ""
        });



    const [locationResponseData, setLocationResponseData] = useState(false);

    const [isCheckedFreePrice, toggleIsCheckedFreePrice] = useState(false);
    const [isCheckedNegotiablePrice, toggleIsCheckedNegotiablePrice] = useState(false),
        [isContactChecked, toggleIsContactChecked] = useState(false);


    const onCreateteHandler = (value) => {
        if (value && selectedTagline.length <= 4) {
            upatedSelectedTagline([
                ...selectedTagline,
                value
            ])
        }
    }

    const handleTagInputChange = (e) => {
        const value = e.target.value;
        if (value.length <= 25) {
            setInputValue(value);  // Only update the input if the length is <= 25
        }
    };

    // Handle the Enter key press
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            handleAddTag();
        }
    };
    // Handle adding a tag (either pressing Enter or clicking the Add button)
    const handleAddTag = () => {
        if (inputValue.trim() && !tags.includes(inputValue)) {
            updateFormData((prevData) => ({
                ...prevData,
                tagline: [...prevData.tagline, inputValue],  // Append to tagline array
            }));
            setTags([...tags, inputValue]);  // Add the tag to the list
            setInputValue("");  // Clear the input field
        }
    };

    // Handle tag removal
    const handleRemoveTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));  // Remove the tag from the list
    };


    const onChangeHandlerForForm = (e) => {
        const inputName = e?.target?.name || e?.name;
        let inputValue = e?.target?.value || e?.value;

        setPrices(inputValue);


        localStorage.removeItem("myItem");

        // Input type for validation of numbers

        if (inputName === 'price') {
            // let inputValue1 = e.target.value.replace(/\D/g, '');
            let inputValue1 = e.target.value.replace(/[^0-9.]/g, '');
            localStorage.setItem('priceInputValue', inputValue);

            if (inputValue1.length > 7) {
                inputValue1 = inputValue1.slice(0, 7);
            }

            inputValue = inputValue1;
        }

        if (inputName === 'currency') {
            // Check if the currency value has changed
            // if (inputValue !== localStorage.getItem('currencyPrice')) {
            //     // Update localStorage with the new currency value
            //     localStorage.setItem('currencyPrice', inputValue);
            // } else {
            //     // If the currency value has not changed, remove it from localStorage
            //     localStorage.removeItem('currencyPrice');
            // }

            setCurrency(inputValue);
        }

        if (inputName === 'quantity') {
            // let inputValue1 = e.target.value.replace(/\D/g, '');
            let inputValue1 = e.target.value.replace(/[^0-9.]/g, '');


            if (inputValue1.length > 7) {
                inputValue1 = inputValue1.slice(0, 7);
            }

            inputValue = inputValue1;
        }

        // Input type for validation of numbers

        // if (inputName === `categories`) {
        //     if (inputValue === `Furniture and Home decore`) updatesSubCategory(dynamicData?.categories_Furniture);
        //     if (inputValue === `Home and Kitchen Appliance`) updatesSubCategory(dynamicData?.categories_Home);
        //     if (inputValue === `Baby & Kids stuff`) updatesSubCategory(dynamicData?.categories_Baby);
        //     if (inputValue === `Real Estate`) updatesSubCategory(dynamicData?.categories_Real);
        //     if (inputValue === `Electronics appliance`) updatesSubCategory(dynamicData?.categories_Electronics);
        //     if (inputValue === `Computers & Accsosories`) updatesSubCategory(dynamicData?.categories_Computers);
        //     if (inputValue === `Books`) updatesSubCategory(dynamicData?.categories_Books);
        //     if (inputValue === `Musical instrument`) updatesSubCategory(dynamicData?.categories_Musical);
        //     if (inputValue === `Phones`) updatesSubCategory(dynamicData?.categories_Phones);
        //     if (inputValue === `Clothing and Footwear`) updatesSubCategory(dynamicData?.categories_Clothing);
        //     if (inputValue === `Pets`) updatesSubCategory(dynamicData?.categories_Pets);
        //     if (inputValue === `Fashion & Jewllery`) updatesSubCategory(dynamicData?.categories_Fashion);
        //     if (inputValue === `Home, Lawn & Garden`) updatesSubCategory(dynamicData?.categories_Home_lawn);
        //     if (inputValue === `Video Games & Consoles`) updatesSubCategory(dynamicData?.categories_Video);
        //     if (inputValue === `Bikes & Moterbikes`) updatesSubCategory(dynamicData?.categories_Bikes);
        //     if (inputValue === `Sport & Health product`) updatesSubCategory(dynamicData?.categories_Sport);
        //     if (inputValue === `Cars`) updatesSubCategory(dynamicData?.categories_Cars);
        //     if (inputValue === `Arts & Collectabiles`) updatesSubCategory(dynamicData?.categories_Arts);
        //     if (inputValue === `Free Stuffs`) updatesSubCategory(dynamicData?.categories_freestuff);
        //     if (inputValue === `Other`) updatesSubCategory(dynamicData?.categories_others);
        // }

        console.log(inputValue, "inputValue455");
        if (inputName === 'categories') {
            // Find the category object with the given ID
            const category = dynamicData.categories.find(cat => cat._id === inputValue);
            if (category) {
                // Extract subcategory names from the array of subcategory objects
                const subcategories = category.subcategory.map(subcat => subcat);
                updatesSubCategory(subcategories);

            } else {
                // If category not found, update with an empty array
                updatesSubCategory([]);
            }
        }


        if (inputName === `primary_phone_number` || inputName === `secondary_phone_number`) {
            const inputNumber1 = e.target.value.replace(/\D/g, ""); // Remove non-digit characters

            if (inputNumber1.length <= 15) {
                updateFormData((prev) => ({
                    ...prev,
                    [inputName]: inputValue
                }));
            }
        } else {

            updateFormData((prev) => ({
                ...prev,
                [inputName]: inputValue
            }));
        }

        if (inputName === `free_price`) {
            updateFormData({
                ...formData,
                price: 0
            })
            toggleIsCheckedNegotiablePrice(false)
            localStorage.removeItem("priceInputValue");
            toggleIsCheckedFreePrice(!isCheckedFreePrice)
            // setPrices("")
        } else if (inputName === `negotiable_price`) {
            toggleIsCheckedNegotiablePrice(!isCheckedNegotiablePrice)
        } else if (inputName === `contact`) {
            toggleIsCheckedNegotiablePrice(false)
            toggleIsContactChecked(!isContactChecked)
            localStorage.removeItem("priceInputValue");
            updateFormData({
                ...formData,
                price: isContactChecked ? null : formData.price
            });
        }
    }


    const [paymentModeArr, updatePaymentModeArr] = useState([]);
    const [fullfilmentArr, updateFullfilmentArr] = useState([]);


    const handleChangeCheckBox = (e) => {

        // Destructuring
        const { value, checked } = e.target;

        // Case 1 : The user checks the box
        if (checked && e.target.name === `payment`) {
            console.log(value)
            if (value === `Not applicable`) {
                updatePaymentModeArr([value]);
            } else {
                const filteredData = paymentModeArr.filter((data) => data !== `Not applicable`)
                updatePaymentModeArr([...filteredData, value]);
            }

        }
        // Case 2  : The user unchecks the box
        else {
            updatePaymentModeArr(paymentModeArr.filter((e) => e !== value));
        }

        if (checked && e.target.name === `fullfilment`) {
            console.log(value)
            if (value === `Not applicables`) {

                updateFullfilmentArr([value]);
            } else {
                const filteredData = fullfilmentArr.filter((data) => data !== `Not applicables`);
                console.log(`filteredData`, filteredData)
                updateFullfilmentArr([...filteredData, value]);
            }


        }
        // Case 2  : The user unchecks the box
        else {
            updateFullfilmentArr(fullfilmentArr.filter((e) => e !== value));
        }

    };


    const {

        categories,
        sub_categories,
        title,
        descriptions,
        user_type,
        product_condition,
        model,

        price,
        currency,
        video_link,
        quantity,
        quantity_unit,
        tagline,

        location,
        location_name,
        latitude,
        longitude,
        orginizerEmailAddress,
        orginizerName,
        photos,

        name,
        email_address,
        primary_phone_number,
        secondary_phone_number,
        hide_phone_number,
        hide_email_address,
        website,
        adsType,
        status
    } = formData;


    const [isHideMobileNumberChecked, toggleIsHideMobileNumberChecked] = useState(false),
        [isHideEmailAddressChecked, toggleIsHideEmailAddressChecked] = useState(false),
        [isHideEmailAddress1Checked, toggleIsHideEmailAddress1Checked] = useState(false);


    // lister info
    const hideEmailNumberSwitchButtonHandler = (e) => {

        const inputName = e?.target?.name;

        if (inputName === `hide_secondary_phone_number`) toggleIsHideEmailAddressChecked(!isHideEmailAddressChecked)
        if (inputName === `hide_primary_phone_number`) toggleIsHideMobileNumberChecked(!isHideMobileNumberChecked)
        if (inputName === `hide_my_email_address`) toggleIsHideEmailAddress1Checked(!isHideEmailAddress1Checked)

        console.log(isHideMobileNumberChecked, "isHideMobileNumberChecked876787")

    }



    const handleFormSubmit = async (e) => {

        e.preventDefault();
        toast.dismiss();

        localStorage.removeItem("start_date");
        localStorage.removeItem("end_date");

        if (steps === 4) return toggleModalVisibilty("buyAndSell", false);

        const missingData = [],
            invalidData = [];

        if (steps === 0) {

            if (!(categories || sub_categories || title || product_condition || user_type || model || descriptions)) return toast.error(`Please provide all data`);

            if (!isTruthyString(categories)) missingData.push(`category`);
            if (subCategory && subCategory.length && !isTruthyString(sub_categories)) missingData.push(`${categories} category`);
            if (!isTruthyString(title)) missingData.push(`Title`);
            if (!isTruthyString(product_condition)) missingData.push(`Product condition`);
            if (!isTruthyString(user_type)) missingData.push(`Seller type`);
            if (model && !isTruthyString(model)) missingData.push(`Model`);

            if (!isTruthyString(descriptions)) missingData.push(`description`);
            else if (isDescriptionHas5Words(descriptions)) {
                return toast.error("You must write at least 5 words in your description");
            }

        } else if (steps === 1) {
            if (!((!isCheckedFreePrice && !price) || isCheckedNegotiablePrice || paymentModeArr.length === 0 || fullfilmentArr.length === 0 || tagline || location || location_name || images.length === 0 || video_link)) return toast.error(`Please provide all data`);

            if (!(isCheckedFreePrice || isContactChecked) && !price) missingData.push(`Price`);
            if (!(isCheckedFreePrice || isContactChecked) && price && isNaN(Number(price))) invalidData.push(`Price`);

            if (quantity && isNaN(quantity)) invalidData.push(`Available Inventory Quantity`);

            if (isCheckedFreePrice && typeof isCheckedFreePrice !== `boolean`) invalidData.push(`Free price`);
            if (isCheckedNegotiablePrice && typeof isCheckedNegotiablePrice !== `boolean`) invalidData.push(`Negotiable price`);

            if (!(isCheckedFreePrice || isContactChecked) && paymentModeArr && paymentModeArr.length < 1) missingData.push(`payment`);
            if (fullfilmentArr && fullfilmentArr.length < 1) missingData.push(`fullfilment`);


            if (video_link && !isValidYoutubURL(video_link)) invalidData.push(`Youtube video link`);

            // if (tagline && !isTruthyString(tagline)) missingData.push(`Tagline`);

            // if (!isTruthyString(location)) missingData.push(`location`);
            if (!isTruthyString(location_name)) missingData.push('location');
            else if (!locationResponseData && !adsIds) {
                return toast.error('Please select a location from the suggestions');
            }
            // if (images && images.length === 0) missingData.push(`Images`);

        } else if (steps === 2) {

            if (!(name || email_address || primary_phone_number || secondary_phone_number || primary_phone_number)) return toast.error(`Please provide all data`)

            if (!isTruthyString(name)) missingData.push(`Name`);
            if (!email_address) missingData.push(`Email address`);
            else if (email_address && !isValidEmailAddress(email_address)) invalidData.push(`Email address`);

            if (!primary_phone_number) missingData.push(`Primary phone number`);
            else if (primary_phone_number && isNaN(primary_phone_number)) invalidData.push(`Primary phone number`);
            else if (primary_phone_number && (primary_phone_number.length < 10 || primary_phone_number.length > 15)) invalidData.push(`Primary phone number`);

            if (secondary_phone_number && isNaN(secondary_phone_number)) invalidData.push(`Secondary phone number`);
            else if (secondary_phone_number && (secondary_phone_number.length < 10 || secondary_phone_number.length > 15)) invalidData.push(`secondary phone number`);

            if ((secondary_phone_number && secondary_phone_number) && primary_phone_number === secondary_phone_number) return toast.error(`Primary phone number can not be same as secondary phone number`)

        }

        // Show errors if needed
        if (missingData.length || invalidData.length) {
            if (missingData.length) toast.error(`Missing: ${missingData.join(`, `)}`);
            if (invalidData.length) toast.error(`Invalid: ${invalidData.join(`, `)}`);
            return;
        } else if (steps !== 1 && steps !== 2) {
            updateSteps(steps + 1);
        }

        if (steps == 2 && adsIds && status === "active") {
            toast.success('Ads Edit Successfully');
            window.location.reload();
        }


        let formDataObj = {
            ads_type: adsCategoriesId || adsType,
            currency,
            status: `draft`,
            sub_category: sub_categories,
            negotiable: isCheckedFreePrice ? false : isCheckedNegotiablePrice,
            is_contact: isCheckedFreePrice ? false : isContactChecked,
            amount: price,
            redirect_uri_success: "menehariya.netscapelabs.com/success"
        };

        if (categories) formDataObj.category = categories;
        if (images.length) formDataObj.photos = images;
        // if (sub_categories) formDataObj.sub_category = `Furniture and Home decore`;
        if (sub_categories) formDataObj.sub_category = sub_categories;
        if (title) formDataObj.title = title;
        if (product_condition) formDataObj.product_condition = product_condition;
        if (user_type) formDataObj.user_type = user_type;
        if (model) formDataObj.product_model = model;
        if (descriptions) formDataObj.descriptions = descriptions;

        // steps 2
        if (paymentModeArr) formDataObj.payment_mode = paymentModeArr
        if (fullfilmentArr.length > 0) formDataObj.fullfilment = fullfilmentArr;
        if (tagline) formDataObj.tagline = tagline;
        if (location_name) formDataObj.location_name = location_name;
        if (latitude) formDataObj.latitude = latitude;
        if (longitude) formDataObj.longitude = longitude;
        if (quantity) formDataObj.quantity = quantity;
        if (video_link) formDataObj.video_link = video_link;
        // if (imagesDataArr.length > 0) formData.photos = imagesDataArr
        // contact detail;

        if (isContactChecked) formDataObj.is_contact = isContactChecked;

        // lsiter detail;
        if (name) formDataObj.name = name;
        // if (currency) formDataObj.currency = currency;
        if (email_address) formDataObj.email_address = email_address;
        if (primary_phone_number) formDataObj.primary_phone_number = primary_phone_number;
        if (secondary_phone_number) formDataObj.secondary_phone_number = secondary_phone_number;
        if (website) formDataObj.website = website;
        if (hide_email_address) formDataObj.hide_email_address = hide_email_address;
        // if (hide_phone_number) formDataObj.hide_phone_number = hide_phone_number;

        if (isHideMobileNumberChecked) formDataObj.hide_my_phone = isHideMobileNumberChecked;
        if (isHideEmailAddressChecked) formDataObj.hide_my_secondary_phone = isHideEmailAddressChecked;


        // return console.log(isCheckedNegotiablePrice)
        const BuySellFormData = new FormData();

        if ((adsId || adsIds) && status === 'draft') {
            // alert("infirsty")
            steps === 2 && selectedFiles.forEach((file, index) => {
                BuySellFormData.append('photos', file);
            })
        }
        if (!((adsId || adsIds) && status === 'draft')) {
            // alert("insecond")
            steps === 2 && selectedFiles.forEach((file, index) => {
                BuySellFormData.append('photos', file);
            })
        }
        if (status === 'active') {
            // alert("inthird")
            steps === 1 && selectedFiles.forEach((file, index) => {
                BuySellFormData.append('photos', file);
            })
        }



        const data = formDataAppender(formDataObj, BuySellFormData);

        for (const pair of data.entries()) {
            console.log(`${pair[0]}, ${pair[1]}`);
        }

        if (steps === 1 && !(buyAndSellAdsId || adsIds)) {

            toggleLoader(true);

            const updatedFetchConfig = fetchConfig;
            delete updatedFetchConfig.headers['Content-Type'];

            fetch(`${appURL}/posts/buysell/create`, {
                ...updatedFetchConfig,
                body: BuySellFormData
            })
                .then(handleFetchErrors)
                .then((res) => {

                    toggleLoader(false)
                    if (res?.status === 200) {
                        const buyAndSellAdsObjectId = res?.postBuySellAdObjToSend?._id
                        updateSteps(steps + 1);
                        updateBuyAndSellAdsId(buyAndSellAdsObjectId)
                        // localStorage.setItem("myprice")

                        localStorage.setItem('adsInfo', JSON.stringify({
                            id: buyAndSellAdsObjectId,
                            title: title,
                            adsType: "BuyAndSell",
                            adsCategories: adsCategoriesId
                        }));
                    } else {
                        return toast.error(res?.message);
                    }
                    console.log(`resresresres`, res)
                    var myAmount = res.postBuySellAdObjToSend.adsInfo.price.amount;
                    // var priceCurrency = res.postBuySellAdObjToSend.adsInfo.price.currency
                    localStorage.setItem("myItem", myAmount)
                    // localStorage.setItem("priceCurrency",priceCurrency )
                    console.log(res.postBuySellAdObjToSend.adsInfo.price.amount, "hwuhdsufhujh3iwhndin");
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err)
                });
        } else if ((steps === 2 || steps === 1) && (buyAndSellAdsId || adsIds)) {
            console.log(`WORKING`)
            toggleLoader(true);

            const updatedFetchConfig = fetchConfig;
            delete updatedFetchConfig.headers['Content-Type'];

            // BuySellFormData.delete('photos');

            fetch(`${appURL}/posts/buysell/edit/${buyAndSellAdsId || adsIds}`, {
                ...updatedFetchConfig,
                method: "PATCH",
                body: BuySellFormData
            })
                .then(handleFetchErrors)
                .then((res) => {
                    console.log(res.updateBuySellAdObjToSend.advertisement_id, "my_new_id");
                    localStorage.setItem('advertisementId', res.updateBuySellAdObjToSend.advertisement_id)
                    toggleLoader(false)
                    if (res?.status === 200) {
                        updateSteps(steps + 1);
                    } else {
                        return toast.error(res?.message);
                    }
                    // console.log(`resresresres`, res)
                })
                .catch((err) => {
                    toggleLoader(false);
                    console.log(err)
                });

        }
    };





    // fetching ads categories
    const fetchingDynamicsData = () => {

        fetch(`${appURL}/posts/buysell/dynamics-data?PostType=${'64e48f7bf9eeb14b6e2135b8'}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(res)

                if (res.status === 200) {
                    const dynamicDataObj = res?.data;
                    if (dynamicDataObj) updateDynamicData(dynamicDataObj)
                    updateFormData({
                        ...formData,
                        currency: dynamicData?.currency?.[0] || "USD",
                    })

                } else {
                    return toast.error(`Enable fetch dynamics data`);
                }

            })
            .catch((err) => standardFetchHandlers.error(err));
    }


    const handleFileChange = (event) => {
        const files = event.target.files;
        setSelectedFiles([...selectedFiles, ...files]);
    };

    // selecting location
    const callBackFunction = (address = '', lat = '', lng = '') => {
        updateFormData({
            ...formData,
            location_name: address,
            latitude: lat,
            longitude: lng,
        });
    }


    console.log(`isCheckedNegotiablePrice`, isCheckedNegotiablePrice)

    console.log(`isContactChecked`, isContactChecked)

    localStorage.setItem("isCheckedNegotiablePrice", isCheckedNegotiablePrice);

    const backBtnClickHandler = () => {
        if (steps === 0) return toggleModalVisibilty("buyAndSell", false);
        updateSteps(steps - 1)
    }


    const fetchingAdsById = () => {
        const actualId = adsId || adsIds;

        fetch(`${appURL}/posts/buysell/ad_details?adsId=${actualId}`, {
            ...fetchConfig,
            method: "GET"
        })
            .then(handleFetchErrors)
            .then((res) => {
                console.log(`resbushell`, res);

                if (res?.status == 200) {
                    const data = res?.ads_details,
                        listerInfo = data?.lister_basic_info,
                        adsInfo = data?.adsInfo;
                    setSelectedFiles(res?.ads_details?.adsInfo?.image);
                    setmyadsId(res?.ads_details?._id);
                    setmyadsType(res?.ads_details?.adsType);
                    toggleIsCheckedNegotiablePrice(adsInfo?.price?.negotiable)
                    updatePaymentModeArr(adsInfo?.payment_mode)
                    updateFullfilmentArr(adsInfo?.fullfilment)
                    // upatedSelectedTagline(adsInfo?.tagline)
                    setTags(adsInfo?.tagline)
                    toggleIsCheckedFreePrice(adsInfo?.price?.amount == 0)
                    toggleIsContactChecked(adsInfo?.price?.is_contact)

                    setmyCat(adsInfo?.category?.name);
                    const dataObj = {
                        categories: !(adsId || adsIds) ? adsInfo?.category : adsInfo?.category?._id,
                        sub_categories: !(adsId || adsIds) ? adsInfo?.sub_category : adsInfo?.sub_category?._id,
                        title: adsInfo?.title || "",
                        descriptions: adsInfo?.descriptions || "",
                        user_type: adsInfo?.user_type || "",
                        product_condition: adsInfo?.product_condition || "",
                        model: adsInfo?.product_model,
                        location_name: adsInfo?.location?.location_name,
                        latitude: adsInfo?.location?.coordinates[1],
                        longitude: adsInfo?.location?.coordinates[0],

                        price: adsInfo?.price?.amount || "",
                        currency: adsInfo?.price?.currency || "",
                        // tagline: !(adsId || adsIds) ? adsInfo?.category : adsInfo?.category?._id,
                        tagline: adsInfo?.tagline || [],
                        video_link: adsInfo?.video_link || "",
                        quantity: adsInfo?.quantity || "",
                        orginizerName: adsInfo?.category || "",


                        name: listerInfo?.name || "",
                        email_address: listerInfo?.email_address || "",
                        primary_phone_number: listerInfo?.primary_mobile_number?.primary_phone_number || "",
                        secondary_phone_number: listerInfo?.secondary_mobile_number?.secondary_phone_number || "",
                        website: listerInfo?.webiste || "",
                        hide_secondary_phone_number: listerInfo?.hide_my_secondary_phone || false,
                        hide_primary_phone_number: listerInfo?.hide_my_phone || false,
                        adsType: data?.adsType,
                        status: data.status,
                    }


                    updateFormData(dataObj)

                }
            })
            .catch((err) => {

                console.log(err)
            });
    }


    useEffect(() => {
        fetchingDynamicsData();
        if (adsId || adsIds) fetchingAdsById();
    }, [])


    let HeadingMessage = `Post your Ad`;
    let SubHeadingMessage = ``;

    if (steps === 3) {
        HeadingMessage = `Post your Ad`
    } else if (steps === 4) {
        HeadingMessage = ``
    } else if (steps === 2) {
        SubHeadingMessage = `Basic Information`
    }

    const paymentToolTipText = {
        'Cashless payment': "By selecting this option, you are also accepting a form of cashless payment. Please make sure to agree on the method while negotiating."
    }

    const fullFillmentToolTipText = {
        'Only local delivery': " By selecting this option, you are willing to deliver this item to the buyer's local preferred location. Please make sure to communicate additional costs (if any) while negotiating.",
        "In person pickup": "By selecting this option, you also offer in person pick up. Please make sure you communicate any necessary instructions to the buyer.",
        "Free shipping": "By selecting this option, you are willing to ship this item to the buyer for free."
    }



    // useEffect(() => {
    //     const myItem = localStorage.getItem("myItem");
    //     console.log(myItem); // Do something with the value

    //     // Cleanup function to remove the item from localStorage when the component unmounts
    //     return () => {
    //         localStorage.removeItem("myItem");
    //     };
    // }, []);



    useEffect(() => {

        const storedPrice = localStorage.getItem('priceInputValue');
        if (storedPrice) {
            setPrices(storedPrice);
        }
    }, []);





    const abcfunction = (data) => {
        setlat(data.lat)
        setlng(data.lng)

        console.log(data.lat, data.lng, "Nothing")
    }
    return (
        <main className={style.main_wrapper}>

            <PostAdsHeading headingText={steps === 2 && (status === 'active' || status === 'draft') && (adsIds || adsId) ? 'Post Your Ad' : HeadingMessage} subHeadingText={SubHeadingMessage} />

            <form id={steps === 3 ? undefined : style.room_rents_form}>

                {
                    (() => {

                        if (steps === 0) {

                            return (
                                <>
                                    {!(adsId || adsIds) || status === 'draft' ?
                                        <Inputs
                                            name='categories'
                                            value={categories}
                                            required={true}
                                            onChangeHandler={onChangeHandlerForForm}
                                            label='Buy & Sell Category'
                                            type='select'
                                            disabled={status !== "draft" && (adsIds ? true : false)}
                                            id='span-2'
                                            placeholder='Select buy & sell category'
                                            selectInputOptions={dynamicData?.categories}
                                        />
                                        : <Inputs
                                            name='categories'
                                            value={myCat}
                                            required={true}
                                            onChangeHandler={onChangeHandlerForForm}
                                            label='Buy & Sell Category'
                                            type='text'
                                            disabled={status !== "draft" && (adsIds ? true : false)}
                                            id='span-2'
                                            placeholder='Select buy & sell category'
                                        />}

                                    {
                                        categories && subCategory.length ?

                                            <Inputs
                                                name='sub_categories'
                                                value={sub_categories}
                                                required={true}
                                                onChangeHandler={onChangeHandlerForForm}
                                                label={`Sub-category`}
                                                type='select'
                                                disabled={status !== "draft" && (adsIds ? true : false)}
                                                id='span-2'
                                                placeholder={`Select Sub-category`}
                                                selectInputOptions={subCategory ? subCategory : ``}
                                            /> : ``
                                    }


                                    <Inputs
                                        name='title'
                                        required={true}
                                        value={title}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label='Title'
                                        type='text'
                                        id='span-2'
                                        placeholder='Enter ad title'
                                    />

                                    <Inputs
                                        name='product_condition'
                                        value={product_condition}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label='Product condition'
                                        type='radio'
                                        required={true}
                                        radioBtnOtions={dynamicData?.product_condition}
                                    />

                                    <Inputs
                                        name='user_type'
                                        value={user_type}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label='Seller Type'
                                        type='radio'
                                        required={true}
                                        radioBtnOtions={dynamicData?.user_type}
                                    />

                                    <Inputs
                                        name='model'
                                        value={model}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label='Model'
                                        type='text'
                                        id='span-2'
                                        placeholder='Enter model'
                                    />

                                    {/* <Inputs
                                        name='descriptions'
                                        required={true}
                                        value={descriptions}
                                        onChangeHandler={onChangeHandlerForForm}
                                        label='Description'
                                        type='textarea'
                                        id='span-2'
                                        placeholder='Enter ad description'
                                    />
 */}

                                    <RichTextEditor value={descriptions} updateFormData={updateFormData} formData={formData} label="Description" required={true} />

                                </>
                            )
                        } else if (steps === 1) {
                            return (
                                <>
                                    {/* 
                                    <section id='span-2'>

                                        <aside className={style.price_label_wrapper}>
                                            <span className={style.price_heaidng}>Price</span>
                                            <div className={style.free_negotiable_check_box_wrapper} >
                                                <div id='asvdhag'>
                                                    <input checked={isCheckedFreePrice} name="free_price" onChange={onChangeHandlerForForm} type="checkbox" id="free" />
                                                    <label id='asvdhag' for="free"> Free</label><br />
                                                </div>

                                                {
                                                    !isCheckedFreePrice && <div>
                                                        <input checked={isCheckedNegotiablePrice} name="negotiable_price" onChange={onChangeHandlerForForm} type="checkbox" id="Negotiable" />
                                                        <label id='asvdhag' for="Negotiable">Negotiable</label><br />
                                                    </div>

                                                }

                                            </div>

                                        </aside>
                                        <Inputs
                                            name='price'
                                            disabled={isCheckedFreePrice}
                                            value={price}
                                            onChangeHandler={onChangeHandlerForForm}

                                            type='number'
                                            id='span-2'
                                            placeholder='Enter price'
                                        />
                                    </section>
 */}


                                    <div id='span-2'>
                                        <aside className={style.price_label_wrapper}>
                                            <span id='label' className={style.price_heaidng}>Price <span className='required_field'>*</span></span>
                                            <div className={style.free_negotiable_check_box_wrapper} >
                                                {
                                                    !isContactChecked &&
                                                    <div id='asvdhag'>
                                                        <input checked={isCheckedFreePrice} name="free_price" onChange={onChangeHandlerForForm} type="checkbox" id="free" />
                                                        <label id='asvdhag' for="free"> Free</label><br />
                                                    </div>
                                                }


                                                {
                                                    !isCheckedFreePrice && <>

                                                        {
                                                            !isContactChecked && <div>
                                                                <input checked={isCheckedNegotiablePrice} name="negotiable_price" onChange={onChangeHandlerForForm} type="checkbox" id="Negotiable" />
                                                                <label id='asvdhag' for="Negotiable">Negotiable</label><br />
                                                            </div>
                                                        }

                                                        <div>
                                                            <input checked={isContactChecked} name="contact" onChange={onChangeHandlerForForm} type="checkbox" id="contact" />
                                                            <label for="contact">Please Contact</label><br />
                                                        </div>
                                                    </>

                                                }

                                            </div>

                                        </aside>

                                        {
                                            !(isCheckedFreePrice || isContactChecked) &&

                                            <div className={style.expected_salary_input_wrapper}>
                                                <section>
                                                    <Inputs
                                                        inputId={style.currency_input_field}
                                                        type='select'
                                                        name='currency'
                                                        value={currency}
                                                        onChangeHandler={onChangeHandlerForForm}
                                                        selectInputOptions={dynamicData?.currency}
                                                    />
                                                </section>
                                                <section>



                                                    <Inputs
                                                        name='price'
                                                        disabled={isCheckedFreePrice}
                                                        value={price}
                                                        onChangeHandler={onChangeHandlerForForm}
                                                        inputId={style.rent_input}
                                                        type='number'
                                                        id='span-2'
                                                        placeholder='Enter price'
                                                    />
                                                </section>
                                            </div>
                                        }



                                    </div>

                                    <div id='span-2'>
                                        <span id='label' className={style.price_heaidng}>Available Inventory Quantity<span className='optional_text'>(Optional)</span></span>
                                        <div className={style.inventory_qunatity_warpper}>

                                            <section>


                                                <Inputs
                                                    name='quantity'
                                                    value={quantity}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    // label='Available Inventory Quantity'
                                                    type='number'
                                                    inputId={style.inventory_qunatity_input}
                                                    id='span-2'
                                                    placeholder='Enter available inventory quantity '
                                                />
                                            </section>

                                            <section>
                                                <Inputs
                                                    inputId={style.qunatity_unit_input}
                                                    type='select'
                                                    name='quantity_unit'
                                                    value={quantity_unit}
                                                    onChangeHandler={onChangeHandlerForForm}
                                                    selectInputOptions={dynamicData?.quantity_unit}
                                                />
                                            </section>

                                        </div>




                                    </div>

                                    {!(isCheckedFreePrice) ?

                                        <Inputs
                                            name='payment'
                                            selectOptionArrForCheckBox={paymentModeArr}
                                            onChangeHandler={handleChangeCheckBox}
                                            label='Payment'
                                            required={true}
                                            type='checkbox'
                                            tooltip={true}
                                            tooltipField={['Cashless payment']}
                                            toolTipText={paymentToolTipText}
                                            id='span-2'
                                            checkboxInputOtions={dynamicData?.payment_mode}
                                        /> : ``

                                    }
                                    <Inputs
                                        name='fullfilment'
                                        required={true}
                                        selectOptionArrForCheckBox={fullfilmentArr}
                                        onChangeHandler={handleChangeCheckBox}
                                        label='Fullfilment'
                                        tooltip={true}
                                        tooltipField={['Free shipping', 'In person pickup', 'Only local delivery']}
                                        toolTipText={fullFillmentToolTipText}
                                        type='checkbox'
                                        id='span-2'
                                        checkboxInputOtions={dynamicData?.fullfilment}
                                    />

                                    <section id="span-2">
                                        <div>
                                            <label className="tagline-main">Business tagline(optional)</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="tagline"
                                                    value={inputValue}
                                                    onChange={handleTagInputChange}
                                                    onKeyPress={handleKeyPress}
                                                    placeholder="Enter tagline"
                                                />
                                                <button onClick={handleAddTag} style={{ display: "none" }} >Add Tag</button>
                                            </div>

                                            {/* Display tags */}
                                            <div>
                                                {tags.length > 0 && (
                                                    <div>
                                                        {tags.map((tag, index) => (
                                                            <span key={index} style={{ margin: "5px", padding: "5px", border: "1px solid #ccc", display: "inline-block" }}>
                                                                {tag}
                                                                <button
                                                                    onClick={() => handleRemoveTag(tag)}
                                                                    style={{ marginLeft: "5px", cursor: "pointer", background: "red", color: "white", border: "none", borderRadius: "50%", width: "20px", height: "20px" }}
                                                                >
                                                                    ×
                                                                </button>
                                                            </span>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            {/* Display message if the limit is exceeded */}
                                            {inputValue.length > 25 && <p style={{ color: 'red' }}>Tag must be less than 25 characters</p>}
                                        </div>

                                    </section>

                                    {/* <section id='span-2'>
                                        <MultiSelect
                                            label='Business tagline'
                                            id='tagline'
                                            value={selectedTagline}
                                            allowCreate={selectedTagline.length <= 25 ? true : false}
                                            onCreate={onCreateteHandler}
                                            name='tagline'
                                            data={["test1", "test2"]}
                                            dataKey="short_id"
                                            textField="name_email"
                                            placeholder="Enter tagline"
                                            onChange={(admin) => upatedSelectedTagline(admin)}
                                        />
                                    </section> */}


                                    <Inputs
                                        label='Youtube video link'
                                        id='span-2'
                                        type='text'
                                        name='video_link'
                                        value={video_link}
                                        onChangeHandler={onChangeHandlerForForm}
                                        placeholder='https://www.youtube.com/'
                                    />

                                    <SelectLocation callBackFunction={callBackFunction} location={location_name}
                                        latitude={latitude}
                                        longitude={longitude}
                                        // abc={abcfunction} 
                                        locationResponseData={locationResponseData}
                                        setLocationResponseData={setLocationResponseData}
                                        required={true} />

                                    <ImageUpload images={images} updateImage={updateImage} handleFileChange={handleFileChange} setSelectedFiles={setSelectedFiles}
                                        selectedFiles={selectedFiles} myadsId={myadsId} myadsType={myadsType} />

                                    {/* <input type="file" name='photos' onChange={handleFileChange} multiple/> */}
                                </>)
                        }

                        else if (steps === 2) {

                            return <AdsListerInfo
                                onChangeHandler={onChangeHandlerForForm}
                                formData={formData}
                                isHideMobileNumberChecked={isHideMobileNumberChecked}
                                isHideEmailAddressChecked={isHideEmailAddressChecked}
                                isHideEmailAddress1Checked={isHideEmailAddress1Checked}
                                hideEmailNumberSwitchButtonHandler={hideEmailNumberSwitchButtonHandler}
                            />
                        }

                        else if (steps === 3 && !adsIds) {
                            return <AdsPlansUpdated updateSteps={updateSteps}
                                steps={steps} adsId={buyAndSellAdsId} adsCategoriesId={adsCategoriesId} myCurrency={myCurrency} />
                        }

                        else if (steps === 3 && adsIds && status != "active") {
                            return <AdsPlansUpdated
                                updateSteps={updateSteps}
                                steps={steps}
                                adsId={buyAndSellAdsId}
                                adsCategoriesId={adsCategoriesId || adsType}
                                adsIds={adsIds}
                                myCurrency={myCurrency} />
                        }

                        else if (steps === 4) {
                            return <CongratulationsScreen />
                        }

                    })()

                }
                {
                    (steps !== 4 && steps !== 3) && <FormButton cancelButtonText='Back' submitBtnText={(steps === 2 && status === 'active') ? "Update My Ad" :
                        (steps === 3 && status === 'draft') ? "Post My Ad" :
                            "Next"} id="span-2" className={style.form_button_section} backBtnClickHandler={backBtnClickHandler} onClickHandler={handleFormSubmit} showLoadder={showLoader} />
                }


            </form>

        </main>
    )
}
