import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
// import 'dayjs/plugin/customParseFormat';

import CommonModalWrapper from '../../common-modal-Wrapper/CommonModalWrapper';
import CloseIcon from '../../Icons/CloseIcon';
import AddonExampleCard from '../addon-example-card/AddonExampleCard';
import { isValidURL } from '../../../utils/verification';
import getFetchConfig from '../../../utils/getFetchConfig';
import handleFetchErrors from '../../../utils/handleFetchErrors';
import standardFetchHandlers from '../../../utils/standardFetchHandlers';
import LoaderScreen from '../loader-screen/LoaderScreen';
import FormButton from '../../buttons/form-button/FormButton';
import CheckoutButton from '../../buttons/checkout-button/CheckoutButton';
import { getCurrencySymbol } from '../../../utils/getCurrencySymbol';
import getSymbolFromCurrency from 'currency-symbol-map';
import CongratulationsScreen from '../congratulations-screen/CongratulationsScreen';

import Inputs from '../../Inputs/Inputs';
import appURLs from '../../../appURL';

import style from './adsPlans.module.css';
import { add } from 'date-fns';


const formatDateFun = (dateString) => {
  const date = new Date(dateString);
  const month = date.toLocaleString('default', { month: 'short' });
  const day = date.getDate();
  const year = date.getFullYear();

  const formattedDate = `${month} ${day} ${year}`;

  return formattedDate;
}

export default function AdsPlans({ adsId, adsCategoriesId, showAdsPlan, setShowAdsPlan, category }) {

  const fetchConfig = getFetchConfig(),
    appURL = appURLs();

  const [planData, updatePlanData] = useState([]), //all fetched data from all plans
    [selectedPlanId, setSelectedPlanId] = useState(""),
    [addOnLists, updateAddOnLists] = useState([]),
    [selectedExampleData, updateSelectedExampleData] = useState({}),
    [isModalOpenForExample, toggleExampleModal] = useState(false),
    [selecetdPlan, updateSelecetdPlan] = useState({}),
    [adExpiry, setAdExpiry] = useState(""),
    [showCongratulations, setShowCongratulations] = useState(false),
    [showLoader, toggleShowLoader] = useState(false),
    [isButtonDisabled, setIsButtonDisabled] = useState(false),
    [showLinkInput, setShowLinkInput] = useState(false),
    [addOnsDetails, setAddOnsDetails] = useState([]),
    [totalPrice, setTotalPrice] = useState(0),
    [isExpired, setIsExpired] = useState(false),
    [isAllSelected, setIsAllSelected] = useState(false),
    [fetchedPriceId, setFetchedPriceId] = useState([]),
    [selectedBySelectAll, setSelectedBySelectAll] = useState([]),
    [newPrice, setNewPrice] = useState(0),
    [oldPrice, setOldPrice] = useState(0),
    [priceError, setPriceError] = useState(false),
    [priceDropInput, setPriceDropInput] = useState(false)

  const [websiteUrl, setWebsiteURL] = useState("");
  const [myads_id, setmyads_id] = useState('');

  const fetchPlanData = () => {
    toggleShowLoader(true);

    fetch(`${appURL}/posts/ads/fetch_plan?ads_type=${adsCategoriesId}&ads_id=${myads_id}`, {
      ...fetchConfig,
      method: "GET",
    })
      .then(handleFetchErrors)
      .then((res) => {
        console.log(res);

        myads_id && toggleShowLoader(false);

        if (res.status === 200) {
          const adsPlansData = res?.data;
          if (adsPlansData.length) {
            console.log("addOnLists2");
            updatePlanData(adsPlansData);
            updateSelecetdPlan(adsPlansData[0]);
            updateAddOnLists(adsPlansData[0]?.add_ons);
          }
          //if add_ons.name === "Price Drop" 
        } else {
          return toast.error(`Something went wrong`);
        }
      })
      .catch((err) => {
        toggleShowLoader(false);
        console.log(err);
      });
  };

  const fetchAdDetails = async () => {
    try {
      const response = await fetch(
        `${appURL}/posts/${category}/ad_details?adsId=${adsId}`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      const currentDate = new Date(); // Current date

      // Set general ad details
      setAdExpiry(data?.ads_details?.plan_validity?.expired_on);
      setSelectedPlanId(data?.ads_details?.plan_validity?.plan_id);
      setmyads_id(data?.ads_details?._id);

      const priceAmount = data?.ads_details?.price_default?.price?.amount;
      if (priceAmount !== undefined) {
        setOldPrice(priceAmount); // Set the price amount to oldPrice
      }

      const priceDropped = data?.ads_details?.price_default?.priceDrop;
      if (priceDropped !== undefined || priceDropped !== 0) {
        setNewPrice(priceDropped); // Set the price amount to oldPrice
      }

      // Process add-on validity details
      const priceDetails = data?.ads_details?.addons_validity?.map((item) => {
        const isLink = new Date(item.name);
        // const isExpired = expiredOnDate < currentDate;

        return {
          price_id: item.add_ons_id,
          expired_on: item.expired_on,
          // isExpired,
          read_only: item.name === "Link to your website" ? false : true,
        };
      }) || [];
      const notOneMonth = data?.ads_details?.addons_validity.find(
        (item) => item.add_ons_id === "64e4db08f9eeb14b6e2185c4" || item.add_ons_id === "64e4da83f9eeb14b6e2185bc"
      );
      console.log(notOneMonth, "addOnLists==0987")
      const today = new Date();
      const todayDate = today.toISOString().split('T')[0];
      const isExpired = notOneMonth?.expired_on && notOneMonth.expired_on > todayDate;

      if (notOneMonth) {
        setAddOnsDetails((prevDetails) => [
          ...prevDetails,
          {
            price_id: notOneMonth.add_ons_id,
            add_ons_id: "64e4db3ef9eeb14b6e2185c7",
            add_name: notOneMonth.name,
            amount: notOneMonth.amount,
            read_only: false,
            expired_on: notOneMonth.expired_on,
            isExpired: !isExpired, // Return false if expired
          },
        ]);
      }
      console.log(addOnsDetails, "addOnLists==567")
      // Process website details if present
      const websiteAddon = data?.ads_details?.addons_validity?.find(
        (item) => item.name === "Link to your website"
      );

      if (websiteAddon) {
        setWebsiteURL(data.ads_details.website_url);
      }

      setFetchedPriceId(priceDetails);

      // Calculate total price (assuming amount is present in add-on data)
      const total = priceDetails.reduce((sum, detail) => sum + (detail.amount || 0), 0);
      setTotalPrice(total.toFixed(2));

    } catch (error) {
      console.error("Error fetching ad details:", error);
    }
  };

  // useEffect(() => {
  //   const updatedAddOnsDetails = [...addOnsDetails]; // Clone the existing state

  //   fetchedPriceId.forEach((priceId) => {
  //     console.log(priceId, "matchedAddOn====0");

  //     planData.forEach((plan) => {
  //       const matchedAddOn = plan.add_ons.find((addOn) =>
  //         addOn.price.some((price) => price._id === priceId.price_id)
  //       );

  //       console.log(matchedAddOn, "matchedAddOn====1");

  //       if (matchedAddOn) {
  //         const matchedPrice = matchedAddOn.price.find(
  //           (price) => price._id === priceId.price_id
  //         );

  //         // Check if the add-on already exists in the updated array
  //         const alreadyExists = updatedAddOnsDetails.some(
  //           (item) => item.add_ons_id === "64e4db3ef9eeb14b6e2185c7" // Replace with dynamic id if required
  //         );

  //         if (!alreadyExists) {
  //           updatedAddOnsDetails.push({
  //             add_name: "Link to your website",
  //             add_ons_id: "64e4db3ef9eeb14b6e2185c7", // Replace with dynamic id if required
  //             price_id: priceId.price_id,
  //             amount: matchedPrice.amount,
  //           });

  //           console.log(updatedAddOnsDetails, "matchedAddOn====2");
  //         }
  //       }
  //     });
  //   });

  //   setAddOnsDetails(updatedAddOnsDetails); // Update the state
  //   setShowLinkInput(true);
  //   console.log(showLinkInput,"matchedAddOn====3")
  //   console.log(addOnsDetails,"matchedAddOn====4")
  // }, []);


  // useEffect(() => {
  //   // const updatedAddOnsDetails = [];
  //   const updatedAddOnsDetails = [...addOnsDetails]; 

  //   fetchedPriceId.forEach((priceId) => {
  //     console.log(priceId,"matchedAddOn====0")

  //     planData.forEach((plan) => {
  //       const matchedAddOn = plan.add_ons.find((addOn) =>
  //         addOn.price.some((price) => price._id === priceId.price_id)
  //       );

  //       console.log(matchedAddOn,"matchedAddOn====1")

  //       if (matchedAddOn) {
  //         const matchedPrice = matchedAddOn.price.find(
  //           (price) => price._id === priceId.price_id
  //         );

  //         updatedAddOnsDetails.push({
  //           add_name: "Link to your website",
  //           // add_ons_id: matchedAddOn._id,
  //           add_ons_id:"64e4db3ef9eeb14b6e2185c7",
  //           price_id: priceId.price_id,
  //           amount: matchedPrice.amount,
  //         });

  //         console.log(updatedAddOnsDetails,"matchedAddOn====2")
  //       }
  //     });
  //   });
  //   setAddOnsDetails(updatedAddOnsDetails);
  //   setShowLinkInput(true)
  // }, [fetchedPriceId, planData]);


  useEffect(() => {
    if (oldPrice) {
      if (newPrice >= oldPrice) {
        setPriceError(true)
      } else {
        setPriceError(false)
      }
    }
  }, [newPrice])

  useEffect(() => {
    console.log(fetchedPriceId, addOnLists, "addOnLists=====456");

    const fetchAddOnsDetails = async () => {
      if (!fetchedPriceId.length || !addOnLists.length) {
        console.log("No valid fetchedPriceId or addOnLists");
        return;
      }
      // Get today's date

      const today = new Date();
      const todayDate = today.toISOString().split('T')[0]; // Format the date as 'YYYY-MM-DD'

      // Iterate over all fetchedPriceId elements
      const newAddOnsDetails = addOnLists.flatMap((addon) => {
        console.log(addon, "Current addon");

        // Check for matching prices for each fetchedPriceId
        const matchingPrices = fetchedPriceId.map((priceObj) => {
          // Check if the addon is expired
          const isExpired = priceObj.expired_on && priceObj.expired_on > todayDate;  // Compare dates

          const matchingPrice = addon.price.find(
            (p) => String(p._id) === String(priceObj.price_id)
          );
          console.log(matchingPrice, "Matching price for", addon.name, "and price_id", priceObj.price_id);

          if (!matchingPrice) return null;
          return {
            price_id: matchingPrice._id,
            add_ons_id: addon._id,
            add_name: addon.name,
            amount: matchingPrice.amount,
            read_only: priceObj.read_only,
            expired_on: priceObj.expired_on,
            isExpired: isExpired ? false : true// Return false if expired
          };
        });

        // Remove null entries
        return matchingPrices.filter((item) => item !== null);
      });

      console.log(addOnsDetails, "addOnLists===123");

      // Set the state to trigger a re-render and update the checked state
      // setAddOnsDetails(addOnsDetails);
      // Add newAddOnsDetails to the existing addOnsDetails array
      setAddOnsDetails((prevDetails) => [...prevDetails, ...newAddOnsDetails]);
    };

    // if 64e4db08f9eeb14b6e2185c4 or 64e4da83f9eeb14b6e2185bc then add 

    // price_id: "64e4db08f9eeb14b6e2185c4",
    // add_ons_id: "64e4db3ef9eeb14b6e2185c7",
    // add_name: "Link to your website",
    // amount: 0,
    // expired_on: priceObj.expired_on,
    // isExpired: isExpired ? false : true// Return false if expired
    // read_only: false,

    fetchAddOnsDetails();

    // Calculate the total after updating the details
    const total = addOnsDetails.reduce((sum, detail) => sum + detail.amount, 0);
    setTotalPrice(total.toFixed(2));
  }, [fetchedPriceId, addOnLists]);

  function calculateDuration(days) {
    const daysInMonth = 30;
    const daysInWeek = 7;
    const months = Math.floor(days / daysInMonth);
    const remainingDays = days % daysInMonth;
    const weeks = Math.floor(remainingDays / daysInWeek);
    const remainingDaysInWeek = remainingDays % daysInWeek;

    if (months !== 0 && weeks !== 0 && remainingDaysInWeek !== 0) {
      return `${months * daysInMonth + weeks * daysInWeek + remainingDaysInWeek} days`;
    } else if (months !== 0 && weeks !== 0) {
      return `${months * daysInMonth + weeks * daysInWeek} days`;

    } else if (months !== 0) {
      const monthValue = months === 1 ? "month" : "months";
      return `${months} ${monthValue}`;
    } else if (weeks !== 0 && remainingDaysInWeek !== 0) {
      return `${weeks * daysInWeek + remainingDaysInWeek} days`;
    } else if (weeks !== 0) {
      const weeksValue = weeks === 1 ? "week" : "weeks";
      return `${weeks} ${weeksValue}`;
    } else {
      return `${remainingDaysInWeek} days`;
    }
  }

  const planRadioBtnOption = useMemo(() => {
    let planRadioBtnOptionArr = [];
    for (let i = 0; i < planData.length; i++) {
      const planDataObj = planData[i];
      planRadioBtnOptionArr.push({
        label: calculateDuration(planDataObj?.duration) !== '0 days' ? calculateDuration(planDataObj?.duration) : 'Permanent',
        value: String(planDataObj?._id),
      })
    }
    return planRadioBtnOptionArr
  }, [planData]);

  useEffect(() => {
    if (adExpiry === "lifetime") {
      setAdExpiry("lifetime");
    } else {
      const date = new Date(adExpiry);
      const formattedDate = date.toLocaleDateString("en-US", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });
      setAdExpiry(formattedDate);
    }
  }, [adExpiry]);

  useEffect(() => {
    fetchAdDetails()
  }, [adsCategoriesId])

  useEffect(() => {
    fetchPlanData()
  }, [myads_id, adsCategoriesId])

  // const planChangeHanlder = (e) => {
  //   if (selectedPlanId !== "") return;

  //   const planId = e?.target?.value;

  //   const foundPlan = planData.find(plan => plan._id === planId);
  //   if (foundPlan && Object.keys(foundPlan).length) {
  //     updateSelecetdPlan(foundPlan);
  //     updateAddOnLists(foundPlan.add_ons);
  //     // updateSelectedAddOn([]);
  //     // toggleIsAllAddonSelected(false);
  //   }
  // }

  const handleExampleModal = (value, addonId) => {

    const foundAddonExample = selecetdPlan.add_ons.find((addon) => addon._id == addonId);
    console.log(foundAddonExample, "selecetdPlannnnn222")

    if (foundAddonExample) {

      updateSelectedExampleData({
        title: foundAddonExample?.example_title,
        description: foundAddonExample?.example_description,
        image: foundAddonExample?.example_image
      })
      toggleExampleModal(value)
    } else {
      toggleExampleModal(value)
    }
  }

  const dateFormat = 'DD/MM/YYYY';
  const startDate = localStorage.getItem('start_date');
  const endDate = localStorage.getItem('end_date');

  const start = dayjs(startDate, dateFormat).toDate();
  const end = dayjs(endDate, dateFormat).toDate();
  const dateDiff = Math.floor((end - start) / (1000 * 60 * 60 * 24));

  let expirationDate;

  if (dateDiff > 30) {
    expirationDate = end.toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  } else {
    const expirationDay = end.getDate() + 1;
    const expirationMonth = end.getMonth() + 1;
    const expirationYear = end.getFullYear();
    expirationDate = new Date(expirationYear, expirationMonth - 1, expirationDay).toLocaleDateString(undefined, {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }

  const handlePriceChangeDropdown = (addOnId, priceId, amount) => {
    console.log("addOnUpdated====0", addOnId, priceId, amount);

    setAddOnsDetails(prevDetails => {
      // Find if the add-on already exists in the array
      const updatedDetails = prevDetails.map(detail => {
        if (detail.add_ons_id === addOnId) {
          // Return a new object with the updated price_id and amount
          return { ...detail, price_id: priceId, amount: amount };
        }
        return detail;
      });

      console.log("Updated Details:", updatedDetails, "Previous AddOnsDetails:", prevDetails);

      // If the add-on doesn't exist, add a new entry
      if (!updatedDetails.find(detail => detail.add_ons_id === addOnId)) {
        updatedDetails.push({ add_ons_id: addOnId, price_id: priceId, amount });
      }

      return updatedDetails; // Return the updated state
    });
  };

  const handleSelectAllAddons = () => {
    setIsAllSelected((prevState) => {
      const newState = !prevState;

      if (newState) {
        // Select all add-ons not currently in addOnsDetails
        const existingAddOnIds = addOnsDetails.map((detail) => detail.add_ons_id);
        const newAddOns = addOnLists
          .filter((addOn) => !existingAddOnIds.includes(addOn._id))
          .map((addOn) => {
            const defaultPrice = addOn.price[0]; // Assume the first price option is the default
            return {
              add_ons_id: addOn._id,
              price_id: defaultPrice._id,
              amount: defaultPrice.amount,
            };
          });

        const updatedDetails = [...addOnsDetails, ...newAddOns];

        setAddOnsDetails(updatedDetails); // Update add-ons state
        // Keep track of which add-ons were selected via "Select All"
        setSelectedBySelectAll(newAddOns.map((addOn) => addOn.add_ons_id));
      } else {
        // Unselect only the add-ons selected via "Select All"
        const remainingAddOns = addOnsDetails.filter(
          (detail) => !selectedBySelectAll.includes(detail.add_ons_id)
        );

        setAddOnsDetails(remainingAddOns);
        setSelectedBySelectAll([]); // Clear the "Select All" tracking list
      }
      return newState;
    });
  };

  const handleCheckboxChange = (addonId, isChecked, addOnName) => {
    console.log(addonId, isChecked, addOnName, "addOnNameeee")

    setAddOnsDetails((prevDetails) => {
      let updatedDetails;

      if (addOnName === "Link to your website") {
        if (isChecked) {
          setShowLinkInput(true);
        } else {
          setShowLinkInput(false);
          setWebsiteURL(""); // Reset the website URL when unselected
        }
      }

      if (addOnName === "Price Drop") {
        if (isChecked) {
          setPriceDropInput(true);
        } else {
          setPriceDropInput(false);
          setOldPrice(""); // Reset the website URL when unselected
          setNewPrice("")
        }
      }

      if (isChecked) {
        // Add the selected add-on
        const addOn = addOnLists.find((a) => a._id === addonId);
        if (addOn) {
          const defaultPrice = addOn.price[0]; // Assume the first price option is the default
          updatedDetails = [
            ...prevDetails,
            {
              add_name: addOnName,
              add_ons_id: addonId,
              price_id: defaultPrice._id,
              amount: defaultPrice.amount,
              // read_only: addOnName ? ""
            },
          ];
        } else {
          // If the add-on is not found, return the existing details (safety check)
          updatedDetails = prevDetails;
        }
      } else {
        // Remove the deselected add-on
        updatedDetails = prevDetails.filter((detail) => detail.add_ons_id !== addonId);
      }

      // Update "Select All" checkbox state
      setIsAllSelected(updatedDetails.length === addOnLists.length);

      return updatedDetails; // Return the updated add-ons details
    });
  };

  useEffect(() => {
    // Filter out the addOns whose price_id already exists in fetchedPriceId
    const filteredAddOns = addOnsDetails.filter(
      (addOn) =>
        !fetchedPriceId.some(
          (fetchedAddOn) => fetchedAddOn.price_id === addOn.price_id
        )
    );

    // Sum up the amounts of the filtered add-ons
    const total = filteredAddOns.reduce((sum, addOn) => sum + addOn.amount, 0);

    // Update the totalPrice state with two decimal places
    setTotalPrice(total.toFixed(2));

  }, [addOnsDetails, fetchedPriceId]);

  const updatedFetchConfig = fetchConfig;
  updatedFetchConfig.headers['Content-Type'] = 'application/json';

  const handleSubmission = () => {
    toggleShowLoader(true);

    if (priceError) {
      toggleShowLoader(false);
      return toast.error("Entered price must be lesser than old price");
    }

    if (priceDropInput && !newPrice) {
      toggleShowLoader(false);
      return toast.error("Please enter dropped price");
    }

    if (showLinkInput && !websiteUrl) {
      toggleShowLoader(false);
      return toast.error("Please provide website link");
    }

    const priceIds = addOnsDetails
      .map(addOn => addOn.price_id) // Extract all price_ids
      .filter(priceId => !fetchedPriceId.some(fetched => fetched.price_id === priceId)); // Exclude matching price_ids

    const addNames = addOnsDetails.map(addOn => addOn.add_name); // Extract all add_names
    // Exclude those equal to fetchedPriceId.price_id
    console.log(priceIds, "addOnListsssssssss====price");

    fetch(`${appURL}/posts/payment/create-payment-intent`, {
      ...updatedFetchConfig,

      body: JSON.stringify({
        postId: myads_id,
        planId: selecetdPlan._id,
        add_ons: priceIds,
        // add_ons: showLink ? addonIds : [],
        price_drop: newPrice || "",
        ...(websiteUrl !== "" && { website_url: websiteUrl }),
        isPromoted: true,
        redirect_uri_success: "https://test.menehariya.com/success",
        // redirect_uri_success: "http://localhost:3005/success",
        ...(showLinkInput === false && { linktoyourwebsite: true, website_url: "" }),
      }),
    })
      .then(handleFetchErrors)
      .then((res) => {
        toggleShowLoader(false);
        console.log(res);
        if (res.paymentIntent?.toString()?.length > 0) {
          window.location.href = res?.paymentIntent;
        } else if (res.status === 201) {
          setShowAdsPlan(false);
          setShowCongratulations(true);
        } else if (addNames.includes("Link to your website")) {

          // New condition
          setShowAdsPlan(false);
          setShowCongratulations(true);
        } else if (priceIds.length === 0) {
          console.log("Checker 101010");
          setShowCongratulations(true);
          setShowAdsPlan(false);
        }
        else {
          toggleShowLoader(false);
          // updateSteps(steps + 1);
        }
      })
      .catch((err) => {
        toggleShowLoader(false);
        setShowAdsPlan(false);
        console.log(err);
      });
  };



  const checkingSelectedPrice = (addonId) => {
    return addOnsDetails.some((addon) => addon.add_ons_id === addonId)
  }

  if (showLoader) {
    return <LoaderScreen />
  }

  console.log("selectedPlanId:",selectedPlanId,"planRadioBtnOption:",planRadioBtnOption,"totalPrice:", totalPrice, "fetchedPriceId:", fetchedPriceId, "addOnsDetails:", addOnsDetails, "addOnLists:", addOnLists, "addOnListsssssssss");


  return (
    <>
      <div className='ad-page-main'>
        <CommonModalWrapper isOpen={isModalOpenForExample} className={style.example_modal} >
          <CloseIcon onClick={() => handleExampleModal(false, null)} />
          <AddonExampleCard selectedExampleData={selectedExampleData} handleExampleModal={handleExampleModal} />
        </CommonModalWrapper>

        {showCongratulations &&
          <CongratulationsScreen adsId={myads_id} />
        }

        <section id='span-2' className={style.ads_plan_wrapper}>
          {/* if Not Local Biz Promotion */}
          {selecetdPlan?.name !== "Local Biz & Services" && (
            <>
              {console.log("Date block")}
              <div className={style.selected_plan}>
                <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Buy Promotions</h5>
                <section className={style.expire_text_section}>
                  <p className={style.ads_expire_info_text}>{isExpired ? `This Ad has expire on ${adExpiry}` : `This Ad will expire on ${adExpiry}`
                  }</p>
                </section>
              </div>
            </>
          )}

          {/* if Local Biz Promotion */}
          {selecetdPlan?.name === "Local Biz & Services" && (
            <>
              <h5 style={{ textAlign: 'center', fontWeight: 'bold' }}>Buy Promotions</h5>
              <section id={planRadioBtnOption.length === 1 && selecetdPlan?.price?.isfree && !expirationDate && style.expire_text_section} className={style.expire_text_section}>
                {
                  planRadioBtnOption.length === 1 && !selecetdPlan?.price?.isfree ?
                    <span className={style.ads_expire_info_text}>{`This Ad will expire on ${adExpiry}`}</span>
                    :
                    <>
                      {
                        planRadioBtnOption?.length &&
                        <Inputs
                          name='careType'
                          value={String(selectedPlanId)}
                          //onChangeHandler={planChangeHanlder}
                          type='radio'
                          id='span-2'
                          required={true}
                          radioBtnOtions={planRadioBtnOption}
                        />
                      }

                      <div className={style.price_section}>
                        <span className={style.plan_price_text}>
                          {!selecetdPlan?.price?.isfree
                            ? `${getCurrencySymbol(selecetdPlan?.price?.currency)}${selecetdPlan?.price?.amount}`
                            : "Free"}
                        </span>

                        <p className={style.expire_text}>
                          {selecetdPlan?.name === "Local Biz & Services" && adExpiry === "lifetime"
                            ? "This ad won't expire"
                            : `This Ad will expire on ${adExpiry}`
                          }
                        </p>

                      </div>

                    </>}
              </section>
            </>
          )}

          <section className={style.addon_section_wrapper}>
            <section className={style.sub_heading_and_selectall_wrapper}>
              <h4>Make your ad stand out!</h4>
              <div className={style.sub_heading_and_selectall_section}>
                <span>Select an option to promote your ad</span>
                <div className={style.select_all_label}>
                  <span>Select All</span>
                  <input
                    checked={isAllSelected}
                    type="checkbox"
                    id="selectAll"
                    name="selectAll"
                    onChange={handleSelectAllAddons}
                  />
                  <label htmlFor="selectAll"></label>
                </div>
              </div>
            </section>

            {addOnLists?.map((addOn) => {
              // Find the matching detail from addOnsDetails
              const matchingDetail = addOnsDetails.find(
                (detail) => detail.add_ons_id === addOn._id
              );

              const isPriceDrop = addOnsDetails.find(
                (detail) => detail.add_name === "Price Drop"
              );

              console.log(matchingDetail, "matchingDetail")

              const isLinkYourWebsite = addOnsDetails.find(
                (detail) => detail.add_name === "Link to your website"
              );

              // Check if the add-on is expired
              const isExpired = matchingDetail?.isExpired || false;
              const expiredOn = matchingDetail?.expired_on;

              // Define background colors based on add-on names
              const backgroundColors = {
                "Featured": "rgb(255, 119, 0)",
                "Bump up": "rgb(116, 184, 73)",
                "Homepage Gallery": "rgb(4, 100, 196)",
                "Urgent": "rgb(252, 20, 20)",
                "Link to your website": "rgb(39, 3, 214)",
                "Price Drop": "#2c8fb7"
              };

              return (
                <section className={style.plan_card_wrapper} key={addOn._id}>
                  <div className={style.check_plan_heading_wrapper}>
                    <div
                      className={style.check_box_wrapper}
                      onClick={() =>
                        matchingDetail?.read_only
                          ? null // Prevent opening modal if readOnly is true
                          : handleExampleModal(true, addOn?._id)
                      }
                      // onClick={() => handleExampleModal(true, addOn?._id)}
                      style={{ cursor: "pointer" }}
                    >
                      <input
                        type="checkbox"
                        className={style.plans_checkbox}
                        checked={addOnsDetails.some((detail) => {
                          return (
                            detail.add_ons_id.toString() === addOn._id.toString()
                          );
                        })}
                        readOnly={matchingDetail?.read_only}
                        // onChange={(e) => {
                        //   handleCheckboxChange(addOn._id, e.target.checked, addOn.name);
                        //   handleExampleModal(false);
                        // }}
                        // onClick={(e) => {
                        //   // Prevent toggle behavior if readOnly is true
                        //   if (matchingDetail?.read_only) {
                        //     e.preventDefault();
                        //   }
                        // }}
                        onChange={(e) => {
                          if (!matchingDetail?.read_only) {
                            handleCheckboxChange(addOn._id, e.target.checked, addOn.name);
                            handleExampleModal(false);
                          }
                        }}
                        style={{
                          display: "block",
                          appearance: "checkbox",
                          backgroundColor: "orange",
                          color: "white",
                          width: "20px",
                          height: "20px",
                          border: "2px solid orange",
                          marginRight: "5px",
                        }}

                      />
                      <p style={{
                        backgroundColor: backgroundColors[addOn.name] || "transparent", // Default color if not matched
                      }}
                        className={style.plan_heading_text}
                      >{addOn.name}</p>
                    </div>

                    <p className={style.plan_description_text}>
                      {addOn?.description}{" "}
                      <span
                        onClick={() => handleExampleModal(true, addOn?._id)}
                        className={style.view_example_text}
                      >
                        View example
                      </span>
                    </p>

                    {addOn?.name === "Link to your website" && isLinkYourWebsite ? (
                      <input
                        value={websiteUrl}
                        onChange={(e) => { setWebsiteURL(e.target.value) }}
                        type="text"
                        name="website"
                        className={style.website_text_input_field}
                        placeholder="Enter website link"
                      />
                    ) : null}

                    {addOn.name === "Price Drop" && isPriceDrop ? (
                      <>
                        {newPrice ? (<p className={style.plan_description_text}>Your sales price will be display as <span className={style.oldPrice}> {oldPrice}</span> <span className={style.newPrice}>{newPrice}</span></p>) : ""}
                        <input
                          value={newPrice}
                          onChange={(e) => { setNewPrice(e.target.value) }}
                          type="number"
                          name="newprice"
                          className={style.website_text_input_field}
                          placeholder="Enter New Price"
                        />
                        {priceError && <p className='price-error' style={{ color: "red" }}>Entered price must be lesser than old price</p>}
                      </>
                    )
                      : null}

                    {matchingDetail && matchingDetail.add_ons_id === addOn._id && isExpired && (matchingDetail.expired_on) && (
                      <>
                        <p style={{ color: "red" }}>
                          This Ad expired on {expiredOn}.
                        </p>
                      </>
                    )}

                    {matchingDetail && matchingDetail.add_ons_id === addOn._id && !isExpired && (matchingDetail.expired_on)
                      && (
                        <>
                          <p style={{ color: "red" }}>
                            This Ad will expire on {expiredOn}.
                          </p>
                        </>
                      )}
                  </div>

                  <select
                    id={style.select_plan_input}
                    onChange={(e) => {
                      const selectedPrice = addOn.price.find(
                        (price) => price._id === e.target.value
                      );
                      handlePriceChangeDropdown(
                        addOn._id,
                        e.target.value,
                        selectedPrice.amount
                      );
                    }}
                    className={
                      checkingSelectedPrice(addOn?._id)
                        ? `${style.select_plan_input} ${style.active_card}`
                        : style.select_plan_input
                    }
                    value={
                      addOnsDetails.find(
                        (detail) =>
                          detail.add_ons_id.toString() === addOn._id.toString()
                      )?.price_id || addOn.price[0]._id
                    }
                  >
                    {addOn.name === "Bump up" ? (

                      addOn?.price?.map((price) => (
                        <option key={price._id} value={price._id}>
                          {price.duration === 30 ? (
                            `Everyday - ${!price?.isfree ? getCurrencySymbol(price?.currency) : ""}${price?.isfree ? "Free" : price?.amount}`
                          ) : (
                            `Every ${price.duration} days - ${!price?.isfree ? getCurrencySymbol(price?.currency) : ""}${price?.isfree ? "Free" : price?.amount}`
                          )}</option>
                      ))

                    ) : (
                      addOn?.price?.map((price) => (
                        <option key={price._id} value={price._id}>
                          {price.amount === 0
                            ? "Free"
                            : `${price.duration} days - ${getCurrencySymbol(price.currency)} ${price.amount}`}
                        </option>
                      ))
                    )}
                  </select>
                </section>
              );
            })}

            <div className={style.price_calculation_section}>
              <section className={style.price_calculation_heading}>
                <span>Total</span>
              </section>
              <section className={style.price_calculation_amount}>
                <span className={style.amount_text}>${totalPrice}</span>
              </section>
            </div>


            <CheckoutButton
              cancelButtonText=""
              // submitBtnText={selectedAddOn.length !== 0 ? "Post My Ad" : "Post My Ad ${totalAmount}"}
              submitBtnText={totalPrice > 0 ? `Promote My Ad - $${totalPrice}` : "Promote My Ad"}
              id="span-2"
              className={style.form_button_section}
              // backBtnClickHandler={backBtnClickHandler}
              onClickHandler={handleSubmission}
              showLoadder={showAdsPlan}
              disabled={isButtonDisabled}
            />

          </section>

        </section>
      </div>
    </>
  )
}

//64e4b5cff9eeb14b6e2184e9
